import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loading from 'react-loading-spinner';
import { setIsLoading } from '../../../actions/common';
import {
	GetUserRole,
	getPatientListAnnotator,
	getPatientIcons,
	getPatientDocuments,
	getDocEntityCounts,
	getDocumentIconsInfo,
	getPendingEncounters,
} from './DataWorkQueueHelper';
import { useSelector } from 'react-redux';
import dataIcon from '../../../images/data_icon.png';
import { connect } from 'react-redux';
import { roleAccess } from '../../../utilities/AppConstants';
import CheckBox from '../../../common/CheckBox/CheckBox';
import { Link } from 'react-router-dom';
import { setHistory, setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
const ListOfPatientCollapsible = lazy(() =>
	import(
		/* webpackChunkName: "ListOfPatientCollapsible" */ '../../../components/ListOfPatientCollapsible/ListOfPatientCollapsible'
	)
);
import PaginationComponent from '../../../common/Pagination/PaginationComponent';

const GridHeader = lazy(() =>
	import(/* webpackChunkName: "GridHeader" */ '../../../components/GridHeader/GridHeader')
);

import { setObjectToLocal, getObjectFromLocal } from '../../../utilities/utils';

const headerData = {
	title: 'Data',
	searchTitle: 'data',
	list: 'list',
	img: (
		<div className="data_icon">
			<img src={dataIcon} alt="Data List" style={{ width: 20 }} />
		</div>
	),
	lastColumn: 'Patients',
};

const DataWorkAnnotator = (props) => {
	const [patients, setPatients] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [pageNo, setPageNo] = useState(1);
	const [fetchDocuments, setFetchDocuments] = useState(false);
	const [totalPatients, setTotalPatients] = useState('');
	const { userRole } = useSelector((state) => state.redSidenav);
	const { loading } = useSelector((state) => state.loading);
	const [showhideNoAnnotation, setShowhideNoAnnotation] = useState(
		getObjectFromLocal('showHideInitialAnnotation') == true ? true : false
	);
	const [selectedBatchId, setSelectedBatchId] = useState(getObjectFromLocal('selectedBatchId'));
	
	useEffect(() => {
		
	}, []);

	useEffect(()=>{
		setIsLoading(true);
		let recordsPerPage = 10;
		if (!props?.redSidenav?.userRole?.Role) {
			GetUserRole().then((resp) => {
				getWorkQueuePatient(1, '', recordsPerPage);
			});
		} else {
			getWorkQueuePatient(1, '', recordsPerPage);
		}
	}, [showhideNoAnnotation])

	const fetchData = (patientId, organizationId, searchText, role) => {
		return new Promise((resolve) => {
			const initialCounts = {
				study_count: '',
				cohort_count: '',
				data_count: '',
				patient_count: '',
			};
			getPatientDocuments(patientId, organizationId, searchText, role, selectedBatchId?.value)
				.then((docs) => {
					docs.map((doc) => {
						doc.counts = initialCounts;
					});
					if (role == roleAccess.ANNOTATOR) {
						getDocumentIconsInfo(docs, role)
							.then((documentWithIcons) => {
								const response = {
									document: documentWithIcons,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					} else {
						getDocEntityCounts(docs)
							.then((docsWithCount) => {
								const response = {
									document: docsWithCount,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					}
				})
				.catch((error) => console.log(error));
		});
	};

	const getWorkQueuePatient = (pageNo, searchText, recordsPerPage = 10) => {
		setIsLoading(true);
		if (showhideNoAnnotation) {
			getPendingEncounters()
				.then((res) => {
					if (res.encounters) {
						let filterredEncounter = res.encounters.filter(items=> items.personal_count == 0);
						setPatients(filterredEncounter);
					}
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
				});
		} else {
			getPatientListAnnotator(1, pageNo, searchText, '', true, recordsPerPage, selectedBatchId?.value)
				.then((res) => {
					gneratePatientList(res);
				})
				.catch((error) => {
					setIsLoading(false);
				});
		}
	};

	const gneratePatientList = (res) => {
		const _patients = res.list;
		setPatients(_patients);
		setTotalPatients(res.count);

		//PREVENT FURTHER PROCESSING, IF PATIENTS ARE NOT PRESENT
		if (!_patients.length) {
			return;
		}
		const patientIdList = _patients.map((patient) => patient.patient_id).join();
		//GET REQUIRED INFO TO SHOW COHORT and ANNOTATION ICONS.
		getPatientIcons(_patients, patientIdList, true).then((patients) => {
			setPatients(patients);
			setFetchDocuments(true);
		});
		setIsLoading(false);
	};
	const gotoPage = (pagenumber, recordsPerPage) => {
		setPageNo(pagenumber);
		getWorkQueuePatient(pagenumber, '', recordsPerPage);
	};

	const ShowhideNoAnnotationClick = () => {
		setPatients([]);
		setObjectToLocal('showHideInitialAnnotation', !showhideNoAnnotation);
		setShowhideNoAnnotation(!showhideNoAnnotation);
	};

	return (
		<div className="data-work-annotator">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={true}
					searchFunction={() => {}}
					searchText=""
					history={props.history}
					noMenu={true}
				/>
			</ErrorBoundary>
			<div className="dataworkqueue-header-container">
				<div className="dataworkqueue-title">Data list: Manual annotation work queue</div>
				<div className="dataworkqueue-showhide custom-checkbox">
					<CheckBox
						id={'dataworkqueue-showhide'}
						onClick={ShowhideNoAnnotationClick}
						name={'No initial annotations'}
						value={''}
						checked={showhideNoAnnotation}
						noLink={true}
					/>
				</div>
			</div>
			<Suspense fallback={<div className="defaultSpinner" />}>
				<GridHeader
					listLabel="Patients"
					lastLabel={headerData.lastColumn}
					userRole={userRole}
					isDataPage={1}
					showhideNoAnnotation={showhideNoAnnotation}
				/>
			</Suspense>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					{!showhideNoAnnotation ? (
						<ListOfPatientCollapsible
							patients={patients}
							paddingLeft={0}
							parentBgDark
							isDataPage={1}
							searchText={searchText}
							fetchDocuments={fetchDocuments}
							userRole={userRole}
							fetchData={fetchData}
							{...props}
						/>
					) : (
						patients.map((encounters) => {
							return <div className="v-title-wrapper">
								<div className="v-col-link">
								<Link
									to={
										constants.ROUTE_URL +
											'/dashboard/document/' +
											encounters.subdocument_id || ''
									}
								>
									{encounters.name}
								</Link></div>
								<div className="v-col-count">{encounters.personal_count}</div>
								<div className="v-col-count">{encounters.total_count}</div>
							</div>;
						})
					)}
				</Suspense>
			</ErrorBoundary>
			{totalPatients > 0 && !showhideNoAnnotation && (
				<ErrorBoundary>
					<PaginationComponent
						totalItemsCount={totalPatients}
						gotoPage={gotoPage}
						currentPage={pageNo}
					/>{' '}
				</ErrorBoundary>
			)}
		</div>
	);
};

DataWorkAnnotator.propTypes = {
	history: PropTypes.any,
	redSidenav: PropTypes.shape({
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};
// export default DataWorkAnnotator;
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataWorkAnnotator);
