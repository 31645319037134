/* istanbul ignore file */
import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export const getEncounterLevelOutcomeScore = (studyId, batchType,batchId) => {
  return new Promise((resolve, reject) => {
    apiGet("GetEncounterLevelOutcomeScores", {
      m_studyId: studyId,
      m_batchType: batchId ? batchId : batchType
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getPatientLevelProgression = (studyId, batchType, batchId) => {
  return new Promise((resolve, reject) => {
    apiGet("GetPatientLevelProgression", {
      m_studyId: studyId,
      m_batchType: batchId ? batchId : batchType
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
export function getStudyValidationCriteria(study_id, criteria) {
  const value = localStorage.getItem(study_id + "studyCriteria");
  return value ? JSON.parse(value) : "";
}
export const setIsLoading = (loading) => {
  store.dispatch({
    type: actionTypes.IS_LOADING,
    payload: loading,
  });
};

export const updateStudyValidation = (updatedStudyValidationValues) => {
  store.dispatch({
    type: actionTypes.UPDATE_STUDY_VALIDITY,
    payload: updatedStudyValidationValues,
  });
};

export const setSideNavList = (list) => {
  store.dispatch({
    type: actionTypes.SET_SIDE_NAVLIST,
    payload: list,
  });
};

export const getStudyDetail = (study_id) => {
  return new Promise(function (resolve, reject) {
    apiGet("GetStudyDetail", {
      "m_StudyObj.study_id": study_id,
    })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_DETAIL,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function getStudyInformation(studyName) {
  return new Promise((resolve, reject) => {
    apiGet("GetStudyInformation", { m_studyName: studyName })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_INFO,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function refreshStudyValidation(generatedReport, batchType, batchId) {
  return new Promise((resolve, reject) => {
    apiGet("RefreshStudyValidation", {
      generateReport: generatedReport,
      m_batchType: batchId ? batchId : batchType
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}