/* istanbul ignore file */
import { apiGet, apiPostForm } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import queryString from 'query-string';

export function getAnnotatotList() {
	return new Promise(function (resolve, reject) {
		apiGet('GetAnnotatorListBySupervisor',  {})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getEncounters(annotattorId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetAnnotatorSubDocListForSupervisor',  {'annotatorId':annotattorId})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}


