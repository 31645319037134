/* istanbul ignore file */
import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

export function FetchUserList() {
	
	return new Promise((resolve, reject) => {
		apiGet('FetchUserList')
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
}

export function getUserPermissions(user_id) {
	let usermanagement = store.getState().redUsermanagement;
	if(usermanagement.userPermissionList && usermanagement.userPermissionList.length > 0){
		return (dispatch) =>
		new Promise(function(resolve, reject) {
			resolve(usermanagement.userPermissionList);
		});
	} else {
		return (dispatch) =>
		new Promise(function(resolve, reject) {
			apiGet('FetchUserPermission', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_USER_PERMISSION,
						payload: res.data.data
					});
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	}
}
