/* istanbul ignore file */
import actionTypes from '../../actions/actionTypes';
import { apiGet } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';

/****************************************
 * Function Name: getCohortPatient
 * Description: exports an action to get the cohort patients
 * data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - cohort_id - used to make the api call based on the cohort ID.
 ****************************************/

export function getCohortPatient(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortPatient', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_COHORT_PATIENT,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

/**************************************** 
    * Function Name: getCohortById
    * Description: Returns a cohort object by id. First it checks in redux store, 
    if not found then fires API call, and then find requested cohort by id given.
    @param cohortId Cohort id
****************************************/
export const getCohortById = (cohortId) => {
	return new Promise((resolve, reject) => {
		//CHECK IF COHORT PRESENT IN REDUX, ALSO CHECK FOR REQUIRED COHORT
		const cohortInStore = store.getState()?.redCohort?.cohortList;
		const isCohortEdited = store.getState().redCohort?.isCohortEdited;
		if (
			!isCohortEdited &&
			cohortInStore && cohortInStore.length &&
			cohortInStore.findIndex((cohort) => cohort.cohort_id === cohortId) !== -1
		) {
			const cohort = cohortInStore.find((cohort) => cohort.cohort_id === cohortId);
			if (cohort) {
				resolve(cohort);
			} else {
				reject();
			}
		} else {
			//FETCH ALL COHORTS, STORE IN REDUX, PROVIDE REQUESTED COHORT BY ID
			store
				.dispatch(actions.getCohortList({}))
				.then((res) => {
					if (isCohortEdited) {
						store.dispatch({
							type: actionTypes.COHORT_EDITED,
							payload: false,
						});
					}
					const cohorts = res.data.data;
					const cohort = cohorts.find((cohort) => cohort.cohort_id === cohortId);
					resolve(cohort);
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		}
	});
};

export const GetCohortOutcomesPatientCounts = (cohortId) => {
    return new Promise((resolve,reject) => {
        apiGet('GetCohortOutcomesPatientCounts', { 'cohortObj.cohort_id': cohortId })
		.then((res) => {
			resolve(res.data.payload);
		})
		.catch((err) => {
			reject(err);
		});
    })
};
