import React, {
	useState,
	useEffect,
	useRef,
    useImperativeHandle,
	lazy,
	Suspense,
    forwardRef
} from 'react';
import { connect } from 'react-redux';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { Tabs, Tab } from 'react-bootstrap';
// import UploadData from '../components/UploadData';
// import ProjectUpload from '../components/ProjectUpload';
// import ManualUploadText from '../components/ManualUploadText';
import constants from '../../constants';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import HamburgerMenu from '../../common/Header/HamburgerMenu';
import ErrorBoundary from '../../components/ErrorBoundary';
import Select from 'react-select';
import InfoPopup from '../../components/InfoPopup/InfoPopup';
import PaginationComponent from '../../common/Pagination/PaginationComponent';
import MessagePopup from '../../components/MessagePopup/MessagePopup';
import StudyBatchPopup from '../../components/StudyBatchPopup/StudyBatchPopup';
// import { getStudyList } from '../../actions/studyActions';
import {addStudyfromdirectory, doStudyAction, getNLPInfo, getstudiesList, getStudyInformation, getUserProject, openModal} from './StudyAdministrationHelper';
import { setIsLoading } from '../../actions/common';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import ReProcessBatch from '../../components/ReProcessBatch/ReProcessBatch';
import localforage from 'localforage';
const UploadData = lazy(() =>
	import(/* webpackChunkName: "UploadData" */ '../../components/UploadData/UploadData')
);

const ProjectUpload = lazy(() =>
	import(/* webpackChunkName: "ProjectUpload" */ '../../components/ProjectUpload/ProjectUpload')
);
const ManualUploadText = lazy(() =>
	import(
		/* webpackChunkName: "ManualUploadText" */ '../../components/ManualUploadText/ManualUploadText'
	)
);
const menuOptions1 = [ { eventKey: 1, label: 'Refresh' } ];
const menuOptions2 = [ { eventKey: 2, label: 'Refresh' } ];
const menuOptionsProject = [ { eventKey: 4, label: 'Edit manual annotation projects' } ];

const progressStatus = {
	Added: 'Added',
	InProgress: 'Processing in progress',
	ProcessingComplete: 'Processing completed',
	ProcessingFailed: 'Processing failed',
	AnnotationCreated: 'Manual annotation project created',
	AnnotationFailed: 'Manual annotation project failed',
	AnnotationInprogress: 'Manual annotation project creation in progress',
	ReprocessComplete: 'Reprocessing completed',
	ReprocessInprogress: 'Reprocessing in progress',
	ReprocessFailed: 'Reprocessing failed',
	RescanInprogress: 'Rescanning in progress',
	RescanFailed: 'Rescanning failed',
	NoAnnotatorPresent: 'No annotators present in the system',
	ReprocessInqueue: "Reprocessing in queue"

};

const projectStatus = {
	inprogress: 'In progress',
	complete: 'Complete',
	frozen: 'Frozen',
	unfrozen: 'Unfrozen',
	deleted: 'Deleted',
	new_project: 'New project'
};

const getStatusClass = (status) => {
	let className = '';
	switch (status) {
		case progressStatus.Added:
			className = 'added';
			break;
		case progressStatus.InProgress:
			className = 'inprogress';
			break;
		case progressStatus.ProcessingComplete:
			className = 'processingcomplete';
			break;
		case progressStatus.ProcessingFailed:
			className = 'processingfailed';
			break;
		case progressStatus.AnnotationCreated:
			className = 'annotationcreated';
			break;
		case progressStatus.AnnotationInprogress:
			className = 'inprogress';
			break;
		case progressStatus.AnnotationFailed:
			className = 'annotationfailed';
			break;
		case progressStatus.NoAnnotatorPresent:
			className = 'annotationfailed';
			break;
		case progressStatus.ReprocessInprogress:
			className = 'inprogress';
			break;
		case progressStatus.ReprocessComplete:
			className = 'processingcomplete';
			break;
		case progressStatus.ReprocessFailed:
			className = 'processingfailed';
			break;
		default:
			className = '';
			break;
	}
	return className;
};

const StudyAdministration = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [key, setKey] = useState(1);
	const [showUploadForm, setShowUploadForm] = useState(false);
	const [showProjectForm, setShowProjectForm] = useState(false);
	const [showManualUpload, setShowManualUpload] = useState(false);
	const [batches, setBatches] = useState([]);
	const [showSearch, setShowSearch] = useState(false);
	const [searchBatchText, setSearchBatchText] = useState('');
	const [deleteMode, setDeleteMode] = useState(false);
	const [menuOptions, setMenuOptions] = useState(menuOptions1);
	const [showForm, setShowForm] = useState(false);
	const [projects, setProjects] = useState([]);
	const [inputStudy, setInputStudy] = useState('');
	const [inputStudyAdded, setInputStudyAdded] = useState(false);
	const [studyDirPath, setStudyDirPath] = useState('');
	const [studyList, setStudyList] = useState([]);
	const [studyInformation, setStudyInformation] = useState([]);
	const [num_patients, setNum_patients] = useState('');
	const [studyName, setstudyName] = useState('');
	const [err, setErr] = useState('');
	const [success, setSuccess] = useState('');
	const [showLoading, setShowLoading] = useState(false);
	const [infoPopupMessage, setInfoPopupMessage] = useState('');
	const [noOfRecords, setNoOfRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [nlpVersion, setNlpVersion] = useState('');
	const [nlpLastUpdated, setNlpLastUpdated] = useState('');
	const [reprocessInprogress, setReprocessInprogress] = useState(false);
	const [disableAdd, setDisableAdd] = useState(false);
	const [forcedTabSelect, setForcedTabSelect] = useState(false);
	const [errAdd, setErrAdd] = useState('');
	const [showRescan, setShowRescan] = useState(false);
	const [predictionBatchList, setPredictionBatchList] = useState([]);
	const [selectedStudy, setSelectedStudy] = useState('');
	const [selectToReProcessPopup, setSelectToReProcessPopup] = useState('Select batches to reprocess');
	const [reProcessBatchList, setreProcessBatchList] = useState([])
	let intervalId = null;

	let confirmPopup = useRef(null);
	let confirmPopupStudy = useRef(null);
	let infoPopup = useRef(null);
	let confirmStudyBatch = useRef(null);
	let reProcessPopup = useRef(null);
	const initialRef = useRef(true);

    useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});

	useEffect(() => {
		getStudyList();
        props.open({
            handleClick: () => handleClick()
        });
		return () => {
			clearInterval(intervalId);
			if(props.open){
				props.open(null);
			}
		}
	}, []);


	useEffect(() => {
        if(infoPopupMessage){
		    infoPopup.current.handleClick()
        }
	}, [infoPopupMessage]);

	useEffect(() => {
		let tempBatch = [];
		if(initialRef.current && studyInformation.length){
			studyInformation.map((studyInfo) => {
				let studyInfoo = {
					...studyInfo,
					isChecked: false
				}
				
				switch (studyInfo.status) {
					case progressStatus.ProcessingComplete:
						if (studyInfo.manual_annotation != 'Yes') {
							if (nlpVersion !== studyInfo.NLP_processing_version) {
								tempBatch = [...tempBatch, studyInfoo]
							}
						}
						break;
					case progressStatus.ProcessingFailed:
						if (nlpVersion !== studyInfo.NLP_processing_version) {
							tempBatch = [...tempBatch, studyInfoo];
						}
						break;
					case progressStatus.AnnotationCreated:
						if (nlpVersion !== studyInfo.NLP_processing_version) {
							tempBatch = [...tempBatch, studyInfoo];
						}
						break;
					case progressStatus.ReprocessComplete:
						if (nlpVersion !== studyInfo.NLP_processing_version) {
							tempBatch = [...tempBatch, studyInfoo];
						}
						break;
					case progressStatus.ReprocessFailed:
						if (nlpVersion !== studyInfo.NLP_processing_version) {
							tempBatch = [...tempBatch, studyInfoo];
						}
						break;
					default:
						break;
				}
			return tempBatch;
			})
			console.log("tempBatch",tempBatch)
			setreProcessBatchList(tempBatch)
			initialRef.current = false;
		}
	}, [studyInformation])
	
	const getStudyList = () => {
		localforage.getItem('isVAStudy').then(enable =>{
			if(enable){
				getNLPInfo().then((res) => {
					if (res) {
						let temp = res?.NLP_Version?.split('-');
						if(temp){
							let dateString =
							temp[1].substring(0, 4) + '-' + temp[1].substring(4, 6) + '-' + temp[1].substring(6, 8);
						
						setNlpVersion(res.NLP_Version),
						setNlpLastUpdated(dateString)
						}
					}
		
					getstudiesList().then((res) => {
						const updateStudyList = [];
						let promiseStudyInformation = [];
						const updateStudyInfo = res?.data?.map((study) => {
							promiseStudyInformation.push(getStudyInformation(study.study_name));
						});
						Promise.all(promiseStudyInformation)
							.then((response) => {
								let batchList = [];
								let isProcessing = false;
								let isAllAdded = true;
		
								response.map((items, index) => {
									/// code for adding values in study list
									let currentStudy = res.data[index];
									if (currentStudy.study_directory == null) {
										updateStudyList.push({
											label: currentStudy.study_name,
											value: currentStudy.study_name,
											added: false
										});
									}
									// code for populating batches
									if (items.code == 503) {
										setDisableAdd(false);
										setSuccess('');
										setErr(errAdd || '');
										setKey(forcedTabSelect ? key : 1)
										return;
									} else {
										let trainingData = items.training;
										let validationData = items.validation;
		
										// let totalDocs = items.total_num_docs;
										if (trainingData || validationData) {
											let studyBatches = trainingData.concat(validationData);
											studyBatches.map((data) => {
												if (data.active == 'Yes') {
													batchList.push({
														studyName: res.data[index].study_name,
														...data
														// num_patients: data.num_patients,
														// status: data.status,
														// manual_annotation: data.manual_annotation,
														// active: data.active,
														// total_num_docs: data.total_num_docs,
														// num_encounters: data.num_encounters,
														// batch_name: data.batch_name,
														// NLP_processing_date: data.NLP_processing_date,
														// NLP_processing_version: data.NLP_processing_version
													});
													if (
														data.status === progressStatus.InProgress ||
														data.status === progressStatus.ReprocessInprogress || 
														data.status === progressStatus.RescanInprogress || 
														data.status === progressStatus.ReprocessInqueue
													) {
														isProcessing = true;
													}
													if (data.status !== progressStatus.Added) {
														isAllAdded = false;
													}
												}
											});
											let predictionData = batchList.filter((items) =>
												items.batch_name.toLowerCase().includes('prediction')
											);
		
											let predictionCompletedData = predictionData.filter(
												(items) => items.status == progressStatus.ProcessingComplete || items.status == progressStatus.ReprocessComplete
											);
		
											let predictionRescanFailed = predictionData.filter(
												(items) => items.status == progressStatus.RescanFailed
											);
		
											setPredictionBatchList(predictionData);
											setShowRescan(
												predictionData.length > 0 && !isProcessing && 
													(predictionData.length == predictionCompletedData.length ||
														predictionRescanFailed.length > 0)
														? true
														: false
											)
		
											// studyList.push({
											// 	studyName: res.data[index].study_name,
											// 	num_patients: items.num_patients,
											// 	status: items.status
											// });
		
											/// code for adding values in study list
											if (isAllAdded) {
												updateStudyList.push({
													label: currentStudy.study_name,
													value: currentStudy.study_name,
													added: true
												});
											}
										}
									}
									if (items.status && items.status == 'Added') {
										setDisableAdd(true)
										setSuccess('Study addition in progress');
										setErr('')
									} else if (items.status && items.status != 'Added') {
										setDisableAdd(false)
										setErr('Study addition failed');
										setSuccess('')
									} else {
										setDisableAdd(false)
										setSuccess('');
										setErr('');
										setErrAdd('')
									}
									if (items.training || items.validation) {
										setDisableAdd(false)
										setSuccess('');
										setErr('');
										setErrAdd('');
										setKey(forcedTabSelect ? key : 2)
									}
								});
		
								setStudyList(updateStudyList);
								setStudyInformation(batchList);
								console.log("batchList",batchList);
								setReprocessInprogress(isProcessing)
							})
							.catch((error) => {});
					});
				});
			} 
		});
		
	};

	const toggleUploadForm = () => {
		setShowUploadForm(!showUploadForm);
		setShowForm(!showForm)
	};

	const toggleProjectForm = () => {
		setShowProjectForm(!showProjectForm);
		setShowForm(!showForm)
	};

	/*****************************************
	 * Function name: handleClick
	 * Description: This function ters the state of the modal.
	//  * Sets the open state of the modal to true to open the modal.
	****************************************/
	const handleClick = () => {
		refreshStudy();
		setOpen(true)
	};

	/*****************************************
	 * Function name: handleClose
	 * Description: This function alters the state of the modal.
	 * Sets the open state of the modal to false to close the modal.
	 * Also adds a css class to it for designing.
	****************************************/
	const handleClose = (key) => {
		if (key) {
			setOpen(false)
			setKey(5)
		} else {
			setOpen(false);
			setKey(1);
			setShowManualUpload(false);
			setShowForm(false);
			setShowProjectForm(false);
			setShowUploadForm(false)
		}

		//SET CLOSE MODAL IN REDUX
		clearTimeout(intervalId);
		openModal('');
		if (props.onClose) {
			props.onClose();
		}
        localStorage.removeItem('forcedTabSelect')
	};

	const handleOptionSelect = (option) => {
		setShowForm(false)
		if (option == 1) {
			setInputStudy('');
			setStudyDirPath('')
			getStudyList();
		}
		if (option == 2) {
			getStudyList();
		}
	};

	const searchBatch = (event) => {
		setSearchBatchText(event.target.value)
	};

	const handleSelect = (key) => {
		if (key == 1) {
			setMenuOptions(menuOptions1)
		}
		if (key == 2) {
			setMenuOptions(menuOptions2)
		}
		if (key == 3) {
			getProjectData(1, 10);
		}
		setKey(key);
        setForcedTabSelect(true);
		setErr('');
		setErrAdd('')
	};

	const getProjectData = (pageNumber, pageSize) => {
		getUserProject({ pageNumber, pageSize }).then((res) => {
			setProjects(res.data.data);
			setNoOfRecords(res.data.noOfRecords)
		});
	};

	const deleteProjects = (project_id) => {};
	const closeManualUpload = () => {
		setKey(1);
		setShowManualUpload(false);
		setShowForm(false);
		setShowProjectForm(false);
		setShowUploadForm(false);
	};

	/********************************************
	 * Add Study menthod 
	 * 
	 * *******************************************/

	const addStudy = (event) => {
		setStudyDirPath(event.target.value)
	};

	const onStudySelect = (selectedStudy) => {
		setInputStudy(selectedStudy.value);
		setInputStudyAdded(selectedStudy.added);
	};

	const okClick = () => {
		if (!inputStudy || !studyDirPath) {
			setErrAdd('Study name and study directory are required');
			setSuccess('');
			setErr('Study name and study directory are required')
			return;
		}
		if (inputStudyAdded) {
			confirmPopupStudy.current.handleClick();
		} else {
			addStudyFromdirectory();
		}
	};

	const addStudyFromdirectory = () => {
			setIsLoading(true);
			addStudyfromdirectory(inputStudy, studyDirPath)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					setSuccess('Study has been successfully added.');
					setErrAdd('')
					getStudyList();
				} else {
					setErrAdd('Document(s) or note file not found for study. Failed to add study.');
					setSuccess('');
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
		setErr('');
		setSuccess('');
		setInputStudy('');
		setStudyDirPath('');
	};

	const startProcessingClick = (studyInfo, status) => {
		let actionName;
		switch (studyInfo.status) {
			case progressStatus.Added:
				actionName = 'ProcessDocs';
				break;
			// case progressStatus.InProgress:
			// 	break;
			case progressStatus.ProcessingComplete:
				if (studyInfo.manual_annotation == 'Yes') {
					actionName = 'UpdateManualAnnotation';
				} else {
					actionName = 'ReprocessDocsFull';
				}
				break;
			case progressStatus.ProcessingFailed:
				actionName = 'ReprocessDocsFull';
				break;
			case progressStatus.AnnotationCreated:
				actionName = 'ReprocessDocs';
				break;
			case progressStatus.AnnotationFailed:
				actionName = 'RedoUpdateManualAnnotation';
				break;
			case progressStatus.NoAnnotatorPresent:
				actionName = 'RedoUpdateManualAnnotation';
				break;
			default:
				actionName = 'ReprocessDocs';
				break;
		}
		setIsLoading(true);
		doStudyAction(studyInfo.studyName, studyInfo.batch_name, actionName, nlpVersion)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					let msg =
						(studyInfo.status === progressStatus.ProcessingComplete &&
							studyInfo.manual_annotation == 'Yes') ||
						studyInfo.status === progressStatus.AnnotationFailed || studyInfo.status === progressStatus.NoAnnotatorPresent
							? 'Manual annotation project being created.'
							: 'Processing started.';
					setInfoPopupMessage(msg);
				} else {
					setInfoPopupMessage('Undefined error in processing.')
				}
			})
			.catch(() => {
				setIsLoading(false);
			});

		// if (status === progressStatus.Added || status === progressStatus.ProcessingFailed || status === progressStatus.AnnotationCreated) {
		// 	this.props.dispatch(actions.setIsLoading(true));
		// 	this.props.dispatch(actions.doStudyAction(studyName,  status === progressStatus.Added ? 'ProcessDocs' : 'ReprocessDocs')).then((reponse) => {
		// 		if (reponse.code === 200) {
		// 			this.setState({ infoPopupMessage: 'Processing started.' }, () => {
		// 				this.infoPopup.handleClick();
		// 			});
		// 		} else {
		// 			this.setState({ infoPopupMessage: 'Undefined error in NLP processing.' }, () => {
		// 				this.infoPopup.handleClick();
		// 			});
		// 		}
		// 	});
		// } else if (status === progressStatus.ProcessingComplete || status === progressStatus.AnnotationFailed) {
		// 	this.props.dispatch(actions.setIsLoading(true));
		// 	this.props.dispatch(actions.doStudyAction(studyName, 'updatemanualannotation')).then((reponse) => {
		// 		if (reponse.code === 200) {
		// 			this.setState({ infoPopupMessage: 'Manual annotation project being created.' }, () => {
		// 				this.infoPopup.handleClick();
		// 			});
		// 		} else {
		// 			this.setState({ infoPopupMessage: 'Undefined error in update manual annotation.' }, () => {
		// 				this.infoPopup.handleClick();
		// 			});
		// 		}
		// 	});
		// }
	};

	const refreshStudy = () => {
		clearTimeout(intervalId);

        intervalId = setTimeout(() => {
			getStudyList();
			refreshStudy();
		}, 10000);
		// this.props.dispatch(actions.setIsLoading(false));
	};

	const cancelClick = () => {
		setInputStudy('');
		setStudyDirPath('');
		setErr('');
		setSuccess('');
		setErrAdd('');
		// this.getStudyList();
	};

	const gotoPage = (pageNo, recordsPerPage) => {
		getProjectData(pageNo, recordsPerPage);
		setCurrentPage(pageNo)
	};

	const openConfirmationPopup = (studyInfo, skip) => {
		if (skip) {
			startProcessingClick(studyInfo);
		} else {
			setSelectedStudy(studyInfo)
			confirmPopup.current.handleClick();
			// confirmStudyBatch.handleClick();
		}
	};

	const startReProcessingClick = (studyInfo, batchNames) => {
		setIsLoading(true);
		doStudyAction(studyInfo.studyName, batchNames, 'ReprocessDocs', nlpVersion)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					let msg =
						(studyInfo.status === progressStatus.ProcessingComplete &&
							studyInfo.manual_annotation == 'Yes') ||
						studyInfo.status === progressStatus.AnnotationFailed || studyInfo.status === progressStatus.NoAnnotatorPresent
							? 'Manual annotation project being created.'
							: 'Processing started.';
					setInfoPopupMessage(msg);
				} else {
					setInfoPopupMessage('Undefined error in processing.')
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};
	
	const startEncounterLevelClick = (studyInfo, batchNames) => {
		setIsLoading(true);
		doStudyAction(studyInfo.studyName, batchNames, 'EncounterLevelInferencing', nlpVersion)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					let msg =
						(studyInfo.status === progressStatus.ProcessingComplete &&
							studyInfo.manual_annotation == 'Yes') ||
						studyInfo.status === progressStatus.AnnotationFailed || studyInfo.status === progressStatus.NoAnnotatorPresent
							? 'Manual annotation project being created.'
							: 'Processing started.';
					setInfoPopupMessage(msg);
				} else {
					setInfoPopupMessage('Undefined error in processing.')
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};
	
	const startDiscreteProcessingClick = (studyInfo, batchNames) => {
		setIsLoading(true);
		doStudyAction(studyInfo.studyName, batchNames, 'DiscreteProcessing', nlpVersion)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					let msg =
						(studyInfo.status === progressStatus.ProcessingComplete &&
							studyInfo.manual_annotation == 'Yes') ||
						studyInfo.status === progressStatus.AnnotationFailed || studyInfo.status === progressStatus.NoAnnotatorPresent
							? 'Manual annotation project being created.'
							: 'Processing started.';
					setInfoPopupMessage(msg);
				} else {
					setInfoPopupMessage('Undefined error in processing.')
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const openReProcessingPopup = (studyList) => {
		let batchNames = []; 
		
		studyList.map((list) => {
			if(list.isChecked == true){
				batchNames.push(list.batch_name)
			}
		});

		startReProcessingClick(studyList[0], batchNames.join());
	};
	
	const openEncounterLevelPopup = (studyList) => {
		let batchNames = []; 
		
		studyList.map((list) => {
			if(list.isChecked == true){
				batchNames.push(list.batch_name)
			}
		});

		startEncounterLevelClick(studyList[0], batchNames.join());
	};
	
	const openDiscreteProcessingPopup = (studyList) => {
		let batchNames = []; 
		
		studyList.map((list) => {
			if(list.isChecked == true){
				batchNames.push(list.batch_name)
			}
		});

		startDiscreteProcessingClick(studyList[0], batchNames.join());
	};
	

	const confirmReProcess =(studyInfo, skip, reprocess) => {

		console.log("in compo",reProcessBatchList)
		let _reprocessBatchList = Object.assign([], reProcessBatchList);
		_reprocessBatchList.map((list) => {
			if(list.batch_name === studyInfo.batch_name){
				return(list.isChecked = true)
			} else {
				return(list.isChecked = false)
			}
		})
		
		setreProcessBatchList(_reprocessBatchList);
		if(reprocess){
			reProcessPopup.current.handleClick();
		}
	}

	const confirmPopupOk = () => {
		startProcessingClick(selectedStudy);
	};

	const confirmPopupStudyOk = () => {
		addStudyFromdirectory();
	};
	const getLatestDate = (prev, next) => {
		if (prev) {
			if (new Date(prev.NLP_processing_date) > new Date(next.NLP_processing_date)) {
				return prev;
			} else {
				return next;
			}
		} else {
			return next;
		}
	};
	const rescanPredictionBatch = () => {
		let lastUpdatedPredictionBatch = predictionBatchList.reduce(getLatestDate);
		setIsLoading(true);
		doStudyAction(
					lastUpdatedPredictionBatch.studyName,
					lastUpdatedPredictionBatch.batch_name,
					'rescan',
					''
			)
			.then((reponse) => {
				setIsLoading(false);
				if (reponse.code === 200) {
					setInfoPopupMessage('Rescan started')
				} else {
					setInfoPopupMessage(reponse.description || 'Error in rescan.')
				}
			}).catch(error=>{
				setIsLoading(false);
				setInfoPopupMessage('Error in rescan.')
			});
	};

		return (
			<div>
				<ErrorBoundary>
					<ModalComponent
						open={open}
						handleClose={handleClose}
						content={
							<div className="modal_body_content studyAdmin-modal">
								{!showForm ? (
									<React.Fragment>
										<div className="modal-heading headerbar product-setting admin">
											<div
												style={{
													display: 'inline-block',
													width: '50%',
													paddingTop: 4,	
													paddingLeft: 0,
													margin: 0
												}}
											>
												Study administration
											</div>
											<div style={{ display: 'inline-block', width: '15%' }} />
											<div style={{ display: 'inline-block', width: '30%' }}>
												{
													// 	/* showSearch && (
													// 		<div className="searchContainer" style={{width: 247,position:'relative'}}>
													// 			<SearchComponent title="batches" searchFunction={searchBatch}/>
													// 		</div>
													// 	) */
												}
											</div>
											{menuOptions.length > 0 && (
												<div style={{ display: 'inline-block', width: '5%', textAlign: 'right' }}>
													<HamburgerMenu
														userRole={props.redSidenav.userRole}
														options={menuOptions}
														handleMenuClick={handleOptionSelect}
													/>
												</div>
											)}
										</div>
										<div className="modal-container">
											<Tabs
												activeKey={key}
												onSelect={handleSelect}
												id="controlled-tab-example"
            									className="nav-tabs-update"
											>
												<Tab eventKey={1} title="Add study">
													<div className="add-study">
														<label className="study-label"> Select study name</label>
														<CustomSelect
															value={studyList.filter(function(option) {
																return option.value === inputStudy;
															  })}
															onChange={(value) => onStudySelect(value)}
															options={studyList}
															placeholder="Select study name"
															className="study-select"
															searchable={false}
															clearable={false}
															required={true}
														/>
													</div>
													<div className="add-study">
														<label className="study-label"> Add study directory</label>
														<input
															value={studyDirPath}
															type="text"
															className="study-input"
															placeholder="Add study directory"
															onChange={(e) => addStudy(e)}
															required
														/>
													</div>
													<div className="error_field">
														{err && <label className="study-error">{err}</label>}
														{success && <label className="study-success">{success}</label>}
													</div>
													<div className="trialButton addNewTrial">
														<button
															type="button"
															className="trialArm"
															onClick={cancelClick}
															disabled={disableAdd}
														>
															Clear
														</button>
														<button
															type="button"
															className="trialArm"
															onClick={okClick}
															disabled={disableAdd}
														>
															Ok
														</button>
													</div>
												</Tab>
												<Tab eventKey={2} title="Studies">
													<div>
														<span style={{ paddingRight: '100px' }}>
															Current NLP version: {nlpVersion}
														</span>
														<span>Last updated: {nlpLastUpdated}</span>
														{showRescan && (
															<div
																style={{
																	float: 'right',
																	height: '30px',
																	width: '200px'
																}}
															>
																<button
																	type="button"
																	className="btn-action-full"
																	onClick={rescanPredictionBatch}
																>
																	Re-scan
																</button>
															</div>
														)}
													</div>
													<TableBatch
														startProcessingClick={openConfirmationPopup}
														studyInformation={studyInformation}
														nlpVersion={nlpVersion}
														reprocessInprogress={reprocessInprogress}
														confirmReProcess={confirmReProcess}
													/>
												</Tab>
												<Tab eventKey={3} title="Manual annotation">
													{projects &&
													projects.length > 0 && (
														<TableProjects
															data={projects}
															deleteMode={deleteMode}
															deleteMethod={deleteProjects}
															addProjectForm={toggleProjectForm}
															noOfRecords={noOfRecords}
															gotoPage={gotoPage}
															pageNo={currentPage}
														/>
													)}
												</Tab>
											</Tabs>
										</div>
									</React.Fragment>
								) : (
									<React.Fragment>
										{showUploadForm && (
											<Suspense fallback={<div className="defaultSpinner" />}>
											<UploadData
												handleClose={toggleUploadForm}
												handleSelect={handleSelect}
											/>
											</Suspense>
										)}
										{showProjectForm && (
											<Suspense fallback={<div className="defaultSpinner" />}>
											<ProjectUpload
											// handleClose={toggleProjectForm}
											// deleteMode={deleteMode}
											// handleSelect={handleSelect}
											/>
											</Suspense>
										)}
										{showManualUpload && (
											<Suspense fallback={<div className="defaultSpinner" />}>
											<ManualUploadText
											// popupRef={previewPopup}
											// handleClose={closeManualUpload}
											// closeAll={handleClose}
											/>
											</Suspense>
										)}
									</React.Fragment>
								)}
							</div>
						}
						className="Data-Management study-admin"
					/>
					{
						<React.Fragment>
							<InfoPopup
								rootClosexit={() => {}}
								open={(c) => (infoPopup.current = c)}
								text={infoPopupMessage}
								confirmClick={getStudyList}
							/>
						</React.Fragment>
					}
					{
						<React.Fragment>
							<ReProcessBatch
								rootClosexit={() => {}}
								open={(c) => (reProcessPopup.current = c)}
								text={selectToReProcessPopup}
								reProcessBatchList={reProcessBatchList}
								progressStatus={progressStatus}

								confirmClick={openReProcessingPopup }
								confirmEncounterNLP={openEncounterLevelPopup }
								confirmDiscreteProcessing={openDiscreteProcessingPopup }
							/>
						</React.Fragment>
					}
					{
						<React.Fragment>
							<MessagePopup
								rootClosexit={() => {}}
								open={(c) => (confirmPopup.current = c)}
								text={'Are you sure, your changes will be lost on ok click?'}
								confirmClick={confirmPopupOk}
							/>
						</React.Fragment>
					}
					{
						<React.Fragment>
							<MessagePopup
								rootClosexit={() => {}}
								open={(c) => (confirmPopupStudy.current = c)}
								text={'Are you sure, your changes will be lost on ok click?'}
								confirmClick={confirmPopupStudyOk}
							/>
						</React.Fragment>
					}
					{
						<React.Fragment>
							<StudyBatchPopup
								rootClosexit={() => {}}
								open={(c) => (confirmStudyBatch.current = c)}
								confirmClick={confirmPopupStudyOk}
							/>
						</React.Fragment>
					}
				</ErrorBoundary>
			</div>
		);
})

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(StudyAdministration));

const TableBatch = ({ startProcessingClick, studyInformation, nlpVersion, reprocessInprogress, confirmReProcess }) => {
	
	const getButtonStatus = (studyInfo) => {
		let btnProcess;
		if (reprocessInprogress) {
			btnProcess = <span>NA</span>;
		} else {
			// } else if (studyInfo.NLP_processing_version === '' || nlpVersion !== studyInfo.NLP_processing_version) {
			switch (studyInfo.status) {
				case progressStatus.Added:
					btnProcess = (
						<button
							type="button"
							className="btn-action-full"
							onClick={() => startProcessingClick(studyInfo, true)}
						>
							{' '}
							Start processing
						</button>
					);
					break;
				case progressStatus.InProgress:
					btnProcess = <span>NA</span>;
					break;
				case progressStatus.AnnotationInprogress:
					btnProcess = <span>NA</span>;
					break;
				case progressStatus.ProcessingComplete:
					if (studyInfo.manual_annotation == 'Yes') {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								onClick={() => startProcessingClick(studyInfo, true)}
							>
								{' '}
								Create manual annotation project
							</button>
						);
					} else {
						if (nlpVersion !== studyInfo.NLP_processing_version) {
							btnProcess = (
								<button
									type="button"
									className="btn-action-full"
									// onClick={() => startProcessingClick(studyInfo, false, true)}
									onClick={() => confirmReProcess(studyInfo, false, true)}
								>
									{' '}
									Re-process
								</button>
							);
						} else {
							btnProcess = <span>NA</span>;
						}
					}
					break;
				case progressStatus.ProcessingFailed:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								// onClick={() => startProcessingClick(studyInfo, false, true)}
								onClick={() => confirmReProcess(studyInfo, false, true)}
							>
								{' '}
								Re-process
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.AnnotationCreated:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								// onClick={() => startProcessingClick(studyInfo, true, true)}
								onClick={() => confirmReProcess(studyInfo, true, true)}
							>
								{' '}
								Re-process
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.AnnotationFailed:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								onClick={() => startProcessingClick(studyInfo)}
							>
								{' '}
								Re-create manual annotation project
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.NoAnnotatorPresent:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								onClick={() => startProcessingClick(studyInfo)}
							>
								{' '}
								Re-create manual annotation project
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.ReprocessComplete:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								// onClick={() => startProcessingClick(studyInfo, false, true)}
								onClick={() => confirmReProcess(studyInfo, false, true)}
							>
								{' '}
								Re-process
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.ReprocessFailed:
					if (nlpVersion !== studyInfo.NLP_processing_version) {
						btnProcess = (
							<button
								type="button"
								className="btn-action-full"
								// onClick={() => startProcessingClick(studyInfo, false, true)}
								onClick={() => confirmReProcess(studyInfo, false, true)}
							>
								{' '}
								Re-process
							</button>
						);
					} else {
						btnProcess = <span>NA</span>;
					}
					break;
				case progressStatus.RescanFailed:
					btnProcess = <span>NA</span>;
					break;
				case progressStatus.RescanInprogress:
					btnProcess = <span>NA</span>;
					break;
				default:
					btnProcess = (
						<button
							type="button"
							className="btn-action-full"
							// onClick={() => startProcessingClick(studyInfo, false, true)}
							onClick={() => confirmReProcess(studyInfo, false, true)}
						>
							{' '}
							Re-process
						</button>
					);
					break;
			}
		}
		return btnProcess;
	};

	const getStatusText = (studyInfo) => {
		if (studyInfo.status === progressStatus.ReprocessComplete) {
			if (!studyInfo.batch_name.includes('prediction')) {
				return 'Manual annotation project created';
			} else {
				return studyInfo.status;
			}
		} else {
			return studyInfo.status;
		}
	};
	return (
		<table className="tbl-study-admin study-status" style={{ marginTop: 20 }}>
			<thead>
				<tr>
					<th>Study name</th>
					<th>Total patient records</th>
					<th>Number of patients</th>
					<th>Number of encounters</th>
					<th>Batch</th>
					<th>NLP version</th>
					<th>Last processed</th>
					<th>Status</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colSpan="4" style={{ padding: 4 }} />
				</tr>
				{studyInformation.map((studyInfo, index) => (
					<tr key={index} className={getStatusClass(studyInfo.status)}>
						<td>{studyInfo.studyName}</td>
						<td>{studyInfo.total_num_docs}</td>
						<td>{studyInfo.num_patients}</td>
						<td>{studyInfo.num_encounters}</td>
						<td style={{ textTransform: 'capitalize' }}>{studyInfo.batch_name}</td>
						<td>{studyInfo.NLP_processing_version}</td>
						<td>{studyInfo.NLP_processing_date}</td>
						<td>
							{getStatusText(studyInfo)}
							{/* {studyInfo.status === progressStatus.ReprocessComplete ? (
								'Manual annotation project created'
							) : (
								studyInfo.status
							)} */}
						</td>
						<td>
							<div style={{ width: 200 }}>{getButtonStatus(studyInfo)}</div>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

const TableProjects = ({ data, noOfRecords, gotoPage, pageNo, deleteMode, deleteMethod, addProjectForm }) => {
	return (
		<React.Fragment>
			<table className="tbl-tech-detail" style={{ marginTop: 20 }}>
				<thead>
					<tr>
						<th>Project name</th>
						<th>Status</th>
					</tr>
				</thead>
				{data &&
				data.length > 0 && (
					<tbody>
						<tr>
							<td colSpan="4" style={{ padding: 4 }} />
						</tr>
						{data.map((project, index) => (
							<tr key={index}>
								<td>
									{deleteMode && (
										<span>
											<img
												onClick={() => deleteMethod(project.projectId)}
												src={constants.IMG_URL + 'remove_minus_icon.png'}
												className="App-minusIcon"
												alt="minusIcon"
												width={20}
											/>
											<span style={{ padding: 4 }} />
										</span>
									)}
									<span className="hyperlink">{project.projectName}</span>
								</td>
								<td style={{ textTransform: 'capitalize' }}>{projectStatus[project.status]}</td>
							</tr>
						))}
						<tr>
							<td>
								{deleteMode && (
									<span>
										<img
											onClick={addProjectForm}
											src={constants.IMG_URL + 'add_plus_icon.png'}
											className="App-minusIcon"
											alt="minusIcon"
											width={20}
										/>
										<span style={{ padding: 4 }} />
									</span>
								)}
							</td>
							<td />
						</tr>
					</tbody>
				)}
			</table>
			<PaginationComponent totalItemsCount={noOfRecords} gotoPage={gotoPage} currentPage={pageNo} />
		</React.Fragment>
	);
};
