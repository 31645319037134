import { apiGet } from "../../utilities/utils";
import actionTypes from "../../actions/actionTypes";
import store from "../../store";

/**************************************** 
     * Function Name: getstudiesList
     * Description: exports an action to get the trial list data from the backend
     * The data received gets set in the trials reducer which can be used
     * later for manipulation. 
     * Method has no signature.
****************************************/

export function getstudiesList() {
	return new Promise((resolve, reject) => {
			apiGet('GetStudiesList')
				.then((res) => {
					store.dispatch({
						type: actionTypes.GET_STUDY_LIST,
						payload: res.data.data
					});
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: addStudyFromdirectory
     * Description: Add study for virtual appliance 
     * Method has no signature.
****************************************/

export function addStudyfromdirectory(studyName, studyDirpath) {
	return new Promise((resolve, reject) => {
			apiGet('AddStudyFromDirectory', { m_studyName: studyName, m_studyDirectoryPath: studyDirpath })
				.then((res) => {
					store.dispatch({
						type: actionTypes.ADD_STUDY_NAME,
						payload: res.data
					});
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: getStudyInformation
     * Description: get detail of study information
     * Method has one signature.
****************************************/
export function getStudyInformation(studyName) {
	return new Promise((resolve, reject) => {
			apiGet('GetStudyInformation', { m_studyName: studyName })
				.then((res) => {
					store.dispatch({
						type: actionTypes.GET_STUDY_INFO,
						payload: res.data
					});
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: doStudyAction
****************************************/

export function doStudyAction(studyName, batchName, processDocs, nlpVersion) {
	return new Promise((resolve, reject) => {
			let data = { m_studyName: studyName, m_batchName: batchName, m_action: processDocs };
			if (nlpVersion) {
				data.m_nlpVersion = nlpVersion;
			}
			apiGet('DoStudyAction', data)
				.then((res) => {
					store.dispatch({
						type: actionTypes.DO_STUDY_ACTION,
						payload: res.data
					});
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: getNLPInfo
****************************************/

export function getNLPInfo() {
	return new Promise((resolve, reject) => {
			apiGet('GetNLPInfo')
				.then((res) => {
					resolve(res.data);
					// resolve({
					// 	NLP_Version: '1.0.0-20201218-1',
					// 	UI_Version: '1.0.0_20201218-1',
					// 	NLP_Jar_File_Version: '1.0.0-20201218-1-adsgcha',
					// 	UI_Jar_File_Version: '1.0.0_20201218-1-adsgcha',
					// 	Parser_Jar_File_Version: '1.0.0_20201218-1-adsgcha',
					// 	Profiling_Jar_File_Version: '1.0.0_20201218-1-adsgcha',
					// 	NLP_Build_Version: '1.0.0_20201218_1_adsgcha',
					// 	UI_Build_Version: '1.0.0_20201218_1_adsgcha'
					// });
				})
				.catch((err) => {
					reject(err);
				});
		});
}
/**************************************** 
     * Function Name: getUserProject
     * Description: Fetch user batches
     * @param - query - Object with required parameters.
****************************************/
export function getUserProject(query){
    return new Promise((resolve, reject) => {
         apiGet("GetProjects", query).then(res => {
              resolve(res)
         })
         .catch(err => {
              console.log(err);
              reject(err);
         })
    })
}

export function openModal(modalType) {
	store.dispatch({
			type: actionTypes.OPEN_MODAL_POPUP,
			payload: modalType
		});
}