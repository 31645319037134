/* istanbul ignore file */
import actionTypes from '../../actions/actionTypes';
import { apiGet } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';
/****************************************
 * Function Name: getCohortList
 * Description: exports an action to get the cohort list data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getCohortList = (params) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
};
/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

 export function getEvent(){
	return new Promise(function(resolve, reject){
		apiGet('GetEvent', {
			'eventType' : 'outcome'
		}).then(res => {
			store.dispatch({
				type: actionTypes.GET_EVENT,
				payload: res.data.data.reverse()
			});
			resolve(res.data.data.reverse());
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	})
}
/** **************************************
 * Function Name: clearStudyCohorts
 * Description: exports an action to clear the study cohorts data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const clearStudyCohorts = () => {
	return {}
};

/** **************************************
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
 export const getStudyDetail = (study_id) => {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: getStudyArmList
 * Description: exports an action to get the study list data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/


export function getStudyArmList(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyArmList', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_ARM_LIST,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
/****************************************
 * Function Name: getCohortCriteria
 * Description: exports an action to get the cohort criterion
 * from the backend.
 * The data received is returned via promise which can be used
 * for manipulation.
 * Method has one signature.
 * @param - cohort_id - used to make the api call based on the cohort ID.
 ****************************************/

export const getCohortCriteria = (cohort_id) => {
	return new Promise((resolve, reject) => {
		// resolve(res)
		resolve()
	});
};
/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

 export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export const searchConceptsById = (conceptIds, eventIds) => {
// 	return new Promise((resolve, reject) => {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };
/** **************************************
 * Function Name: saveStudyCohorts
 * Description: exports an action to get the study cohorts data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const saveStudyCohorts = (studyCohort, cohortIndex) => {
	return store.dispatch({
		type: actionTypes.SAVE_STUDY_COHORTS,
		cohortIndex: cohortIndex,
		payload: studyCohort,
	});
};
/** **************************************
 * Function Name: addStudy
 * Description: Add study data and send to the backend.
 * later for manipulation.
 * Method has five signature.
 ****************************************/
export const addStudy = (base_cohort, study_name, m_StudyArmString, event_type, event_id) => {
	return new Promise((resolve, reject) => {
		apiGet('AddStudy', {
			'm_StudyObj.base_cohort': base_cohort,
			'm_StudyObj.study_name': study_name,
			m_StudyArmString: m_StudyArmString,
			'm_OutcomeObj.event_type': event_type,
			m_eventIds: event_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: editStudy
 * Description: exports an action to Update the study detail data from backend
 * later for manipulation.
 * Method has six signature.
 ****************************************/
export const editStudy = (
	study_id,
	base_cohort,
	study_name,
	m_StudyArmString,
	event_type,
	event_id
) => {
	return new Promise((resolve, reject) => {
		apiGet('EditStudy', {
			'm_StudyObj.study_id': study_id,
			'm_StudyObj.base_cohort': base_cohort,
			'm_StudyObj.study_name': study_name,
			m_StudyArmString: m_StudyArmString,
			'm_OutcomeObj.event_type': event_type,
			m_eventIds: event_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/** **************************************
 * Function Name: deleteStudy
 * Description: exports an action to delete the study data from the backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export const deleteStudy = (m_studyId) => {
	return new Promise((resolve, reject) => {
		apiGet('DeleteStudy', {
			m_studyId: m_studyId,
		})
			.then((res) => {
				resolve(res);
				// console.log(res)
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
