import React from 'react'

const CheckBox = ({type = "checkbox", name, onClick, id, value, checked, noLink}) => {
    return (
        <div className={`${ noLink ? '' : 'hyperlink'}`}>
            <input className="custom-control-input" id={id} onClick={onClick} type={type} name={name} value={value} checked={checked} onChange={()=>{}}/>
            <label  className="modal-label custom-control-label " htmlFor={id}>{name}</label>
        </div>
    )
}

export default CheckBox;