/* istanbul ignore file */
import store from '../../store';
import actionTypes from '../../actions/actionTypes';
import { apiGet, partial } from '../../utilities/utils';

export function setCriteriaData(criteriaData) {
	store.dispatch({
		type: actionTypes.SET_CRITERIA_DATA,
		payload: criteriaData,
	});
}

export function getEvent() {
	return new Promise(function (resolve, reject) {
		apiGet('GetEvent', {
			eventType: 'outcome',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data?.data.reverse(),
				});
				resolve(res.data?.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function editCohort(cohort_id, cohort_name, criterionString, eventIds) {
	return new Promise(function (resolve, reject) {
		apiGet('EditCohort', {
			'cohortObj.cohort_id': cohort_id,
			'cohortObj.cohort_name': cohort_name,
			criterionString: criterionString,
			eventIds,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function addCohort(cohort_name, criterionString, eventIds) {
	return new Promise(function (resolve, reject) {
		apiGet('AddCohort', {
			'cohortObj.cohort_name': cohort_name,
			criterionString: criterionString,
			eventIds,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
let GetSearchConcepts = partial(apiGet, 'SearchConcepts');

export function getConceptsForTextInput(text) {
	return new Promise((resolve, reject) => {
		let searchConceptsForText = GetSearchConcepts({
			'conceptTableEntry.umls_concept_category': 'All',
			'conceptTableEntry.display_name': text,
		});
		searchConceptsForText
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function setIsLoading(loading) {
	store.dispatch({
		type: actionTypes.IS_LOADING,
		payload: loading,
	});
}