/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { filterList } from '../../utilities/utils';
import CountLinkLoader from '../../components/CountLinkLoader/CountLinkLoader';
import {
	getManAnnotationIcon,
	getCohortCounts,
	getQueryParamObject,
	gotoPage,
	gotoDataDetail,
	getCohortListByParams,
} from './CohortHelper';
import actions from '../../actions';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { roleAccess } from '../../utilities/AppConstants';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import ErrorBoundary from '../../components/ErrorBoundary';
import SortComponent from '../../common/SortComponent/SortComponent';
import Copyright from '../../components/Copyright/Copyright';
import { setIsLoading } from '../../actions/common';

import ManualTooltipScroll from '../../components/ManualTooltipScroll/manualTooltipScroll';
import cohortIcon from '../../images/cohort_icon.png';
import removeMinusIcon from '../../images/remove_minus_icon.svg';
import addPlusIcon from '../../images/add_plus_icon.svg';

const headerData = {
	title: 'Cohort',
	searchTitle: 'cohorts',
	list: 'list',
	img: (
		<img
			src={cohortIcon}
			className={'App-CohortList'}
			alt={'Cohort List'}
			style={{ width: '38px' }}
		/>
	),
};

// COUNTS = '', MEANS API IS NOT FETCHED YET, SHOW LOADER
const initialCounts = {
	study_count: '',
	cohort_count: '',
	data_count: '',
	patient_count: '',
};

const CohortList = (props) => {
	const [searchText, setSearchText] = useState('');
	const [cohorts, setCohorts] = useState(
		props?.redCohort?.cohortList ? props?.redCohort?.cohortList : []
	);
	const [filterApplied, setFilterApplied] = useState('');
	const [showLoading, setShowLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [selectedCohort, setSelectedCohort] = useState(-1);
	const [sortOrder, setSortOrder] = useState('');
	const [columnName, setColumnName] = useState('');
	const [duplicateCohortError, setDuplicateCohortError] = useState('');
	const filteredCohorts = filterList(searchText, cohorts, [{ dataKey: 'cohort_name' }]);
	let { userRole } = props.redSidenav;

	useEffect(() => {
		if (props.redSidenav.filter !== filterApplied) {
			setFilterApplied(props.redSidenav.filter);
			getCohorts(queryString.parse(props?.location?.search));
		}
	}, [props.redSidenav?.filter]);

	useEffect(() => {
		getCohorts(queryString.parse(props?.location?.search));
		let study_id = queryString.parse(window.location.search).study_id;
		let concept_id = queryString.parse(window.location.search).concept_id;
		let cohort_id = queryString.parse(window.location.search).cohort_id;

		if (study_id) {
			setIsLoading(true);
			props.dispatch(actions.getStudyDetail(study_id)).then((studyDetail) => {
				setIsLoading(false);
				let options = [
					{
						filterLabel: 'Study',
						name: studyDetail.study_name,
					},
				];
				setFilter(options);
			});
		}
		if (concept_id) {
			setIsLoading(true);
			props.dispatch(actions.searchConceptsById(concept_id)).then((resp) => {
				setIsLoading(false);
				let options = [
					{
						filterLabel: 'Concept',
						name: resp[0].text,
					},
				];
				setFilter(options);
			});
		}
		sortASC('cohort_name', 'asc');
	}, []);

	const setFilter = (options) => {
		let filterLabel = {
			page: 'patient',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	/** **************************************
	 * Function Name: getCohorts
	 * Description: Fetch API to get cohorts
	 * @param query - url query parameters object from react router.
	 ****************************************/
	const getCohorts = (query) => {
		setShowLoading(true);

		const queryparams = getQueryParamObject(query);
		getCohortListByParams(queryparams).then((data) => {
			// props.dispatch(actions.getCohortList(queryparams)).then((data) => {
			const cohortIdList = [];
			const cohort = data.data.data;
			cohort.forEach((cohort) => {
				cohort.counts = { ...initialCounts };
				cohortIdList.push(cohort.cohort_id);
			});

			setCohorts(cohort);
			setShowLoading(false);
			// GET ICON INFORMATION
			getManAnnotationIcon([...cohort], cohortIdList).then((cohorts) => {
				setCohorts(cohorts);
			});

			// GET COUNTS
			getCohortCounts([...cohort], cohortIdList.join()).then((cohorts) => {
				setCohorts(cohorts);
			});
		});
	};

	/** **************************************
	 * Function Name: searchCohort
	 * Description: Set state variable
	 * @param event Event object on search input box.
	 ****************************************/
	const searchCohort = (event) => {
		setSearchText(event.target.value);
	};

	/** **************************************
	 * Function Name: addCohort
	 * Description: Navigates to add cohort page.
	 * Method has no parameter
	 ****************************************/
	const addCohort = () => {
		setEditMode(true);
		// props.dispatch(actions.setHistory({ ...window.location, display_name: 'Cohort list' }));
		// props.history.push(constants.ROUTE_URL + '/dashboard/addcohort');
	};

	const exitEditMode = () => {
		setEditMode(false);
	};

	const removeCohort = (cohortId) => {
		setConfirmDelete(true);
		setSelectedCohort(cohortId);
	};

	const deleteCohort = () => {
		setShowLoading(true);
		props.dispatch(actions.deleteCohort(selectedCohort)).then(
			(res) => {
				if (res.data.code === 200) {
					setShowLoading(false);
					setConfirmDelete(false);
					getCohorts(queryString.parse(props?.location?.search));
				} else if (res.data.code === 400) {
					setShowLoading(false);
					let duplicateCohortErr = res.data.description;
					setDuplicateCohortError(duplicateCohortErr);
				}
			},
			(error) => {
				setShowLoading(false);
				// if (error.response.data.code === 400) {
				//     let duplicateCohortErr = error.response.data.description;
				//     setDuplicateCohortError(duplicateCohortErr)
				// }
				// Hide confirm modal
			}
		);
	};

	/*****************************************
	 * Function name: handleClose
	 * Description: This function alters the state of the modal.
	 * Sets the open state of the modal to true and adds the shake animation
	 * class to the modal box whenever user clicks outside the modal.
	 * setTimeout is is used for continuous use of shake animation.
	 ****************************************/
	const handleClose = () => {
		setDuplicateCohortError('');
		setConfirmDelete(false);
	};

	const addCohortRedirect = () => {
		props.dispatch(actions.setHistory({ ...window.location, display_name: 'Cohort list' }));
		props.history.push(constants.ROUTE_URL + '/dashboard/addcohort');
	};

	/** **************************************
	 * Function Name: gotoCohortDesign
	 * Description: Navigate to cohort design page.
	 * @param cohort_id Cohort id
	 ****************************************/
	const gotoCohortDesign = (cohortId) => {
		setHistory();
		props.history.push(constants.ROUTE_URL + '/dashboard/cohortdesign/' + cohortId);
	};

	/*********************************
	 *  Function Name: Sort functionality
	 *******************************/

	const sortASC = (columnName, order) => {
		if (!order) {
			if (!sortOrder || sortOrder == 'dsc') {
				setSortOrder('asc');
				setColumnName(columnName);
			} else {
				setSortOrder('dsc');
				setColumnName(columnName);
			}
		} else {
			setSortOrder(order);
			setColumnName(columnName);
		}
	};

	const sortOption = (arr) => {
		if (sortOrder == 'asc') {
			try {
				arr.sort(function (a, b) {
					if (columnName.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (
						a[columnName].toString().toLowerCase() <
						b[columnName].toString().toLowerCase()
					) {
						return -1;
					}
					if (
						a[columnName].toString().toLowerCase() >
						b[columnName].toString().toLowerCase()
					) {
						return 1;
					}
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			try {
				arr.sort(function (a, b) {
					if (columnName.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (
						a[columnName].toString().toLowerCase() <
						b[columnName].toString().toLowerCase()
					) {
						return 1;
					}
					if (
						a[columnName].toString().toLowerCase() >
						b[columnName].toString().toLowerCase()
					) {
						return -1;
					}
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		}
		return arr;
	};

	const sortOptionCount = (arr) => {
		if (sortOrder == 'asc') {
			try {
				arr.sort(function (a, b) {
					if (columnName.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (a[columnName] < b[columnName]) {
						delete a.columnName;
						delete b.columnName;
						return -1;
					}
					if (a[columnName] > b[columnName]) {
						delete a.columnName;
						delete b.columnName;
						return 1;
					}
					delete a[columnName];
					delete b[columnName];
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			try {
				arr.sort(function (a, b) {
					if (columnName.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (a[columnName] < b[columnName]) {
						delete a.columnName;
						delete b.columnName;
						return 1;
					}
					if (a[columnName] > b[columnName]) {
						delete a.columnName;
						delete b.columnName;
						return -1;
					}
					delete a[columnName];
					delete b[columnName];
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		}
		return arr;
	};

	// if (sortOrder && cohorts.length) {
	//     cohort = sortOption(cohorts);
	// }

	if (
		columnName == 'study_count' ||
		columnName == 'cohort_count' ||
		columnName == 'patient_count' ||
		columnName == 'data_count'
	) {
		sortOptionCount(cohorts);
	} else if (sortOrder && cohorts.length) {
		sortOption(cohorts);
	}
	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search
					searchFunction={searchCohort}
					searchText={searchText}
					addCohort={addCohort}
				/>
			</ErrorBoundary>

			{/* <GridHeader /> */}
			<GridHeader columnName={columnName} sortOrder={sortOrder} sortASC={sortASC} />

			<div className="bg-dark-first" style={{ minHeight: '70vh', position: 'relative' }}>
				{filteredCohorts.length > 0 &&
					filteredCohorts.map((cohort, index) => (
						<div key={index} className="v-title-wrapper">
							<div className="v-col-link">
								{editMode && (
									<div className="minusIcon-container">
										<img
											src={removeMinusIcon}
											onClick={() => removeCohort(cohort.cohort_id)}
											className="App-minusIcon"
											alt="minusIcon"
										/>
									</div>
								)}
								<span
									id="cohort-name-hyperlink"
									className="hyperlink"
									onClick={() => gotoCohortDesign(cohort.cohort_id)}
								>
									{cohort.cohort_name}
								</span>
								{cohort.hasManAnnotation && (
									<ErrorBoundary>
										<ManualTooltipScroll
											annotationType="cohort"
											goToDataDetail={gotoDataDetail}
											cohortId={cohort.cohort_id}
										/>
									</ErrorBoundary>
								)}
							</div>

							<div className="v-col-count">
								<ErrorBoundary>
									<CountLinkLoader
										count={cohort?.counts?.study_count}
										handleClick={() =>
											gotoPage('study', cohort.cohort_id, cohort.cohort_name)
										}
									/>
								</ErrorBoundary>
							</div>

							<div className="v-col-count">
								{userRole && userRole.Role == roleAccess.ANNOTATOR ? (
									<ErrorBoundary>
										<CountLinkLoader
											count={cohort?.counts?.patient_count}
											handleClick={() =>
												gotoPage(
													'data',
													cohort.cohort_id,
													cohort.cohort_name
												)
											}
										/>
									</ErrorBoundary>
								) : (
									<ErrorBoundary>
										<CountLinkLoader
											count={cohort?.counts?.patient_count}
											handleClick={() =>
												gotoPage(
													'patient',
													cohort.cohort_id,
													cohort.cohort_name
												)
											}
										/>
									</ErrorBoundary>
								)}
							</div>

							<div className="v-col-count">
								<ErrorBoundary>
									<CountLinkLoader
										count={cohort?.counts?.data_count}
										handleClick={() =>
											gotoPage('data', cohort.cohort_id, cohort.cohort_name)
										}
									/>
								</ErrorBoundary>
							</div>
						</div>
					))}
				{editMode && (
					<div className="v-title-wrapper">
						<div className="add-row" style={{ paddingLeft: '16px' }}>
							<img
								src={addPlusIcon}
								onClick={addCohortRedirect}
								className="App-plusicon hyperlink"
								alt="plusicon"
							/>
						</div>
					</div>
				)}
				{showLoading && <div className="v-loading-spinner" />}
			</div>
			{/* Added Cancel button to exit edit mode of cohortlist*/}
			{editMode && (
				<div className="text-right trialButton addNewTrial exitEditPage">
					<button type="button" className="trialArm" onClick={() => exitEditMode()}>
						Close
					</button>
				</div>
			)}

			{confirmDelete && (
				<ErrorBoundary>
					<ModalComponent
						open={true}
						content={
							<div className="jconfirm-box-container">
								<div className="jconfirm-content-pane">
									<h4>Are you sure you want to delete?</h4>
								</div>
								<div className="jconfirm-content-pane">
									<div className="jconfirm-buttons">
										<button className="btn btn-default" onClick={handleClose}>
											Cancel
										</button>
										<button
											className="btn btn-default"
											onClick={() => deleteCohort()}
										>
											Ok
										</button>
									</div>
								</div>
							</div>
						}
						className="testClass"
						handleClose={handleClose}
					/>
				</ErrorBoundary>
			)}
			{duplicateCohortError && (
				<ErrorBoundary>
					<ModalComponent
						open={true}
						className="modal-dialog"
						content={
							<div className="jconfirm-box-container">
								<div className="jconfirm-content-pane">
									{duplicateCohortError && <span>{duplicateCohortError}</span>}
								</div>
								<div className="jconfirm-content-pane">
									<div className="jconfirm-buttons">
										<button className="btn btn-default" onClick={handleClose}>
											Ok
										</button>
									</div>
								</div>
							</div>
						}
						className="testClass"
						handleClose={handleClose}
					/>
				</ErrorBoundary>
			)}
			<Copyright />
		</div>
	);
};

CohortList.propTypes = {
	dispatch: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	redCohort: PropTypes.shape({
		cohortList: PropTypes.any,
	}),
	redSidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(CohortList);

const GridHeader = ({ columnName, sortOrder, sortASC }) => {
	return (
		<div className="v-grid-header">
			<div className="v-col-link" onClick={() => sortASC('cohort_name')}>
				<span>Cohort name</span>
				<SortComponent
					id={'cohort_name'}
					columnName={'cohort_name'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>

			<div className="v-col-count" onClick={() => sortASC('study_count')}>
				<span>Studies</span>
				<SortComponent
					columnName={'study_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>

			<div className="v-col-count" onClick={() => sortASC('patient_count')}>
				<span>Patients</span>
				<SortComponent
					columnName={'patient_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>

			<div className="v-col-count" onClick={() => sortASC('data_count')}>
				<span>Data</span>
				<SortComponent
					columnName={'data_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>

			{/* <div className="v-grid-header">
	 	<div className="v-col-link">Cohort name</div>

	 	<div className="v-col-count">Studies</div>

	 	<div className="v-col-count">Patients</div>

	 	<div className="v-col-count">Data</div>
	</div> */}
		</div>
	);
};
