/* istanbul ignore file */
import { apiGet, apiPostForm } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';
import queryString from 'query-string';

/****************************************
 * Function Name: getUserDetails
 * Description: exports an action to get the user
 * data from the backend
 * The data received gets set in the userList reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getUserDetails(userIdList) {
	return new Promise(function (resolve, reject) {
		let params = {};
		if (userIdList) {
			params.userIdList = userIdList;
		}

		apiGet('FetchUserDetails', params)
			.then((res) => {
				resolve(res);
				store.dispatch({
					type: actionTypes.GET_USER_DETAILS,
					payload: res.data.data,
				});
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
}
/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getSubdocumentById(subddoc_id) {
	return new Promise(function (resolve, reject) {
		let storedData = store.getState();
		let storedSubdocument =
			storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
		if (storedSubdocument) {
			resolve(storedSubdocument);
		} else {
			apiGet('GetSubdocumentById', {
				m_documentId: subddoc_id,
			})
				.then((res) => {
					if (res?.data?.data) {
						store.dispatch({
							type: actionTypes.GET_SUBDOCUMENT_DETAIL,
							payload: { [`subdocument_${subddoc_id}`]: res },
						});
					}
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		}
	});
}
/****************************************
 * Function Name: getPatientList
 * Description: exports an action to get the patients list
 * data from the backend
 * The data received gets set in the patients reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getPatientList(
	patient_ids,
	index,
	record,
	cohort_id,
	concept_id,
	isflagForPatientWithConcept,
	searchText,
	study_id,
	searchByPatient
) {
	return new Promise(function (resolve, reject) {
		let forKappaScore = queryString.parse(window.location.search).forKappaScore;
		// if(forKappaScore){
		// 	query.forKappaScore =true;
		// }
		let kappa = forKappaScore ? '&forKappaScore=true' : '';
		let cohort = cohort_id ? '&m_cohortId=' + cohort_id : '';
		let study = study_id ? '&m_studyId=' + study_id : '';
		let concept = concept_id ? '&m_conceptId=' + concept_id : '';
		let stext = searchText ? '&m_searchString=' + searchText : '';
		let m_searchByPatient =
			searchByPatient != undefined && searchByPatient.toString()
				? '&m_searchByPatient=' + searchByPatient
				: '';
		let flagForPatientWithConcept =
			isflagForPatientWithConcept != undefined
				? '&flagForPatientWithConcept=' + isflagForPatientWithConcept
				: '';
		let url =
			index != null && index != undefined
				? 'GetPatient?pageNumber=' +
				  index +
				  '&pageSize=' +
				  record +
				  cohort +
				  study +
				  concept +
				  flagForPatientWithConcept +
				  stext +
				  kappa +
				  m_searchByPatient
				: 'GetPatient?m_start';
		apiGet(url, patient_ids ? { m_PatientIdList: patient_ids } : {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_PATIENT_DATA,
					payload: res.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export function getEncounterLevelManualAnnotation(subdocumentId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetEncounterLevelManualAnnotations', {
			subdocumentId: subdocumentId,
		})
			.then((res) => {
				resolve(res);
				// resolve({
				// 	data: {
				// 		severity: null,
				// 		score: 8,
				// 		conceptName: 'Migraine',
				// 		codeType: 'SNOMED',
				// 		conceptId: 'C0149931',
				// 		conceptCode: '37796009',
				// 		progression: null,
				// 		annotationDetails: [
				// 			{
				// 				annotation_id: 6425220,
				// 				user_id: 64,
				// 				subdocument_id: 1061754,
				// 				section_number: 3,
				// 			},
				// 			{
				// 				annotation_id: 6425226,
				// 				user_id: 64,
				// 				subdocument_id: 1061754,
				// 				section_number: 3,
				// 			},
				// 			{
				// 				annotation_id: 6425234,
				// 				user_id: 64,
				// 				subdocument_id: 1061754,
				// 				section_number: 19,
				// 			},
				// 			{
				// 				annotation_id: 6425234,
				// 				user_id: 64,
				// 				subdocument_id: 1061754,
				// 				section_number: 19,
				// 			}
				// 		],
				// 		descriptor: null,
				// 	},
				// 	status: 200,
				// 	statusText: '',
				// 	headers: {
				// 		connection: 'keep-alive',
				// 		'content-length': '429',
				// 		'content-type': 'application/json;charset=UTF-8',
				// 		date: 'Fri, 21 May 2021 13:39:54 GMT',
				// 		'keep-alive': 'timeout=20',
				// 		vary: 'Origin',
				// 	},
				// 	config: {
				// 		transformRequest: {},
				// 		transformResponse: {},
				// 		timeout: 0,
				// 		xsrfCookieName: 'XSRF-TOKEN',
				// 		xsrfHeaderName: 'X-XSRF-TOKEN',
				// 		maxContentLength: -1,
				// 		headers: {
				// 			Accept: 'application/json, text/plain, */*',
				// 		},
				// 		method: 'get',
				// 		params: {
				// 			subdocumentId: '1061754',
				// 			userRole: 'Administrator',
				// 		},
				// 		withCredentials: true,
				// 		url: 'http://localhost:8080/AnalyticsProjectServer/GetEncounterLevelManualAnnotations',
				// 	},
				// 	request: {},
				// });
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getPatientManualAnnotationsData(m_PatientId, m_annotationLevel) {
	return new Promise(function (resolve, reject) {
		let data = {
			m_PatientId: m_PatientId,
			m_DocLevelManAnnotation: false,
			pageNumber: 1,
			pageSize: 10,
			m_responseType: 'ConceptWise',
		};
		if (m_annotationLevel) {
			data.m_annotationLevel = m_annotationLevel;
		}

		apiGet('GetPatientManualAnnotations', data)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_PATIENT_MANUAL_ANNOTATION,
					payload: res.data,
				});

				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getDerivedManualAnnotationsData(derivedAnnotationId, patientId, annotationSource) {
	return new Promise(function (resolve, reject) {
		let temp = {
			DerivedAnnotationId: derivedAnnotationId,
			PatientId: patientId,
			AnnotationLevel: 'Patient',
			AnnotationSource: annotationSource,
			ConceptId: '',
		};
		// resolve({
		// 	data: {
		// 		data: [
		// 			{
		// 				DerivedAnnotationId: 1,
		// 				PatientId: 1,
		// 				AnnotationLevel: 'Patient',
		// 				AnnotationSource: 'Outcome',
		// 				ConceptId: 'C00001',
		// 				Descriptor: '',
		// 				NumericResult: '',
		// 				Progression: 'Worsened',
		// 				Severity: '',
		// 				ManualAnnotationConceptAttributeId: '1'
		// 			},
		// 			{
		// 				DerivedAnnotationId: 2,
		// 				PatientId: 1,
		// 				AnnotationLevel: 'Patient',
		// 				AnnotationSource: 'Text',
		// 				ConceptId: 'C00001',
		// 				Descriptor: '',
		// 				NumericResult: '',
		// 				Progression: '',
		// 				Severity: 'Mild',
		// 				ManualAnnotationConceptAttributeId: 2
		// 			}
		// 		]
		// 	}
		// });
		apiPostForm('GetDerivedManualAnnotations', temp)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function openModal(modalType) {
	store.dispatch({
		type: actionTypes.OPEN_MODAL_POPUP,
		payload: modalType,
	});
}

export function DeletePatientManualAnnotations(m_PatientId, conceptIds) {
	return new Promise(function (resolve, reject) {
		let data = {
			m_PatientId: m_PatientId,
			m_ConceptIds: conceptIds,
		};
		apiGet('DeletePatientManualAnnotations', data)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function AddPatientManualAnnotation(m_PatientId, conceptIds) {
	return new Promise(function (resolve, reject) {
		let data = {
			m_PatientId: m_PatientId,
			m_ConceptIds: conceptIds,
		};
		apiGet('AddPatientManualAnnotation', data)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function addDerivedAnnotation(outcomeConcepts) {
	return new Promise(function (resolve, reject) {
		// resolve({
		// 	data: {
		// 		data: [
		// 			{
		// 				ConceptName: 'Asthma',
		// 				ConceptId: 'C00001',
		// 				OutcomeId: 1,
		// 				StudyId: '1'
		// 			}
		// 		]
		// 	}
		// });
		apiPostForm('AddDerivedAnnotation', outcomeConcepts)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

// Outcome API
export function getDescriptorValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetDescriptorValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_DESCRIPTOR_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function getSeverityValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetSeverityValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_SEVERITY_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function getProgressionValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetProgressionValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_PROGRESSION_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getManualAnnotationProjectAttributes() {
	return new Promise(function (resolve, reject) {
		apiGet('GetManualAnnotationProjectAttributes', {})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getStudyOutcomes(studyId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyOutcomes', { m_studyId: studyId })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
