import React, { useState, useEffect, useRef } from 'react';
import { NavbarBrand } from 'react-bootstrap';
import constants from '../../constants';
import SideNav from '../SideNav/SideNav';
import Logout from '../../containers/Logout/Logout';
import { connect } from 'react-redux';
import actions from '../../actions';
import DataManagement from '../../containers/DataManagement/DataManagement';
import UserManagement from '../../containers/UserManagement/UserManagement';
import StudyAdministration from '../../containers/StudyAdministration/StudyAdministration';
import AccountManagement from '../../containers/AccountManagement/AccountManagement';
import CancelRapidManual from '../../components/CancelRapidManual/CancelRapidManual';
import history from '../CreateBrowserHistory/CreateBrowserHistory';
import MessagePopup from '../../components/MessagePopup/MessagePopup';
import RoleBasedNavLink from '../../components/RoleBasedNavLink/RoleBasedNavLink';
import modalConstants from '../Header/modalConstants';
import verantosIcon from '../../images/Verantos_icon.svg';
import { getObjectFromLocal, removeItemFromLocal } from '../../utilities/utils';

const Menu = (props) => {
	const [visible, setVisible] = useState(true);
	return (
		<div className="menu">
			<div className={(visible ? 'visible ' : '') + props.alignment}>{props.children}</div>
		</div>
	);
};

const MenuItem = ({ children }) => <div className="menu-item">{children}</div>;

const Sidebar = (props) => {
	const [datapageUrl, setDatapageUrl] = useState('');
	const [navPath, setNavPath] = useState('');
	let { userRole } = props.redSidenav;

	const logoutModal = useRef(null);
	const userManagementModal = useRef(null);
	const accountManagementModal = useRef(null);
	const dataManagementModal = useRef(null);
	const confirmPopup = useRef(null);
	const messagePopup = useRef(null);
	const studyAdministrationModel = useRef(null);

	useEffect(() => {
		try {
			if (!props.redSidenav.userRole.Role) {
				props.dispatch(actions.GetUserRole());
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		// It takes prop modal from store and opens desired modal window.
		switch (props?.redModal?.type) {
			case modalConstants.logout:
				logoutModal.current.handleClick();
				break;
			case modalConstants.productSetting:
				userManagementModal.current.handleClick();
				break;
			case modalConstants.accountSetting:
				accountManagementModal.current.handleClick();
				break;
			case modalConstants.dataSetting:
				dataManagementModal.current.handleClick();
				break;
			case modalConstants.studySetting:
				studyAdministrationModel.current.handleClick();
				break;
			default:
				break;
		}
	}, [props?.redModal]);

	const cancelUpdate = () => {
		removeItemFromLocal(`newRapidAnnotation_${props?.match?.params?.docId}`);
		removeItemFromLocal(`selectedConcept_${props?.match?.params?.docId}`);
		removeItemFromLocal(`editmodeNormal_${props?.match?.params?.docId}`);
		removeItemFromLocal(`updatedAnnotation_${props?.match?.params?.docId}`);
		removeItemFromLocal(`deletedAnnotation_${props?.match?.params?.docId}`);
		removeItemFromLocal(`fromEditMultipleManual_${props?.match?.params?.docId}`);
		props.dispatch(actions.setFilterText(''));
		props.dispatch(actions.setSideNavList([]));
		props.dispatch(actions.removeHistory([]));
		history.push(navPath);
	};

	/*****************************************
	 * Function name: changeNavHistory
	 * Description: Sets filtered text and side navigation options.
	 ****************************************/
	const changeNavHistory = (event, url) => {
		sessionStorage.setItem('storedHistory', JSON.stringify([]));
		let temp = getObjectFromLocal(`newRapidAnnotation_${props?.match?.params?.docId}`);
		let newRapidAnnotationTemp = temp ? temp : '';
		let tempUpdated = getObjectFromLocal(`updatedAnnotation_${props?.match?.params?.docId}`);
		let updatedAnnotation = tempUpdated ? tempUpdated : '';
		let tempDeleted = getObjectFromLocal(`deletedAnnotation_${props?.match?.params?.docId}`);
		let deletedAnnotation = tempDeleted ? tempDeleted : '';
		setNavPath(url);

		if (newRapidAnnotationTemp || updatedAnnotation || deletedAnnotation) {
			setDatapageUrl(window.location.pathname);
			confirmPopup.current.handleClick();
			return;
		} else if (sessionStorage.getItem('PreviewText')) {
			setDatapageUrl(url);
			messagePopup.current.handleClick();
			event.isPropagationStopped();
			return;
		} else {
			removeItemFromLocal(`newRapidAnnotation_${props?.match?.params?.docId}`);
			removeItemFromLocal(`selectedConcept_${props?.match?.params?.docId}`);
			removeItemFromLocal(`editmodeNormal_${props?.match?.params?.docId}`);
			removeItemFromLocal(`viewmodeDocument_${props?.match?.params?.docId}`);
			removeItemFromLocal(`fromDataManual_${props?.match?.params?.docId}`);
			props.dispatch(actions.setFilterText(''));
			props.dispatch(actions.setSideNavList([]));
			props.dispatch(actions.removeHistory([]));
		}
		history.push(url);
	};
	useEffect(() => {
		if (history.listen) {
			history.listen((location) => {
				if (
					!(
						location.pathname.includes('document') ||
						location.pathname.includes('datamanual') ||
						location.pathname.includes('editdatamanual') ||
						location.pathname.includes('dataautomated')
					)
				) {
					for (var attr in localStorage) {
						if (attr.includes('highlightAnnotation')) {
							localStorage.removeItem(attr);
						}
					}
				}
			});
		}
	}, [history]);

	const rootClosexit = (event) => {
		history.push(datapageUrl);
	};

	const redirectToPage = () => {
		sessionStorage.setItem('PreviewText', '');
		sessionStorage.removeItem('patientpreviewdata');
		history.push(datapageUrl);
	};

	const resetState = (oldUrl) => {
		if (props.resetState) {
			props.resetState(oldUrl);
		}
	};

	const onStudyAdminPopupClose = () => {
		window.location.reload();
	};
	return (
		<div>
			<Menu alignment="left">
				<NavbarBrand>
					<div className="top_nav">
						<ul>
							<li key={'homelink'} style={{ height: 50 }} className="event">
								<div className="ver-logo-wrapper">
									{/* <img className="logo-icon ver-logo" src={logoVerantos} /> */}
								</div>
							</li>

							{userRole && userRole.Role && (
								<RoleBasedNavLink
									userRole={userRole.Role}
									onClick={(event, path) => changeNavHistory(event, path)}
								/>
							)}
						</ul>
					</div>
				</NavbarBrand>
				<div id="navigation-menu">
					<SideNav resetState={resetState} />
				</div>
			</Menu>
			<Logout open={(c) => (logoutModal.current = c)} />
			<DataManagement open={(c) => (dataManagementModal.current = c)} />
			<StudyAdministration
					open={(c) => (studyAdministrationModel.current = c)}
					onClose={onStudyAdminPopupClose}
				/>
			<AccountManagement open={(c) => (accountManagementModal.current = c)} />
			<UserManagement open={(c) => (userManagementModal.current = c)} />
			<CancelRapidManual
				open={(c) => (confirmPopup.current = c)}
				rootClosexit={(event) => rootClosexit(event)}
				cancelRapidUpdate={cancelUpdate}
				revertRapidChanges={cancelUpdate}
			/>
			<MessagePopup
				open={(c) => (messagePopup.current = c)}
				rootClosexit={() => {}}
				text={
					'If you exit preview, your changes will be lost. Are you sure you want to exit preview?'
				}
				confirmClick={redirectToPage}
			/>
		</div>
	);
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Sidebar);
