/* istanbul ignore file */
import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';
import store from '../store';

/****************************************
 * Function Name: setIsLoading
 * Description: dispatches an action to set the Loading on applied page
 * as true or false. It is used as a common action for the differemt pages.
 * Method has one signature.
 * @param - loading - The value of the loading that needs to be set. Can be
 * either true or false depending on whether to show the loader or not.
 ****************************************/

export function setIsLoading(loading) {
	store.dispatch({
		type: actionTypes.IS_LOADING,
		payload: loading,
	});
}

/****************************************
 * Function Name: getCohortCounts
 * Description: return total count of cohort for given params
 * Method has one signature.
 * @param - doc_id - The document ID based on which the API call has to be made.
 ****************************************/

export function getCohortCounts(query) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetCohortCounts', query)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}
/****************************************
 * Function Name: getPatientCounts
 * Description: return total count of cohort for given params
 * Method has one signature.
 * @param - doc_id - The document ID based on which the API call has to be made.
 ****************************************/

export function getPatientCounts(page, ids) {
	let data = {};
	switch (page) {
		case 'study':
			data = { studyIds: ids };
			break;
		case 'cohort':
			data = { cohortIds: ids };
			break;
		default:
		// code block
	}
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetPatientCounts', data)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getDataCounts
 * Description: return total count of cohort for given params
 * Method has one signature.
 * @param - doc_id - The document ID based on which the API call has to be made.
 ****************************************/

export function getDataCounts(page, ids, orgId) {
	let data = {};
	switch (page) {
		case 'study':
			data = { studyIds: ids };
			break;
		case 'cohort':
			data = { cohortIds: ids };
			break;
		case 'org':
			data = { patientIds: ids, organizationId: orgId };
			break;
		case 'patient':
			data = { patientIds: ids };
			break;
		default:
		// code block
	}
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetDataCounts', data)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}
/****************************************
 * Function Name: getStudyCounts
 * Description: return total count of cohort for given params
 * Method has one signature.
 * @param - doc_id - The document ID based on which the API call has to be made.
 ****************************************/

export function getStudyCounts(query) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetStudyCounts', query)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}
/****************************************
 * Function Name: getConceptCategories
 * Description: return categories of the concepts
 * Method has one signature.
 *
 ****************************************/

export function getConceptCategories() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetConceptCategories')
				.then((res) => {
					dispatch({
						type: actionTypes.GET_CONCEPT_CATEGORIES,
						payload: res.data.data || [],
					});
					resolve(res.data.data || []);
				})
				.catch((err) => {
					reject(err);
				});
		});
}
