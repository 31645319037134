/* istanbul ignore file */
import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export function getSubdocumentById(subddoc_id) {
	return new Promise(function (resolve, reject) {
		let storedData = store.getState();
		let storedSubdocument =
			storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
		if (storedSubdocument) {
			resolve(storedSubdocument);
		} else {
			apiGet('GetSubdocumentById', {
				m_documentId: subddoc_id,
			})
				.then((res) => {
					if (res?.data?.data) {
						store.dispatch({
							type: actionTypes.GET_SUBDOCUMENT_DETAIL,
							payload: { [`subdocument_${subddoc_id}`]: res },
						});
					}
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		}
	});
}

export function getConceptCategories() {
	return new Promise(function (resolve, reject) {
		apiGet('GetConceptCategories')
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_CONCEPT_CATEGORIES,
					payload: res.data.data || [],
				});
				resolve(res.data.data || []);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getHistoryData(doc_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetAnnotatedTextFromID', {
			dataRecordID: parseInt(doc_id),
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_ANNOTATED_TEXT,
					payload: res.data || [],
				});
				resolve(res.data || []);
			})
			.catch((err) => {
				console.log(err);
			});
	});
}
