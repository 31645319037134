/* istanbul ignore file */
import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

export function getUserBatches(query) {
	return new Promise((resolve, reject) => {
		apiGet('GetUserBatches', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getUserProject(query) {
	return new Promise((resolve, reject) => {
		apiGet('GetProjects', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function openModal(modalType) {
	store.dispatch({
		type: actionTypes.OPEN_MODAL_POPUP,
		payload: modalType,
	});
}
export function getUserDetails(userIdList) {
	return new Promise(function (resolve, reject) {
		let params = {};
		if (userIdList) {
			params.userIdList = userIdList;
		}

		apiGet('FetchUserDetails', params)
			.then((res) => {
				resolve(res);
				store.dispatch({
					type: actionTypes.GET_USER_DETAILS,
					payload: res.data.data,
				});
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
}
export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}
