import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from '../../../constants';
import ReactHtmlParser from 'react-html-parser';
import { getSectionTextPreview } from '../DocumentHelper/documentHelper';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { withRouter } from 'react-router-dom';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { setIsLoading } from '../../../actions/common';
import { getPreviewGATEResponse } from './DataPreviewHelper';
import queryString from 'query-string';

const AnnotationPopover = lazy(() =>
	import(
		/* webpackChunkName: "AnnotationPopover" */ '../../../common/AnnotationPopover/AnnotationPopover'
	)
);
const DummyHtmlLayout = lazy(() =>
	import(
		/* webpackChunkName: "DummyHtmlLayout" */ '../../../components/DummyHtmlLayout/DummyHtmlLayout'
	)
);
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data detail',
	list: 'preview',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataPreview = (props) => {
	const [parsedText, setParsedText] = useState('');
	const [sectionedJson, setSectionedJson] = useState([]);
	let { automated_annotation_id } = queryString.parse(window.location.search);
	let { loading } = props.loading;

	useEffect(() => {
		setIsLoading(true);
		let nlpData = sessionStorage.getItem('nlpsectiondata');
		if (nlpData) {
			setIsLoading(false);
			generateSectionedData(JSON.parse(nlpData));
		} else {
			getPreviewGATEResponse({
				documentBody: sessionStorage.getItem('PreviewText'),
			})
				.then((resp) => {
					let sectionedJson = resp.data.payload.section_data;
					sessionStorage.setItem(
						'conceptsupport_0',
						JSON.stringify(resp.data.payload.concept_support)
					);
					sessionStorage.setItem(
						'nlpsectiondata',
						JSON.stringify(resp.data.payload.section_data)
					);
					setIsLoading(false);
					generateSectionedData(sectionedJson);
				})
				.catch((err) => {
					setIsLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		getSectionTextPreview(sectionedJson).then((sectionedText) => {
			let updatedText = sectionedText.map((section, index) => {
				
				return (
					<div key={index}>
						<div className="section-header">
							{section.canonical_section_name.split('_').map((txt, index) => {
								if (index == 0) {
									if (txt == 'UNKNOWN') {
										return <span className="text-capitalize" />;
									}
									return (
										<span className="text-capitalize">
											{`${txt.toLowerCase()} `}
										</span>
									);
								}
								return <span className="text-lowercase">{`${txt} `}</span>;
							})}
						</div>
						{
							<div className={'section-text'}>
								{ReactHtmlParser(
									section.sectionText
										.replace(/\r\n/g, '<br/>')
										.replace(/\r/g, '<br/>')
										.replace(/\n/g, '<br/>')
										.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'),
									{ transform: htmlParserTransform }
								)}
							</div>
						}
					</div>
				);
			});
			setParsedText(updatedText);
		});
	}, [sectionedJson]);

	const generateSectionedData = (sectionedJson) => {
		setSectionedJson(sectionedJson);
	};

	const gotoConceptSupport = (conceptId) => {
		props.history.push(
			`${constants.ROUTE_URL}/dashboard/conceptsupport/0` + `?conceptId=${conceptId}`
		);
	};

	const htmlParserTransform = (node, index) => {
		if (node.type == 'tag' && node.name == 'a') {
			let annotationData = [];
			sectionedJson.map((items) => {
				if (items.section_id == node.attribs.sid) {
					annotationData = items.entities.filter((annotation) => {
						annotation.encounterDate = items.encounterDate;
						return annotation.BeginOffset == node.attribs.bost;
					});
				}
			});
			let annotations = annotationData;
			let isBrPresent = false;
			node.children.map((items) => {
				if (items.name == 'br') {
					isBrPresent = true;
				}
			});

			return (
				<React.Fragment>
					<OverlayTrigger
						placement={'auto'}
						trigger="click"
						rootClose={true}
						onEntering={(event) => onEnteringAutomatedAnnotationText(event, node)}
						onExit={() => {
							deselectAutomatedText();
						}}
						onEntered={() => {
							onEnteredAutomatedAnnotation(node);
						}}
						overlay={
							<Popover
								id="popover-positioned-annotation document-annotation-popup"
								className="preview"
								placement="auto"
								bsPrefix={isBrPresent ? 'havebreak popover' : 'popover'}
							>
								<ErrorBoundary>
									<Suspense fallback={<div className="defaultSpinner" />}>
										<AnnotationPopover
											annotations={annotations}
											gotoConceptSupport={gotoConceptSupport}
										/>
									</Suspense>
								</ErrorBoundary>
							</Popover>
						}
					>
						<a
							href="javascript:0"
							className="hyperlink"
							id={
								`showautomatedannoataion_${ 
								node.attribs['sid'] 
								}_${ 
								node.attribs['bost'] 
								}_${ 
								node.attribs['eost']}`
							}
							data-annotationid={annotations[0]?.annotation_id}
						>
							{node.children.map((val) => {
								if (val.type == 'tag' && val.name == 'br') {
									return <br />;
								}
								return (
									<span {...val.attribs}>
										{val.children[0] ? val.children[0].data : val.data}
									</span>
								);
								// }
							})}
						</a>
					</OverlayTrigger>
				</React.Fragment>
			);
		}
		if (node.type == 'tag' && node.name == 'span') {
			let manualAnnotation =
				props.manualAnnotationList &&
				props.manualAnnotationList.filter((item) => {
					return item.annotation_id == node.attribs['data-annotationid'];
				})[0];
			return (
				<span {...node.attribs}>
					{node.children[0] ? (
						node.children.map((val) => {
							if (val.type == 'tag' && val.name == 'br') {
								return <br />;
							}
							return (
								<span {...val.attribs}>
									{val?.children && val.children[0] ? val.children[0].data : val.data}
								</span>
							);
							// }
						})
					) : (node.data)}
				</span>
			);
		}
	};

	useEffect(() => {
		if (automated_annotation_id) {
			let selectedElement = document.querySelector(
				`#showautomatedannoataion_${automated_annotation_id}`
			);
			if (selectedElement) {
				selectedElement.click();
				window.scrollTo({
					top: selectedElement.offsetTop - 200,
					behavior: 'smooth',
				});
				const cleanUri = location.protocol + '//' + location.host + location.pathname;
				window.history.replaceState({}, document.title, cleanUri);
			}
		}
	}, [htmlParserTransform]);

	const onEnteringAutomatedAnnotationText = (event, node) => {
		let isBrPresent = false;
		node.children.map((items) => {
			if (items.name == 'br') {
				isBrPresent = true;
			}
		});
		if (isBrPresent) {
			let elementId =
				`showautomatedannoataion_${ 
				node.attribs['sid'] 
				}_${ 
				node.attribs['bost'] 
				}_${ 
				node.attribs['eost']}`;

			let eleWidth = $(`#${elementId}`).width() / 2 - 2;
			let spanPosition = $(`#${elementId} span:first-child`);
			$('#popover-positioned-annotation').css({
				left: eleWidth - 5,
				transform: 'unset !important',
			});
		}
		let startPoint = parseInt(node.attribs['bost']);
		let endPoint = parseInt(node.attribs['eost']);
		let sectionId = node.attribs['sid'];

		for (var i = startPoint; i <= endPoint; i++) {
			$(`span[ost=${i}][sid=${sectionId}]`)
				.parent()
				.addClass('highlighted');
		}
	};

	const onEnteredAutomatedAnnotation = (node) => {
		let startPoint = parseInt(node.attribs['bost']);
		let endPoint = parseInt(node.attribs['eost']);
		let sectionId = node.attribs['sid'];

		for (var i = startPoint; i <= endPoint; i++) {
			$(`span[ost=${i}][sid=${sectionId}]`)
				.parent()
				.addClass('highlighted');
		}
	};

	const deselectAutomatedText = () => {
		$('a').removeClass('highlighted');
	};

	const endPreviewClick = () => {
		sessionStorage.removeItem('patientpreviewdata');
		let goBackUrl = sessionStorage.getItem('EndPreviewUrl');
		if (goBackUrl) {
			props.history.push(constants.ROUTE_URL + goBackUrl);
		}
	};

	const patientPreviewClick = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/0`);
	};

	return (
		<React.Fragment>
			<div className="preview-annotation">
				{loading && (
					<Loading isLoading={true} loadingClassName="loading">
						<span />
					</Loading>
				)}
				<HeaderTitles data={headerData} search={false} noMenu={true} />
				<Suspense fallback={<div className="defaultSpinner" />}>
					<DummyHtmlLayout>{parsedText}</DummyHtmlLayout>
				</Suspense>
			</div>
			<div className="trialButton" style={{ textAlign: 'right', marginTop: '30px' }}>
				<button
					type="button"
					id="testid-btn-patientpreview"
					className="trialArm"
					onClick={patientPreviewClick}
				>
					Patient preview
				</button>
				<button
					type="button"
					id="testid-btn-endpreview"
					className="trialArm"
					onClick={endPreviewClick}
				>
					End preview
				</button>
			</div>
		</React.Fragment>
	);
};

DataPreview.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	manualAnnotationList: PropTypes.shape({
		filter: PropTypes.func,
	}),
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(DataPreview));
