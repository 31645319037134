/* istanbul ignore file */
import { apiPost, apiPostForm, apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import { setObjectToLocal } from '../../../utilities/utils';
import store from '../../../store';

export const login = (user) => {
	new Promise((resolve, reject) => {
		apiPostForm('Login', { email: user.email, password: user.password })
			.then((res) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_SUCCESS,
					payload: true,
				});
				setObjectToLocal('authSecret', { secret: res.data.authSecret }),
					setObjectToLocal('user', { email: user.email }),
					sessionStorage.setItem('userSession', 'Y');
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_ERROR,
					payload: 'Wrong email or password.',
				});
			});
	});
};

export const loginError = (err) => {
	store.dispatch({
		type: actionTypes.AUTH_LOGIN_ERROR,
		payload: err,
	});
};
export function getUserRole() {
	return new Promise((resolve, reject) => {
		apiGet('GetUserRole')
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_USER_ROLE,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getMFAEnabled() {
	return new Promise((resolve, reject) => {
		apiGet('MFAEnabled')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getIsVAStudy() {
	return new Promise((resolve, reject) => {
		apiGet('isVAStudy')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}