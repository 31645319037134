import PropTypes from 'prop-types';
import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import constants from '../../constants';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';

const TechnologyItemComponent = lazy(() =>
	import(
		/* webpackChunkName: "TechnologyItemComponent" */ '../../components/TechnologyItemComponent/TechnologyItemComponent'
	)
);

function TechnologyItem(props) {
	useEffect(() => {
		let { itemDetail } = props.technology;

		if (Object.keys(itemDetail).length == 0) {
			props.history.push(`${constants.ROUTE_URL}/dashboard/techdetail`);
		}
	}, []);

	const { lexicon_id } = props.match.params;
	let { itemDetail } = props.technology;
	return (
		<div>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<TechnologyItemComponent lexicon_id={lexicon_id} itemDetail={itemDetail} />
				</Suspense>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
}

TechnologyItem.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			lexicon_id: PropTypes.any,
		}),
	}),
	technology: PropTypes.shape({
		itemDetail: PropTypes.any,
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(TechnologyItem);
