import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';

const MessagePopup = forwardRef((props, ref) => {
	const [open, setOpen] = useState(props.forceOpen ? true : false);
	const [className, setClassName] = useState('testClass');

	useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});

	useEffect(() => {
		if (props.open) {
			props.open({
				handleClick: () => handleClick(),
			});
		}
		return () => {
			if(props.open){
				props.open(null);
			}
		};
	}, []);

	const cancelClick = (event) => {
		setClassName('testClass');
		setOpen(false);
		props.rootClosexit(event);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(true);
		setClassName('animated shake Data-Management');
		setTimeout(() => setClassName('Data-Management'), 1000);
	};

	const okClick = (event) => {
		setOpen(false);
		setClassName('Data-Management');
		props.confirmClick(event);
	};

	return (
		<div>
			<ErrorBoundary>
				<ModalComponent
					open={open}
					backdrop={true}
					content={
						<div className="jconfirm-box-container" style={{ padding: '20px' }}>
							<div className="jconfirm-content-pane">
								<div
									style={{ fontSize: 18 }}
									dangerouslySetInnerHTML={{ __html: props.text }}
								/>
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										type="button"
										className="btn btn-default"
										onClick={(event) => cancelClick(event)}
									>
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-default"
										onClick={(event) => okClick(event)}
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className={'testClass'}
					handleClose={handleClose}
				/>
			</ErrorBoundary>
		</div>
	);
});

MessagePopup.propTypes = {
	confirmClick: PropTypes.func,
	forceOpen: PropTypes.any,
	open: PropTypes.func,
	rootClosexit: PropTypes.func,
	text: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(MessagePopup));
