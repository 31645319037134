import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../../actions';
import Loading from 'react-loading-spinner';
import Copyright from '../../components/Copyright/Copyright';
import { setIsLoading } from '../../actions/common';
import localforage from 'localforage';

const EditStudyValidityDetail = lazy(() =>
	import(
		/* webpackChunkName: "EditStudyValidityDetail" */ '../../components/EditStudyValidityDetail/EditStudyValidityDetail'
	)
);

function EditStudyValidity(props) {
	let { studyDetail, studyArmList } = props.redStudy;

	let { loading } = props.loading;
	let _cohortList = [];
	let _conceptList = [];

	const [cohortLists, setCohortLists] = useState([]);
	const [conceptLists, setConceptLists] = useState([]);
	const [batchOptions, setBatchOptions] = useState([]);
	const [batchIds, setBatchIds] = useState([]);
	const [disableOkFlag,setDisableOkFlag]=useState(true);

	useEffect(() => {
		let { studyId } = props.match.params;
		setIsLoading(true);
		props.dispatch(actions.getStudyDetail(studyId)).then((studyDetail) => {
			getCohortCriteria(studyDetail.base_cohort);

			let validStatusOption = [
				'Manual annotation project created',
				'Reprocessing completed',
				'Reprocessing failed'
			];
			localforage.getItem('isVAStudy').then(enable =>{
				if(enable) {
					props.dispatch(actions.getStudyInformation(studyDetail.study_name)).then((resp) => {
						let trainingData = resp.training || [];
						let validationData = resp.validation || [];
						let batchOption = [...batchOptions];
						let batchId = [...batchIds]
						let trainingAnnotationGenerated =
							trainingData.filter((item) => {
								return item.active == 'Yes' && item.status && validStatusOption.indexOf(item.status) != -1;
							});
						let validationAnnotationGenerated =
							validationData.filter((item) => {
								return item.active == 'Yes' && item.status && validStatusOption.indexOf(item.status) != -1;
							});
						if (
							trainingData.length > 0 &&
							trainingAnnotationGenerated.length > 0
						) {
							batchOption.push({ value: 'Training', label: 'Training' });
							setDisableOkFlag(false);
						}
						if (
							validationData.length > 0 &&
							validationAnnotationGenerated.length > 0
						) {
							batchOption.push({ value: 'Validation', label: 'Validation' });
							setDisableOkFlag(false);
						}
						setBatchOptions(batchOption);
						if (trainingData.length > 0 && trainingAnnotationGenerated.length > 0) {
							trainingData.forEach((item) => {
								batchId.push({
									value: item?.batch_name.charAt(0).toUpperCase() + item?.batch_name.slice(1),
									label: item?.batch_name.charAt(0).toUpperCase() + item?.batch_name.slice(1),
								});
							});
							batchId.push({ value: 'Training', label: 'Training-batch-all' });
						}
						setBatchIds(batchId);
					});
				} else {
					props.dispatch(actions.getUserCohortAnnotationCountBatches()).then((resp) => {
						if (resp) {
							const batchOption = [];
							const batchId = [];
							Object.keys(resp).forEach(key =>{
								batchOption.push({
									label: key.toString().charAt(0).toUpperCase() + key.toString().slice(1),
									value: key.toString().charAt(0).toUpperCase() + key.toString().slice(1),
								 });
								if (key.toString().toLowerCase() === 'training' && resp[key].length > 0) {
									resp[key].forEach(item =>{
										// -- As per dicussion with kashif for analytics project, If batch_name is 'training-batch-all' then value will be 'Training'
										batchId.push({
											value: item?.batch_name?.toLowerCase() === 'training-batch-all' ? 'Training' : item?.batch_name,
											label: item?.batch_name?.toString().charAt(0).toUpperCase() + item?.batch_name?.toString().slice(1),
										});
									});
								}
							});
							setBatchIds(batchId);
							setBatchOptions(batchOption);
						}
					});
				}
			});
		});
		props.dispatch(actions.getCohortList());
		props.dispatch(actions.getStudyArmList(studyId)).then(() => {
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		props?.redCohort?.cohortList &&
			props?.redCohort?.cohortList.map((value, index) => {
				if (studyDetail.base_cohort == value.cohort_id) {
					_cohortList.push({ value: value.cohort_id, label: value.cohort_name });
				}
				studyArmList.map((arm, index) => {
					if (arm.cohort_id == value.cohort_id) {
						_cohortList.push({ value: value.cohort_id, label: value.cohort_name });
					}
				});
			});
		setCohortLists(_cohortList);

		props?.redCohort?.cohortCriterion &&
			props?.redCohort?.cohortCriterion.map((value, index) => {
				if (value) {
					_conceptList.push({
						value: value.concepts?.cuid,
						label: value.concepts?.text,
					});
				}
			});

		setConceptLists(_conceptList);
	}, [props.redCohort?.cohortList, props.redCohort?.cohortCriterion, studyArmList]);

	useEffect(() => {
		return () => {
			props.dispatch(actions.clearStudyCohorts());
		};
	}, []);

	const getCohortCriteria = (cohort_id) => {
		if (props?.redCohort?.cohortCriterion.length == 0) {
			setIsLoading(true);
			props
				.dispatch(actions.GetConceptForAnnotationByCohortId(cohort_id))
				.then((res) => {
					if (res && res.data.data.length) {
						GetSearchConcepts(res.data.data);
					} else {
						props.dispatch(actions.setCriterion_Concepts([]));
						setIsLoading(false);
					}
				})
				.catch((items) => {
					setIsLoading(false);
				});
		}
	};

	const GetSearchConcepts = (concepts) => {
		props.dispatch(actions.searchConceptsById(concepts)).then((criteriaConcepts) => {
			setIsLoading(false);
			let dataObj = concepts.map((criteria, index) => {
				if (criteria) {
					return {
						...criteria,
						concepts: criteriaConcepts.filter((items) => items.cuid == criteria)[0],
					};
				}
			});
			props.dispatch(actions.setCriterion_Concepts(dataObj));
		});
	};

	return (
		<div className="study-result-container">
			{loading && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<Suspense fallback={<div className="defaultSpinner" />}>
				<EditStudyValidityDetail
					studyDetail={studyDetail}
					cohortList={cohortLists}
					conceptList={conceptLists}
					batchIdList={batchIds}
					getCohortCriteria={getCohortCriteria}
					router={props.router}
					batchOptions={batchOptions}
					disableOk={disableOkFlag}
					{...props}
				/>
			</Suspense>
			<Copyright />
		</div>
	);
}

EditStudyValidity.propTypes = {
	dispatch: PropTypes.func,
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			studyId: PropTypes.any,
		}),
	}),
	redCohort: PropTypes.shape({
		cohortCriterion: PropTypes.arrayOf(
			PropTypes.shape({
				length: PropTypes.number,
				map: PropTypes.func,
			})
		),
		cohortList: PropTypes.arrayOf(
			PropTypes.shape({
				map: PropTypes.func,
			})
		),
	}),
	redStudy: PropTypes.shape({
		studyDetail: PropTypes.shape({
			base_cohort: PropTypes.any,
		}),
		studyArmList: PropTypes.arrayOf(PropTypes.shape({
			map: PropTypes.func,
		})),
	}),
	router: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(EditStudyValidity);
