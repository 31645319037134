//Defining the different action types.
/* istanbul ignore file */
export default {

	AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
	AUTH_LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
	VERIFY_AUTHENTICATION_CODE: 'VERIFY_AUTHENTICATION_CODE',
	VERIFY_AUTHENTICATION_ERROR: 'VERIFY_AUTHENTICATION_ERROR',
	// GET_STUDY_DATA: 'GET_STUDY_DATA',
	// SET_STUDY_DATA : 'SET_STUDY_DATA',
	GET_STUDY_ARM_LIST: 'GET_STUDY_ARM_LIST',
	GET_STUDY_DETAIL: 'GET_STUDY_DETAIL',
	GET_PATIENT_DATA: 'GET_PATIENT_DATA',
	GET_ORGANIZATIONS : 'GET_ORGANIZATIONS',
	GET_DOCUMENTS: 'GET_DOCUMENTS',
	SET_PATIENT_DETAIL_DATA: 'SET_PATIENT_DETAIL_DATA',
	SET_PATIENT_NAME: 'SET_PATIENT_NAME',
	STEP2_VERIFICATION_SUCCESS: 'STEP2_VERIFICATION_SUCCESS',
	SET_DIAGNOSES: 'SET_DIAGNOSES',
	SET_DOC_NAME : 'SET_DOC_NAME',
	SET_COHORT_DETAILS : 'SET_COHORT_DETAILS',
	GET_COHORT_LIST : 'GET_COHORT_LIST',
	// SET_DATERANGE_DELETE_MODE: 'SET_DATERANGE_DELETE_MODE',
	SET_CRITERION_CONCEPTS : 'SET_CRITERION_CONCEPTS',
	GET_LEXICON_COUNT : 'GET_LEXICON_COUNT',
	GET_TEMPORAL_ORDER : 'GET_TEMPORAL_ORDER',
	GET_GRAMMAR_COUNT : 'GET_GRAMMAR_COUNT',
	GET_NEGATIONS_COUNT : 'GET_NEGATIONS_COUNT',
	GET_ASSOCIATION_COUNT : 'GET_ASSOCIATION_COUNT',
	GET_NE_COUNT : 'GET_NE_COUNT',
	GET_CONCEPT_SUPPORT : 'GET_CONCEPT_SUPPORT',
	GET_LEXICON_CONCEPTS : 'GET_LEXICON_CONCEPTS',
	IS_LOADING: 'IS_LOADING',
	GET_ANNOTATED_TEXT: 'GET_ANNOTATED_TEXT',
	GET_ANNOTATION_LIST: 'GET_ANNOTATION_LIST',
	GET_EVENT: 'GET_EVENT',
	GET_ANNOTATION_DETAIL: 'GET_ANNOTATION_DETAIL',
	SET_CRITERIA_DATA: 'SET_CRITERIA_DATA',
	SET_SIDE_NAVLIST: 'SET_SIDE_NAVLIST',
	SET_TECH_ITEM_DETAIL: 'SET_TECH_ITEM_DETAIL',
	SET_COHORT_PATIENT: 'SET_COHORT_PATIENT',
	SET_COHORT_DATA: 'SET_COHORT_DATA',
	SET_FILTER_TEXT: 'SET_FILTER_TEXT',
	REMOVE_FILTER_TEXT: 'REMOVE_FILTER_TEXT',
	SET_CONCEPT_COHORTS: 'SET_CONCEPT_COHORTS',
	SET_PATIENT_COHORTS: 'SET_PATIENT_COHORTS',
	SET_DOCUMENT_COHORTS: 'SET_DOCUMENT_COHORTS',
	SET_FILE_FORMAT: 'SET_FILE_FORMAT',
	SET_UPLOAD_ORGANIZATION : 'SET_UPLOAD_ORGANIZATION',
	SET_UPLOAD_ORIGIN: 'SET_UPLOAD_ORIGIN',
	// SET_PATH_LIST: 'SET_PATH_LIST',
	END_DATA_UPLOAD: 'END_DATA_UPLOAD',
	START_DATA_UPLOAD : 'START_DATA_UPLOAD',
	GET_BATCH_ORGANIZATION : 'GET_BATCH_ORGANIZATION',
	SET_BATCH_LIST : 'SET_BATCH_LIST',
	GET_USER_DETAILS:'GET_USER_DETAILS',
	// SET_PROVIDER_DETAILS : "SET_PROVIDER_DETAILS",
	// GET_ACCESS_ORGANIZATION:'GET_ACCESS_ORGANIZATION',
	// SET_USER_LIST: 'SET_USER_LIST',
	UPDATE_PROFILE:'UPDATE_PROFILE',
	GET_USER_PERMISSION:'GET_USER_PERMISSION',
	GET_USER_IMAGE:'GET_USER_IMAGE',
	GET_USER_ORGANIZATION:'GET_USER_ORGANIZATION',
	SAVE_STUDY_COHORTS : 'SAVE_STUDY_COHORTS',
	CLEAR_STUDY_COHORTS : 'CLEAR_STUDY_COHORTS',
	GET_MANUAL_ANNOTATIONS:'GET_MANUAL_ANNOTATIONS',
	// GET_COHORT_MANUAL_ANNOTATIONS:'GET_COHORT_MANUAL_ANNOTATIONS',
	// GET_MANUAL_ANNOTATION_DETAIL:'GET_MANUAL_ANNOTATION_DETAIL',
	SET_MANUAL_ANNTATION_LIST:'SET_MANUAL_ANNTATION_LIST',
	// SET_COHORTS_MANUAL_ANNOTATIONS:'SET_COHORTS_MANUAL_ANNOTATIONS',
	GET_PATIENT_FOR_CONCEPT:'GET_PATIENT_FOR_CONCEPT',
	// GET_DETAIL_DOC_CONCEPT_TEMPORALITY:'GET_DETAIL_DOC_CONCEPT_TEMPORALITY',
	GET_PATIENT_MANUAL_ANNOTATION: 'GET_PATIENT_MANUAL_ANNOTATION',
	GET_STUDY_LIST_FOR_PATIENT: 'GET_STUDY_LIST_FOR_PATIENT',
	GET_STUDY_LIST_FOR_DOCUMENT: 'GET_STUDY_LIST_FOR_DOCUMENT',
	GET_RAPID_MANUAL_ANNOTATION: 'GET_RAPID_MANUAL_ANNOTATION',
	SET_RAPID_MANUAL_ANNOTATION: 'SET_RAPID_MANUAL_ANNOTATION',
	// SET_RAPID_MANUAL_DOCUMENT_CONCEPT_MAP: 'SET_RAPID_MANUAL_DOCUMENT_CONCEPT_MAP',
	EMPTY_PATIENT_DETAILS: 'EMPTY_PATIENT_DETAILS',
	GET_SYSTEM_USER_DETAILS:'GET_SYSTEM_USER_DETAILS',
	GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT:'GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT',
	// GET_COHORT_DOCUMENTS:'GET_COHORT_DOCUMENTS',
	// GET_SUMMARY_ANALYTICS:'GET_SUMMARY_ANALYTICS',
	GET_LEXICON_CONCEPTS_BY_ID:'GET_LEXICON_CONCEPTS_BY_ID',
	GET_STUDY_VALIDITY:'GET_STUDY_VALIDITY',
	GET_STUDY_CONCEPTS:'GET_STUDY_CONCEPTS',
	UPDATE_STUDY_VALIDITY:'UPDATE_STUDY_VALIDITY',
	OPEN_MODAL_POPUP: 'OPEN_MODAL_POPUP',
	SET_HISTORY:'SET_HISTORY',
	REMOVE_HISTORY:'REMOVE_HISTORY',
	GET_USER_ROLE:'GET_USER_ROLE',
	RESET_STATE:'RESET_STATE',
	//,STUDY_VALIDATION_CRITERIA:'STUDY_VALIDATION_CRITERIA'
	//SET_COHORT_FILTER: 'SET_COHORT_FILTER',
	//SET_STUDY_FILTER: 'SET_STUDY_FILTER'
	//,STUDY_VALIDATION_CRITSERIA:'STUDY_VALIDATION_CRITERIA'
	//new data detail actions
	//GET_DOCUMENT_TEXT:"GET_DOCUMENT_TEXT"
	//new data detail actions

	//following actions are for version patient list and data list 2.0
	SET_TOTAL_PATIENTS: 'SET_TOTAL_PATIENTS',
	TOGGLE_CONFIRM_DELETE: 'TOGGLE_CONFIRM_DELETE',
	COHORT_EDITED: 'COHORT_EDITED', // Used to refresh redux data after cohort edited
	GET_CONCEPT_CATEGORIES: 'GET_CONCEPT_CATEGORIES',
	SET_USER_ROLE:'SET_USER_ROLE',
	GET_SEVERITY_VALUES:'GET_SEVERITY_VALUES',
	GET_DESCRIPTOR_VALUES:'GET_DESCRIPTOR_VALUES',
	GET_PROGRESSION_VALUES:'GET_PROGRESSION_VALUES',
	GET_NUMERIC_VALUE_UNITS: 'GET_NUMERIC_VALUE_UNITS',
	GET_MEDICATION_UNITS: 'GET_MEDICATION_UNITS',
	GET_MEDCATION_FORMS: 'GET_MEDCATION_FORMS',
	GET_MEDICATION_ROUTES: 'GET_MEDICATION_ROUTES',
	GET_CONCEPT_CATEGORIES_OPTIONS: 'GET_CONCEPT_CATEGORIES_OPTIONS',
	SET_ANNOTATOR_PATIENT_LIST:'SET_ANNOTATOR_PATIENT_LIST',
	GET_SUBDOCUMENT_DETAIL:'GET_SUBDOCUMENT_DETAIL',
	// GET_MANUAL_ANNOTATIONS_SUBDOC: 'GET_MANUAL_ANNOTATIONS_SUBDOC',
	GET_NOT_EXPERIENCED_REASONS: 'GET_NOT_EXPERIENCED_REASONS',
	GET_NOT_DEFINITIVE_DETAILS: 'GET_NOT_DEFINITIVE_DETAILS',

	SET_USER_ROLE:'SET_USER_ROLE',
	GET_STUDY_LIST:'GET_STUDY_LIST',
	ADD_STUDY_NAME:'ADD_STUDY_NAME',
	DO_STUDY_ACTION:'DO_STUDY_ACTION',
	GET_STUDY_INFO:'GET_STUDY_INFO',
	GET_PATIENTS_ENCOUNTER: 'GET_PATIENTS_ENCOUNTER'
}
