import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group"; //This is used for fade in Animation
import { Button, Alert, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import actions from "../../../actions";
import constants from "../../../constants";
import {
  getObjectFromLocal,
  setObjectToLocal,
  removeItemFromLocal,
} from "../../../utilities/utils";
import auth0 from "auth0-js";
import queryString from "query-string";
import { roleAccess } from "../../../utilities/AppConstants";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import {
  verifyAuth,
  getUserRole,
  Auth0VerifyAuthenticationCode,
  auth0Login,
} from "./Auth0Step2VerificationHelper";

const Auth0Step2Verification = (props) => {
  const [code, setCode] = useState("");
  const [err, setErr] = useState("");
  const [placeholder, setPlaceholder] = useState("Enter the 6-digit code");
  const [user_email, setUserEmail] = useState("");
  const [change_password, setChangePassword] = useState(
    props.isChangePassword ? props.isChangePassword : false
  );
  const [showAnimate, setShowAnimate] = useState(false);
  const focusPlaceholder = (e) => {
    setPlaceholder("");
  };

  const blurPlaceholder = (e) => {
    setPlaceholder("Verification code");
  };
  /****************************************
   * Function name: getCode
   * This function is used for Code/OTP generation.
   ****************************************/

  const getCode = (event) => {
    //Here will be the code for Code/OTP generation.
    props.history.push(`${constants.ROUTE_URL}/code`);
    // event.preventDefault();
  };

  /*****************************************
   * Function name: componentDidMount
   * Description: React LifeCycle Method that runs just after rendering of the component.
   * It adds a css class that gives the background image on component load.
   * It also gets the Auth0 information from the hash url.
   ****************************************/
  useEffect(() => {
    setShowAnimate(true);
    //adds background image to the body
    const element = document.getElementsByTagName("body")[0];
    element?.classList?.add("bg-image");
    // For Auth0 Access
    if (constants.ALLOW_AUTH0_ACCESS_URL == "Y") {
      let { hash } = props.location; // Getting the Auth0 hash url from the props.
      hash = hash.slice(1); // Removing the first character ie : # from the Auth0 hash url.
      hash = queryString.parse(hash); // Using the querystring component method to parse the hash url and show the Auth0 results in a array.

      // Retrieving the Auth0 results from the array.
      const access_token = hash.access_token;
      let errorHash = props?.location?.hash?.substr(1);

      // Login success from Auth0, get the user profile information based on access token.
      if (access_token) {
        //Assigning Auth0 credentials from the config file.
        const webAuth = new auth0.WebAuth({
          domain: constants.AUTH0_DOMAIN,
          clientID: constants.AUTH0_CLIENT_ID,
        });

        webAuth.client.userInfo(access_token, (err, userDetails) => {
          // This method will make a request to the /userinfo endpoint
          // and return the user object, which contains the user's information,
          const user_name = userDetails.name;
          const user_email = userDetails.email;
          setUserEmail(user_email);
          auth0Login(user_email);
        });

        removeItemFromLocal("change_password_user");
      } else if (errorHash?.indexOf("error") !== -1) {
        props.history.push(`${constants.ROUTE_URL}`);
      }
      // Password Expired from Auth0, call the change password api and show to change password screen.
      else {
        const user = getObjectFromLocal("change_password_user") || {};
        const user_email = user.email;
        //Assigning Auth0 credentials from the config file.
        const webAuth = new auth0.WebAuth({
          domain: constants.AUTH0_DOMAIN,
          clientID: constants.AUTH0_CLIENT_ID,
        });
        // Validating the change password api end point for Auth0.
        webAuth.changePassword(
          {
            connection: "Username-Password-Authentication",
            email: user_email,
          },
          (err, authResult) => {
            if (err) {
              setErr(err.error_description);
            }
          }
        );
        // Show to change password screen to the user
        setChangePassword(true);
      }
    }
    // For Normal Access
    else {
      const user = getObjectFromLocal("user") || {};
      setUserEmail(user.email);
    }
    return () => {
      const element = document.getElementsByTagName("body")[0];
      element?.classList?.remove("bg-image");
    };
  }, []);
  /****************************************
   * Function Name: gotoLogin.
   * Description: This method redirects the user to the login screen.
   ****************************************/
  const gotoLogin = () => {
    removeItemFromLocal("change_password_user");
    props.history.push(`${constants.ROUTE_URL}/`);
  };
  /****************************************
   * Function Name: changePasswordDetail.
   * Description: This method is used to show the change password screen.
   * Returns the JSX code which renders the HTML to be shown to the user.
   ****************************************/
  const changePasswordDetail = () => {
    const user = getObjectFromLocal("change_password_user") || {};

    return (
      <div className="login-container">
        <div className="login code-signature">
          <div className="login-header">
            <div className="logo-wrapper">
              <img className="logo_img" src={logo} alt="Verantos_Logo" />
            </div>
            <span className="login-text">Change password?</span>
          </div>
          <div className="singnature-text">
            Password reset instructions have been sent to <br />
            {user?.email}. Please check your e-mail and follow the instructions
            to set your new password.
          </div>
          <div className="login_form">
            <p className="submit send_password">
              <Button
                type="submit"
                onClick={gotoLogin}
                className="btn-submit-ok"
              >
                Done
              </Button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props.redAuth?.step2verify) {
      props.history.push(`${constants.ROUTE_URL}/dashboard/studylist`);
    }
  }, [props.redAuth?.step2verify]);

  /****************************************
   * Function Name: updateValue.
   * Description: This method updates the value of the verification code
   * from the form input in the state.
   * Method has one signature.
   * @param - e - It is event property through which we get the values.
   ****************************************/

  const updateValue = (event) => {
    //updates the value from the form input
    setCode(event.target.value);
  };

  /****************************************
   * Function Name: verifyCode.
   * Description: This function performs the necessary validations on the
   * front end whenever the user submits the code.
   * Checks for valid code and if true it navigates the user to
   * the homepage of the application
   * It allows bypass of normal users for Auth0 authentication.
   ****************************************/

  const verifyCode = () => {
    //Verification of the code entered by the user
    setErr("");
    if (!code) {
      setErr("Please enter a code.");
      return;
    } else {
      const email = user_email || {};
      const authSecret = getObjectFromLocal("authSecret") || {};
      // For Auth0 Access

      if (constants.ALLOW_AUTH0_ACCESS_URL == "Y") {
        //dispatching an action to set the step2 verification as success
        Auth0VerifyAuthenticationCode(authSecret.secret, code, email)
          .then((res) => {
            setObjectToLocal("user", { email, codeVerified: true });
            getUserRole().then((userRole) => {
              if (
                userRole.length > 0 &&
                userRole[0].Role === roleAccess.ANNOTATOR
              ) {
                try {
                  localforage.getItem('isVAStudy').then(enable =>{
                    if(!enable) {
                      props.history.push(constants.ROUTE_URL + '/dashboard/dataworkqueue');
                    } else {
                      props.history.push(constants.ROUTE_URL + '/dashboard/datafilter');
                    }
                  });
                } catch(error) {
                  console.error(error);
                }
              } else {
                props.history.push(
                  `${constants.ROUTE_URL}/dashboard/studylist`
                );
              }
            });
          })
          .catch((err) => {
            setErr("Invalid code. Please try again.");
          });
      }
      // For Normal Access
      else {
        //dispatching an action to set the step2 verification as success
        verifyAuth(authSecret.secret, code).then(
          (res) => {
            setObjectToLocal("user", { email, codeVerified: true });
            getUserRole().then((userRole) => {
              if (
                userRole.length > 0 &&
                userRole[0].Role === roleAccess.ANNOTATOR
              ) {
                try {
                  localforage.getItem('MFAEnable').then(enable =>{
                    if(enable) {
                      props.history.push(constants.ROUTE_URL + '/dashboard/dataworkqueue');
                    } else {
                      props.history.push(constants.ROUTE_URL + '/dashboard/datafilter');
                    }
                  });
                } catch(error) {
                  console.log(error);
                }
              } else {
                props.history.push(
                  `${constants.ROUTE_URL}/dashboard/studylist`
                );
              }
            });
          },
          (error) => {
            if (error.response.status === 400) {
              setErr("Invalid code. Please try again.");
            }
          }
        );
      }
    }
  };
  /****************************************
   * Function Name: handleKeyPress.
   * Description: This function handles form submit when Enter key is pressed.
   * Checks if enter key is pressed and if true it calls
   * the function verifyCode to perform validations.
   ****************************************/

  const handleKeyPress = (target) => {
    //check if enter is pressed.
    if (target.charCode == 13) {
      verifyCode();
    }
  };

  /****************************************
   * Function Name: render.
   * Description: React Lifecycle method that renders the HTML to the DOM.
   * Used to display the UI to the user for this particular component.
   * Returns the JSX code which renders the HTML to be shown to the user.
   ****************************************/

  return (
    /* ReactCSSTransition Group is used to implement the fade in effect
			for step2 page*/
    change_password == false ? (
      <CSSTransition in={showAnimate} timeout={3000} classNames="fade">
        <div className="login-container">
          <div className="login  code-signature">
            <div className="login-header">
              <div className="logo-wrapper">
                <img className="logo_img" src={logo} alt="Verantos_Logo" />
              </div>
              <span className="login-text">Two-step verification</span>
            </div>
            <div className="singnature-text">
              Enter the 6-digit code we've just sent to your phone.
            </div>
            <div className="form-field-control">
              <FormControl
                type="text"
                name="code"
                value={code}
                id="verificationCode"
                className="form-field-input"
                placeholder=" "
                onBlur={blurPlaceholder}
                autoFocus
                onClick={focusPlaceholder}
                onChange={updateValue}
                onKeyPress={handleKeyPress}
              />
              <label
                htmlFor="verificationCode"
                id="email-label"
                className="form-field-label"
              >
                Verification Code
              </label>
            </div>
            <div className="login-error_field">{err}</div>
            <p className="submit">
              <button
                type="button"
                name="commit"
                value="Verify"
                id="verifyCode"
                className="btn-code-verify"
                onClick={verifyCode}
              >
                Submit
              </button>
            </p>
            <p className="code_v">
              <p>
                <Link to={`${constants.ROUTE_URL}/code`} className="forgot">
                  No code?
                </Link>
              </p>
            </p>
          </div>
        </div>
      </CSSTransition>
    ) : (
      changePasswordDetail()
    )
  );
};

Auth0Step2Verification.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isChangePassword: PropTypes.any,
  location: PropTypes.shape({
    hash: PropTypes.any,
  }),
  redAuth: PropTypes.shape({
    step2verify: PropTypes.any,
  }),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Auth0Step2Verification);
