import React, { useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import constants from '../../constants';
import { connect } from 'react-redux';
import queryString from 'query-string';
import actions from '../../actions';
import { setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../components/ErrorBoundary';
import {
	setIsLoading,
	setTotalPatients,
	getCohortList,
	getEvent,
	getStudyDetail,
	searchConceptsById,
	getPatientDocuments,
	getDocEntityCounts,
	getDocumentIconsInfo,
	getEntityCounts,
	getEntityCountsAnnotator,
	getEncounterMulticlassUserEntityCounts,
	getPatientMulticlassUserEntityCounts,
	getEncounterTwoclassUserEntityCounts,
} from './DataListHelper';
import { roleAccess } from '../../utilities/AppConstants';


const Patients = lazy(() =>
	import(/* webpackChunkName: "Patients" */ '../../components/Patients/Patients')
);
import dataIcon from '../../images/data_icon.png';

const headerData = {
	title: 'Data',
	searchTitle: 'data',
	list: 'list',
	img: (
		<div className="data_icon">
			<img src={dataIcon} alt="Data List" style={{ width: 20 }} />
		</div>
	),
	lastColumn: 'Patients',
};

function DataList(props) {
	let { userRole } = props.redSidenav;

	const studyId = props?.match?.params?.studyId;
	const conceptId = props?.match?.params?.conceptId;
	const twoClass = props?.match?.params?.twoClass;
	const is_event = props?.match?.params?.is_event || 'false';
	const att_name = props?.match?.params?.att_name || '';
	const att_value = props?.match?.params?.att_value || '';
	const isConcept = props?.match?.params?.isConcept;
	const cohortCriteria = props?.match?.params?.cohortCriteria;
	const isRefStCount = props?.match?.params?.isRefStCount;
	
	let criteriaFromSession = localStorage.getItem(studyId+'studyCriteria');
	let criteria;
	if (criteriaFromSession) {
		criteria = JSON.parse(criteriaFromSession);
	}
	
	useEffect(() => {
		//RESET PATIENT COUNT FROM PATIENT LIST FOR PAGINATION
		setTotalPatients(0);
		let cohort_id = queryString.parse(window.location.search).cohort_id;
		let concept_id = queryString.parse(window.location.search).concept_id;
		let event_id = queryString.parse(window.location.search).event_id;
		let study_id = queryString.parse(window.location.search).study_id;
		let patient_id = queryString.parse(window.location.search).patient_id;
		if (cohort_id) {
			setIsLoading(true);
			let cohortName = '';

			getCohortList().then((res) => {
				if (res.data.data) {
					let temp = res.data.data.filter((item) => item.cohort_id == cohort_id)[0];
					cohortName = temp.cohort_name;
					if (concept_id) {
						searchConceptsById(concept_id).then((resp) => {
							let options = [
								{
									filterLabel: 'Cohort',
									name: cohortName,
								},
								{
									filterLabel: 'Concept',
									name: resp[0].text,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else if (event_id && event_id != 0) {
						getEvent().then((res) => {
							let events = res;
							let event = events.filter((item) => item.event_id == event_id);

							let eventName = event.length > 0 ? event[0].name : '';
							let options = [
								{
									filterLabel: 'Cohort',
									name: cohortName,
								},
								{
									filterLabel: 'Event',
									name: eventName,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else {
						let options = [
							{
								filterLabel: 'Cohort',
								name: cohortName,
							},
						];
						setIsLoading(false);
						setFilter(options);
					}
				}
			});
		}

		if (study_id) {
			setIsLoading(true);
			getStudyDetail(study_id).then((studyDetail) => {
				setIsLoading(false);
				let options = [
					{
						filterLabel: 'Study',
						name: studyDetail.study_name,
					},
				];
				setFilter(options);
			});
		}
		if (concept_id) {
			setIsLoading(true);
			searchConceptsById(concept_id).then((resp) => {
				setIsLoading(false);
				let options = [
					{
						filterLabel: 'Concept',
						name: resp[0].text,
					},
				];
				setFilter(options);
			});
		}
		if (patient_id) {
			setIsLoading(true);
			props.dispatch(actions.getPatientList(patient_id)).then((res) => {
				setIsLoading(false);
				let options = [
					{
						filterLabel: 'Patient',
						name: res.data.data[0].patient_Identifier,
					},
				];
				setFilter(options);
			});
		}
		
	}, []);

	const setFilter = (options) => {
		let filterLabel = {
			page: 'patient',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	const fetchData = (patientId, organizationId, searchText, role) => {
		return new Promise((resolve) => {
			const initialCounts = {
				study_count: '',
				cohort_count: '',
				data_count: '',
				patient_count: '',
			};
			getPatientDocuments(patientId, organizationId, searchText, role)
				.then((docs) => {
					docs.map((doc) => {
						doc.counts = initialCounts;
					});
					if (role == roleAccess.ANNOTATOR) {
						getDocumentIconsInfo(docs, role)
							.then((documentWithIcons) => {
								const response = {
									document: documentWithIcons,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					} else {
						getDocEntityCounts(docs)
							.then((docsWithCount) => {
								const response = {
									document: docsWithCount,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					}
				})
				.catch((error) => console.log(error));
		});
	};

	const fetchEntitiesCount = () => {
		return new Promise((resolve) => {
			if (conceptId) {
				if (twoClass === 'encounter') {
					getEncounterMulticlassUserEntityCounts(studyId, conceptId).then((res) => {
						if (res && Object.keys(res).length) {
							resolve(res);
						}
					});
				}
				if (twoClass === 'patient') {
					const _cohortCriteria = isConcept === 'true' ? cohortCriteria : 'All';
					getPatientMulticlassUserEntityCounts(studyId, conceptId, _cohortCriteria).then(
						(res) => {
							if (res && Object.keys(res).length) {
								resolve(res);
							}
						}
					);
				}
				if (twoClass === 'twoClass') {
					getEncounterTwoclassUserEntityCounts(
						studyId,
						conceptId,
						is_event,
						att_name,
						att_value,
						isConcept,
						cohortCriteria,
						criteria?.batch?.value,
						criteria?.batchId?.value,
						isRefStCount
					).then((res) => {
						if (res && Object.keys(res).length) {
							resolve(res);
						}
					});
				}
			} else if (userRole.Role == roleAccess.ANNOTATOR) {
				getEntityCountsAnnotator().then((res) => {
					if (res && Object.keys(res).length) {
						resolve(res);
					}
				});
			} else {
				getEntityCounts().then((res) => {
					if (res && Object.keys(res).length) {
						resolve(res);
					}
				});
			}
		});
	};

	return (
		<React.Fragment>
			{userRole && userRole.Role && (
				<ErrorBoundary>
					<Suspense fallback={<div className="defaultSpinner" />}>
						<Patients
							filter={props.redSidenav.filter}
							headerData={headerData}
							isDataPage={1}
							userRole={userRole}
							history={props.history}
							fetchData={fetchData}
							fetchEntitiesCount={fetchEntitiesCount}
							{...props}
						/>
					</Suspense>
				</ErrorBoundary>
			)}
		</React.Fragment>
	);
}

DataList.propTypes = {
	dispatch: PropTypes.func,
	history: PropTypes.any,
	match: PropTypes.shape({
		params: PropTypes.shape({
			att_name: PropTypes.string,
			att_value: PropTypes.string,
			cohortCriteria: PropTypes.any,
			conceptId: PropTypes.any,
			isConcept: PropTypes.string,
			is_event: PropTypes.string,
			studyId: PropTypes.any,
			twoClass: PropTypes.string,
		}),
	}),
	redSidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
	sidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};
export default connect((state) => state)(DataList);
