/* istanbul ignore file */
import actionTypes from '../actions/actionTypes';

//defining initial state for the reducer
let initialState= {
	cohortList : [],
	// cohortDetails : [],
	// cohortPatients: [],
	// cohortData: [],
	cohortCriterion: [],
	events: [],
	criteriaData: [],
	conceptCohorts : [],
	patientCohorts: [],
	documentCohorts: [],
	// cohortManualAnotations:[],
	// cohortManualList:[],
	// cohorDocumentList:[],
	// dateRangeDeleteMode: false,
	// filterApplied: false,
	confirmDelete: false,
	isCohortEdited: false,
};

//reducer that matches the action type for cohort actions and changes the state
export const cohortReducer = (state = initialState, action) => {

	switch(action.type) {
		// case actionTypes.SET_COHORT_DETAILS : {
		// 	return {...state, cohortDetails : action.payload}
		// }

		case actionTypes.GET_COHORT_LIST : {
			return {...state, cohortList: action.payload}
		}

		// case actionTypes.SET_COHORT_PATIENT : {
		// 	return {...state, cohortPatients: action.payload}
		// }

		// case actionTypes.SET_COHORT_DATA : {
		// 	return {...state, cohortData: action.payload}
		// }

		case actionTypes.SET_CRITERION_CONCEPTS : {
			return {...state, cohortCriterion: action.payload}
		}

		case actionTypes.GET_EVENT : {
			return {...state, events: action.payload}
		}

		case actionTypes.SET_CRITERIA_DATA : {
			return {...state, criteriaData: action.payload}
		}

		case actionTypes.SET_CONCEPT_COHORTS : {
			return { ...state, conceptCohorts: action.payload }
		}

		case actionTypes.SET_PATIENT_COHORTS : {
			return { ...state, patientCohorts: action.payload }
		}

		case actionTypes.SET_DOCUMENT_COHORTS : {
			return { ...state, documentCohorts: action.payload }
		}
		// case actionTypes.GET_COHORT_MANUAL_ANNOTATIONS : {
		// 	return { ...state, cohortManualAnotations: action.payload }
		// }
		// case actionTypes.SET_COHORTS_MANUAL_ANNOTATIONS : {
		// 	return { ...state, cohortManualList: action.payload }
		// }
		// case actionTypes.GET_COHORT_DOCUMENTS : {
		// 	return { ...state, cohorDocumentList: action.payload }
		// }
		// case actionTypes.SET_DATERANGE_DELETE_MODE: {
		// 	return { ...state, dateRangeDeleteMode: action.payload}
		// }
		// case actionTypes.SET_COHORT_FILTER: { //TOGGLE FILTER MODE
		// 	return { ...state, filterApplied: action.payload }
		// }
		case actionTypes.TOGGLE_CONFIRM_DELETE: {
			return { ...state, confirmDelete: action.payload };
		}

		case actionTypes.COHORT_EDITED: 
			return { ...state, isCohortEdited: action.payload };


		default : {
			return state;
		}
	}
}
