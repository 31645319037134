import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../../actions';
import queryString from 'query-string';
import { setHistory, setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../../components/ErrorBoundary';
// import PLAstudyreview from '../../../../data/PLAstudyreview.json';
import HeaderAccordion from '../../../components/HeaderAccordion/HeaderAccordion';
import { setIsLoading } from '../../../actions/common';
import {
	GetPatientManualAnnotationsWithConflictsStudys,
	getStudyDetail,
	getUserDetails,
	searchConceptsById,
	getDerivedManualAnnotations,
	getDescriptorValues,
	getSeverityValues,
	getProgressionValues,
	getManualAnnotationProjectAttributes,
} from './DataPLAStudyReviewHelper';

import PaginationComponent from '../../../common/Pagination/PaginationComponent';

const TextLevelAnnotation = lazy(() =>
	import(
		/* webpackChunkName: "TextLevelAnnotation" */ '../../../components/TextLevelAnnotation/TextLevelAnnotation'
	)
);

const PatientLevelConflicts = lazy(() =>
	import(
		/* webpackChunkName: "PatientLevelConflicts" */ '../../../components/PatientLevelConflicts/PatientLevelConflicts'
	)
);
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
	// img: <img src={constants.IMG_URL+'data_icon.png'} className={'App-DataListImage'} alt={'Data List'} style={{width: '20px', marginRight: '5px'}}/>
};

const DataPLAStudyReview = (props) => {
	const [doc_name, setDoc_name] = useState('');
	const [study_name, setStudy_name] = useState('');
	const [conceptName, setConceptName] = useState('');
	const [PatientLevelAnnotation, setPatientLevelAnnotation] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [totalPatientCount, setTotalPatientCount] = useState(0);
	const [annotationAttribute, setAnnotationAttribute] = useState('');
	const [annotationProps, setAnnotationProps] = useState(null);
	const [patientId, setPatientId] = useState('');

	let { docId } = props.match.params;
	let { loading } = props.loading;
	let concept_id = queryString.parse(props?.location?.search).concept_id;

	useEffect(() => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		setIsLoading(true);
		getUserDetails().then((res) => {});

		getStudyDetail(study_id).then((studyDetail) => {
			setStudy_name(studyDetail.study_name);

			if (concept_id) {
				searchConceptsById(concept_id).then((resp) => {
					if (resp) {
						setConceptName(resp[0].text);
					}
					if (concept_id && study_id) {
						let filterLabel = {
							page: 'datastudyreview',
							options: [
								{
									filterLabel: 'Study',
									name: studyDetail.study_name,
									fixed: true,
								},
								{
									filterLabel: 'Concept',
									name: conceptName,
								},
							],
						};
						setFilterLabel(filterLabel);
					}
				});
			} else {
				let filterLabel = {
					page: 'datastudyreview',
					options: [
						{
							filterLabel: 'Study',
							name: studyDetail.study_name,
							fixed: true,
						},
					],
				};
				setFilterLabel(filterLabel);
			}
		});
		if (!props.redUsermanagement || props.redUsermanagement.userList.length == 0) {
			getUserDetails();
		}
		GetPatientManualAnnotationsWithConflictsStudy(study_id, 1, 10);
	}, []);

	useEffect(() => {
		if (props?.redDocument?.descriptorOptions?.length == 0) {
			getDescriptorValues();
		}
		if (props?.redDocument?.progressionOptions?.length == 0) {
			getSeverityValues();
		}
		if (props?.redDocument?.severityOptions?.length == 0) {
			getProgressionValues();
		}
		setIsLoading(true);
		getManualAnnotationProjectAttributes().then((resp) => {
			setAnnotationAttribute(resp?.data?.data?.RequiredAnnotationAttribute || '');
			setIsLoading(false);
		});
		if (props.derivedAnnotationId && patientId) {
			getDerivedManualAnnotations(props.derivedAnnotationId, patientId, 'Outcome').then(
				(resp) => {
					setAnnotationProps(resp.data.data[0] || []);
				}
			);
		}
	}, []);

	const GetPatientManualAnnotationsWithConflictsStudy = (study_id, pageNo, recordsPerPage) => {
		setIsLoading(true);
		GetPatientManualAnnotationsWithConflictsStudys(study_id, pageNo, recordsPerPage).then(
			(res) => {
				setIsLoading(false);
				if (pageNo == 1) {
					setPatientLevelAnnotation(res.data.payload);
					setTotalPatientCount(
						res.data.totalRecords ? res.data.totalRecords : res.data.payload.length
					);
				} else {
					setPatientLevelAnnotation(res.data.payload);
				}
			}
		);
	};

	/****************************************
	 * Function Name: gotoPage
	 * Description: Switches to different pages from pagination.
	 * @param pageNo Page number
	 ****************************************/
	const gotoPage = (pageNo, recordsPerPage) => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		setPageNo(pageNo);
		setRecordsPerPage(recordsPerPage);
		GetPatientManualAnnotationsWithConflictsStudy(study_id, pageNo, recordsPerPage);
	};

	const gotoPatientDetail = (patientId) => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/${patientId}`);
	};

	const gotoStudyDetail = () => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		props.history.push(`${constants.ROUTE_URL}/dashboard/studydesign/${study_id}`);
	};

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<div className="document-header-container">
					<span> Manual annotation conflicts: </span>
					<span className="hyperlink" onClick={gotoStudyDetail}>
						{study_name}
					</span>
				</div>
			</ErrorBoundary>

			{PatientLevelAnnotation &&
				PatientLevelAnnotation.length > 0 &&
				PatientLevelAnnotation.map((patient) => {
					return (
						<ErrorBoundary>
							<HeaderAccordion
								type={'header'}
								identifier={patient.PatientId}
								referenceId={patient.PatientId}
								text={patient.PatientIdentifier}
								leftPad={20}
								isOpen={true}
								handleClick={gotoPatientDetail}
							>
								{patient.PatientAnnotationDetail &&
									patient.PatientAnnotationDetail.map((concept) => {
										return (
											<HeaderAccordion
												type={'subheader'}
												identifier={patient.PatientId + concept.ConceptID}
												referenceId={concept.ConceptID}
												text={concept.ConceptName}
												leftPad={40}
												isOpen={false}
											>
												{concept.TextAnnotation &&
													concept.TextAnnotation.length > 0 && (
														<Suspense
															fallback={
																<div className="defaultSpinner" />
															}
														>
															<TextLevelAnnotation
																concept={concept}
																annotations={concept.TextAnnotation}
																identifier={
																	patient.PatientId +
																	concept.ConceptID
																}
															></TextLevelAnnotation>
														</Suspense>
													)}
												{concept.PatientlevelAnnotation &&
													concept.PatientlevelAnnotation.length > 0 && (
														<Suspense
															fallback={
																<div className="defaultSpinner" />
															}
														>
															<PatientLevelConflicts
																concept={concept}
																identifier={
																	patient.PatientId +
																	concept.ConceptID
																}
																annotations={
																	concept.PatientlevelAnnotation
																}
																requiredAnnotationAttribute={
																	annotationAttribute
																}
																listAnnoation={annotationProps}
															></PatientLevelConflicts>
														</Suspense>
													)}
											</HeaderAccordion>
										);
									})}
							</HeaderAccordion>
						</ErrorBoundary>
					);
				})}
			{totalPatientCount > 0 && (
				<PaginationComponent
					totalItemsCount={totalPatientCount}
					gotoPage={(pageNo, recordsPerPage) => gotoPage(pageNo, recordsPerPage)}
					currentPage={pageNo}
				/>
			)}
		</div>
	);
};

DataPLAStudyReview.propTypes = {
	derivedAnnotationId: PropTypes.any,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
	redDocument: PropTypes.shape({
		descriptorOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		progressionOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		severityOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
	redUsermanagement: PropTypes.shape({
		userList: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataPLAStudyReview);
