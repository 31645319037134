/* istanbul ignore file */
import actionTypes from '../../actions/actionTypes';
import { apiGet } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';

export function GetAnnotatorKappaScores(conceptId, cohortId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetAnnotatorKappaScores', {
			m_conceptId: conceptId,
			m_CohortId: cohortId,
		})
			.then((res) => {
				resolve(res.data.payload);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getStudyDetail(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }

export function setSideNavList(list) {
	store.dispatch({
		type: actionTypes.SET_SIDE_NAVLIST,
		payload: list,
	});
}
