//configuring all the actions in one single file
/* istanbul ignore file */
import * as authActions from './auth';
import * as studyActions from './studyActions';
import * as cohortActions from './cohortActions';
import * as patientActions from './patientActions';
import * as historyActions from './historyActions';
import * as technologyActions from './technologyActions';
import * as commonActions from './common';
import * as sidenavActions from './sideNavActions';
import * as dataManagementActions from './dataManagementActions';
import * as userManagementActions from './userManagementActions';
import * as accountManagementActions from './accountManagementActions';
import * as rapidManualAnnotationActions from './rapidManualAnnotationActions';
import * as summaryAnalyticsActions from './summaryAnalyticsActions';
import * as modalActions from './modalActions'

export default {
	
	...authActions,
	...studyActions,
	...cohortActions,
	...patientActions,
	...historyActions,
	...technologyActions,
	...commonActions,
	...sidenavActions,
	...dataManagementActions,
	...userManagementActions,
	...accountManagementActions,
	...rapidManualAnnotationActions,
	...summaryAnalyticsActions,
	...modalActions
}