/* istanbul ignore file */
import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export function getSubdocumentById(subddoc_id) {
	return new Promise(function (resolve, reject) {
		let storedData = store.getState();
		let storedSubdocument =
			storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
		if (storedSubdocument) {
			resolve(storedSubdocument);
		} else {
			apiGet('GetSubdocumentById', {
				m_documentId: subddoc_id,
			})
				.then((res) => {
					if (res?.data?.data) {
						store.dispatch({
							type: actionTypes.GET_SUBDOCUMENT_DETAIL,
							payload: { [`subdocument_${subddoc_id}`]: res },
						});
					}
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		}
	});
}

export function getCohortListForDocument(document_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortListForDocument', {
			m_DocIdList: document_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_DOCUMENT_COHORTS,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export function getCohortListForConcept(concept_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortListForConcept', {
			concept_id: concept_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_CONCEPT_COHORTS,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
