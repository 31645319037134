/* istanbul ignore file */
import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: updateProfile
     * Description: exports an action to start the data upload when the user clicks
     * the start button. 
     * Method has one signature.
     * @param -userDetailCriterionString - The object having the necessary details required for
     * the upload of the data such as userDetails 
****************************************/
export function updateProfile(userDetailCriterionString){
	return dispatch => new Promise(function(resolve, reject){
		apiGet("UpdateUserDetails", {
			userDetailCriterionString : userDetailCriterionString
		}).then(res => {
               resolve(res);
          })
          .catch(err => {

               console.log(err);
               reject(err);
          })
	});
}

/**************************************** 
     * Function Name: updateUserImage
     * Description: exports an action to change the data upload when the user clicks
     * the change button.
     * Method has one signature.
     * @param -userPic - The object having the necessary details required for
     * the upload of the data such as userPic
****************************************/
export function updateUserImage(userPic){
     return dispatch => new Promise(function(resolve, reject){
          apiGet("userImage", {
                userPic: userPic
          }).then(res => {
               dispatch({
                    type: actionTypes.GET_USER_IMAGE,
                    payload: res.data.data
               });
          })
          .catch(err => {
               console.log(err);
          })
     });
}	


// export function getUserOrganization() {
//      return dispatch => new Promise(function(resolve, reject){
//           apiGet('GetOrganization', {}).then(res => {
//                 dispatch({
//                     type: actionTypes.GET_USER_ORGANIZATION,
//                     payload: res.data.data
//                });
     
//                resolve(res.data);
//           })
//           .catch(err => {
//                console.log(err);
//                reject(err);
//           });
//      });
// }