import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSectionText } from '../DocumentHelper/documentHelper';
import queryString from 'query-string';
import Loading from 'react-loading-spinner';
import ReactHtmlParser from 'react-html-parser';
import {
	convertToPlural,
	setObjectToLocal,
	removeItemFromLocal,
	getObjectFromLocal,
} from '../../../utilities/utils';
import axios from 'axios';
import { setHistory, setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ModalComponent from '../../../common/ModalComponent/ModalComponent';
import Copyright from '../../../components/Copyright/Copyright';
import { roleAccess } from '../../../utilities/AppConstants';
import CancelRapidManual from '../../../components/CancelRapidManual/CancelRapidManual';
import { setIsLoading } from '../../../actions/common';
import {
	getDescriptorValues,
	getSeverityValues,
	getProgressionValues,
	getNumericValueUnits,
	getMedicationUnits,
	getMedicationForms,
	getMedicationRoutes,
	GetConceptCategoriesOptions,
	GetNotExperiencedReasons,
	GetNotDefinitiveDetails,
	getSubdocumentById,
	GetSubdocumentList,
	getHistoryData,
	searchConceptsById,
	setRapidManualAnnotation,
	getManualAnnotationsNew,
	getRapidManualAnnotationData,
	getUserManualAnnotationsWithoutFlag,
	getAnnotatorEncounters
} from './DocumentContainerHelper';

const SectionalText = lazy(() =>
	import(/* webpackChunkName: "SectionalText" */ '../SectionalText/SectionalText')
);
const DocumentHeader = lazy(() =>
	import(/* webpackChunkName: "DocumentHeader" */ '../DocumentHeader/DocumentHeader')
);
import dataIcon from '../../../images/data_icon.png';
import localforage from 'localforage';
// import subDocumentFromFile from '../../../finaloutput.json';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
	// img: <img src={constants.IMG_URL+'data_icon.png'} className={'App-DataListImage'} alt={'Data List'} style={{width: '20px', marginRight: '5px'}}/>
};
const DocumentContainer = (props) => {
	// class DocumentContainer extends React.Component {

	let { route_annotation_id } = queryString.parse(props?.location?.search);
	let { docId } = props.match.params;
	let annotator = getObjectFromLocal(`encounterAnnotator_${docId}`);
	const [editM_Annotation, setEditM_Annotation] = useState(false);
	const [newRapid, setNewRapid] = useState('');
	const [showRapidManualPopup, setShowRapidManualPopup] = useState(false);
	const [annotationid, setAnnotationid] = useState(route_annotation_id);
	const [sectionedText, setSectionedText] = useState('');
	const [sectionedJson, setSectionedJson] = useState('');
	const [manualAnnotatioDetailList, setManualAnnotatioDetailList] = useState([]);
	const [automatedAnnotationList, setAutomatedAnnotationList] = useState([]);
	const [manualAnnotationList, setManualAnnotationList] = useState([]);
	const [relatedEncounter, setRelatedEncounter] = useState([]);
	const [showRelatedEncounter, setShowRelatedEncounter] = useState(
		props.showEncounter ? props.showEncounter : false
	);
	const [fetchingEncounterText, setFetchingEncounterText] = useState(false);
	const [fetchingManualannotation, setFetchingManualannotation] = useState(false);
	const [toggleRapidAnnotationPopup, setToggleRapidAnnotationPopup] = useState(false);
	const [openMessageLevelPopup, setOpenMessageLevelPopup] = useState(false);
	const [openDocumentLevelPopup, setOpenDocumentLevelPopup] = useState(false);
	const [doc_name, setDoc_name] = useState('');
	const [doc_type, setDoc_type] = useState('');
	const [showRapidBadgeIcon, setShowRapidBadgeIcon] = useState(false);
	const [selectedAnnotation, setSelectedAnnotation] = useState([]);
	const [highlightManualAnnotation, setHighlightManualAnnotation] =  useState(false);
	
	const confirmPopup = useRef();
	let patientids = [];
	let patientConcepts = [];
	let {
		descriptorOptions,
		progressionOptions,
		severityOptions,
		numericValueUnitOptions,
		medicationUnitOptions,
		medicationFormOptions,
		medicationRoutesOptions,
		conceptCategoriesOptions,
		notExperiencedReasons,
		notDefinitiveDetails,
	} = props.redDocument;
	const [editMultipleManual, setEditMultipleManual] = useState(false);
	const [annotatorEncounters, setAnnotatorEncounters] = useState([]);
	const [selectedAnnotator, setSelectedAnnotator] = useState(annotator);
	

	useEffect(() => {
		setIsLoading(true);
		setFilterLabel({});
		if (getObjectFromLocal(`editmodeNormal_${docId}`)) {
			setEditM_Annotation(true);
			setNewRapid(getObjectFromLocal(`selectedConcept_${docId}`));
			setOpenMessageLevelPopup(true);
		}
		if (getObjectFromLocal(`fromEditMultipleManual_${docId}`)) {
			setEditMultipleManual(true);
		}
		if (getObjectFromLocal(`editmodeDocument_${docId}`) || getObjectFromLocal(`viewmodeDocument_${docId}`)) {
			setNewRapid(getObjectFromLocal(`selectedConcept_${docId}`));
		}
		// setIsLoading(true)
		let {
			descriptorOptions,
			progressionOptions,
			severityOptions,
			numericValueUnitOptions,
			medicationUnitOptions,
			medicationFormOptions,
			medicationRoutesOptions,
			conceptCategoriesOptions,
			notExperiencedReasons,
			notDefinitiveDetails,
		} = props.redDocument;

		if (descriptorOptions?.length == 0) {
			getDescriptorValues();
		}
		if (progressionOptions?.length == 0) {
			getSeverityValues();
		}
		if (severityOptions?.length == 0) {
			getProgressionValues();
		}
		if (numericValueUnitOptions?.length == 0) {
			getNumericValueUnits();
		}
		if (medicationUnitOptions?.length == 0) {
			getMedicationUnits();
		}
		if (medicationFormOptions?.length == 0) {
			getMedicationForms();
		}
		if (medicationRoutesOptions?.length == 0) {
			getMedicationRoutes();
		}
		if (conceptCategoriesOptions?.length == 0) {
			GetConceptCategoriesOptions();
		}
		if (notExperiencedReasons && notExperiencedReasons?.length == 0) {
			GetNotExperiencedReasons();
		}
		if (notDefinitiveDetails && notDefinitiveDetails?.length == 0) {
			GetNotDefinitiveDetails();
		}
		setFetchingEncounterText(true);
		getSubdocumentById(docId)
			.then((resp) => {
				setIsLoading(true);
				if (resp.data.data) {
					let documentDetail = resp.data.data;
					setDoc_name(documentDetail.name_with_date);
					setDoc_type(documentDetail.content_type);

					if (documentDetail.content_type != 'discrete') {
						getManualAnnuatationList();
						GetSubdocumentList(documentDetail.document_id, 'discrete').then((resp) => {
							if (Array.isArray(resp.data)) {
								setRelatedEncounter(resp.data || []);
							}
						});
					} else {
						setIsLoading(true);
						GetSubdocumentList(documentDetail.document_id, 'narrative').then((resp) => {
							if (Array.isArray(resp.data)) {
								setRelatedEncounter(resp.data || []);
							}
						});
						getHistoryData(docId).then((sectionedJson) => {
							setSectionedJson(sectionedJson);
							setIsLoading(false);
							setFetchingEncounterText(false);
						});
					}
				} else {
					setIsLoading(false);
				}
			})
			.catch(() => {
				setIsLoading(false);
			});
		// $('html, body').animate(
		// 	{
		// 		scrollTop: 0,
		// 	},
		// 	500
		// );

		if (window && window.scroll) {
			window.scroll({ top: 0, behavior: 'smooth' });
		}
		
		return () => {
			removeItemFromLocal(`encounterAnnotator_${docId}`)
		  };
	}, []);

	useEffect (()=>{
		
		if(props?.redSidenav?.userRole?.Role){
			localforage.getItem('nextEncounter').then(encounters => {
				if(encounters){
					setAnnotatorEncounters(encounters)
				} else{
					getAnnotatorEncounters().then(res => {
						if(res){
							
							let encounterList = [...new Set(res?.encounters?.map(items=> items.subdocument_id))]
							setAnnotatorEncounters(encounterList);
							localforage.setItem('nextEncounter', encounterList)
						}
					})
				}
			})
		}

	}, props?.redSidenav?.userRole?.Role)

	useEffect(() => {
		props?.redCohort?.documentCohorts &&
			props?.redCohort?.documentCohorts.map((concepts) => {
				if (patientids && patientids.indexOf(concepts.patient_id) != -1) {
					patientConcepts.map((pconcept, index) => {
						if (pconcept.patient_id == concepts.patient_id) {
							pconcept.patient_concepts.push(concepts);
						}
					});
				} else {
					patientids.push(concepts.patient_id);
					patientConcepts.push({
						patient_id: concepts.patient_id,
						patient_concepts: [concepts],
					});
				}
			});

		patientConcepts &&
			patientConcepts.map((patient) => {
				let patientCohortstIds = [];
				patient.cohorts = [];
				patient.patient_concepts &&
					patient.patient_concepts.map((concept) => {
						let ifExitsCohort = patient.cohorts.filter((cohort) => {
							return cohort.cohort_id == concept.cohort_id;
						});
						if (!ifExitsCohort?.length) {
							patient.cohorts.push({
								cohort_id: concept.cohort_id,
								cohort_name: concept.cohort_name,
							});
						}
					});
			});

		//--- inserting concept in cohorts
		patientConcepts.map((patient) => {
			patient.cohorts.map((cohort) => {
				let concepts = patient.patient_concepts.filter((concept) => {
					return concept.cohort_id == cohort.cohort_id;
				});
				cohort['concepts'] = concepts;
			});
		});
	}, [props?.redCohort]);

	// method trigger only browser back button click
	const onBackButtonEvent = (e) => {
		e.preventDefault();
		window.location.reload();
	};

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);
		};
	}, []);

	/****************************************
	 * Function Name: getManualAnnuatationList.
	 * Description: This method is used to check for Get manuaAnnotaton List for a particular
	 * id in the in the reducer. If it is present then API is
	 * not called to get the list and if not present then we make an API
	 * call to fetch the list and set the list in the reducer. Based on the data
	 * it changes the state of the popoverBottom so that the list Tooltip
	 * is shown to the user.
	 * Method has no signature:
	 * @param - doc_id - The id for which the annotation list has to be fetched.
	 ****************************************/

	const getManualAnnuatationList = () => {
		// let savedManualAnnotation = props.redDocument.subDocManualAnnotationList?.[docId];
		// if(savedManualAnnotation){
		// 	populateManualAnnotationList(savedManualAnnotation);
		// } else {
		setFetchingEncounterText(true);
		// setIsLoading(true);
		getManualAnnotationsNew(docId).then((annotation) => {
			populateManualAnnotationList(annotation);
		});
		// }
		let temp = getObjectFromLocal(`newRapidAnnotation_${docId}`);
		let newRapidAnnotationTemp = temp || '';

		if (newRapidAnnotationTemp && newRapidAnnotationTemp?.length > 0) {
			setShowRapidBadgeIcon(true);
		} else {
			setShowRapidBadgeIcon(false);
		}
	};

	const populateManualAnnotationList = (annotation) => {
		// setIsLoading(true);
		let m_annotationList = [];
		let annotationConcept_id = [];
		let textAnnotations = [];
		let textAnnotationIds = [];
		getDocumentAnnotationList(docId);
		annotation.map((annotationData) => {
			if (annotationData.annotation_type == 'Document concept') {
			}
			if (annotationData.annotation_type == 'Text') {
				textAnnotationIds.push(annotationData.annotation_id);
			}
		});

		let temp1 = getObjectFromLocal(`updatedAnnotation_${docId}`);
		let updatedAnnotations = temp1 || '';
		if (updatedAnnotations.length > 0) {
			updatedAnnotations.map((updatedAnnotation, index) => {
				annotation.map((existingAnnotation, ind) => {
					if (existingAnnotation.annotation_id == updatedAnnotation.annotation_id) {
						annotation[ind] = updatedAnnotation;
					}
				});
			});
		}

		let tempDeleted = getObjectFromLocal(`deletedAnnotation_${docId}`);
		let deletedAnnotation = tempDeleted ? tempDeleted : [];

		if (deletedAnnotation.length > 0) {
			deletedAnnotation.map((delItem, index) => {
				annotation.map((currentAnnot, currentAnnotIndex) => {
					annotation = annotation.filter((item) => {
						if (item.annotation_id !== delItem.annotation_id) {
							return item;
						}
					});
				});
			});
		}

		setManualAnnotatioDetailList(annotation || []);

		let conceptIds = [];
		annotation.map((annotationList) => {
			conceptIds.push(annotationList.concept_id);
		});

		if (conceptIds.length) {
			searchConceptsById(conceptIds)
				.then((criteriaConcepts) => {
					annotation.map((annotation) => {
						let concept = criteriaConcepts.filter(
							(item) => annotation.concept_id == item.cuid
						)[0];
						m_annotationList.push({
							...annotation,
							data: concept,
							concept_name: concept?.text,
						});
					});
					setManualAnnotationList(m_annotationList);
					if (!fetchingEncounterText) {
						// setIsLoading(false)
					}
					getSectionDocument(docId);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setManualAnnotationList(m_annotationList);
			getSectionDocument(docId);
		}
	};

	/****************************************
	 * Function Name: getDocumentAnnotationList.
	 * Description: This method is used to call the method getDocumentAnnotationList in the data detail container
	 * by passing the doc_id parameter as props through the document level.
	 * @param - doc_id - The document id for which the automated annotation list needs to be fetched.
	 ****************************************/

	const getDocumentAnnotationList = (doc_id) => {
		//props.dispatch(actions.getAutomatedAnnotationListForDocument(doc_id));
	};
	/****************************************
	 * Function Name: editMode.
	 * Description: This method used to goto manualAnnotation tooltip.
	 * Method has one signature:
	 ****************************************/
	const editMode = (newRapid) => {
		// setIsLoading(true);
		setObjectToLocal(`selectedConcept_${docId}`, newRapid);
		setEditM_Annotation(!editM_Annotation);
		setNewRapid(newRapid);
		setOpenMessageLevelPopup(true);
		getManualAnnuatationList();
	};

	const getSectionDocument = (docId) => {
		// axios.get("/data/finaloutput.json").then(resp => {
		//     let sectionedJson = resp.data;
		setIsLoading(true);
		// let subDocumentFromStorage = getObjectFromLocal(`subdocumentjson_${docId}`);
		localforage.getItem(`subdocumentjson_${docId}`).then((subDocumentFromStorage)=>{
			if (subDocumentFromStorage) {
				processAnnotatedText(subDocumentFromStorage);
				setIsLoading(false);
			} else {
				//-- fetching data from file-- for test
				// let sectionedJson = subDocumentFromFile;
				// localforage.setItem(`subdocumentjson_${docId}`, sectionedJson);
				// processAnnotatedText(sectionedJson);
				// setIsLoading(false);


				//-- Actual implementation
					getHistoryData(docId).then((sectionedJson) => {
					localforage.setItem(`subdocumentjson_${docId}`, sectionedJson);
					processAnnotatedText(sectionedJson);
					setIsLoading(false);
				});
			}
		}).catch((err)=>{
			console.log('error',err);
		});
		
		
	};

	const processAnnotatedText = (sectionedJson) => {
		setSectionedJson(sectionedJson);
		createAutomatedAnnotation(sectionedJson);
		setSectionedText('');
		getSectionText(sectionedJson).then((sectionedText) => {
			setSectionedText(sectionedText || '');
		});
	};

	useEffect(() => {
		if (sectionedText) {
			setFetchingEncounterText(false);
			setIsLoading(false);
		}
	}, [sectionedText]);

	const createAutomatedAnnotation = (sections) => {
		let arrEntities = [];
		sections.map((section) => {
			let section_number = section.section_id;
			section.entities &&
				section.entities.map((items) => {
					items.section_number = section_number;
					arrEntities.push(items);
				});
		});
		setAutomatedAnnotationList(arrEntities);
	};

	const refreshSectionText = (value) => {
		// setIsLoading(true);
		setShowRapidManualPopup(false);
		setSetEditM_Annotation(false);
		getManualAnnuatationList();
	};
	const openManualAnnotationPop = (annotation) => {
		setSelectedAnnotation(annotation);
		setShowRapidManualPopup(false);
	};
	const refreshSelectedAnnotation = () => {
		setSelectedAnnotation(null);
	};
	const reopenManualPopup = () => {
		setShowRapidManualPopup(true);
		// $('html, body').animate({ scrollTop: 0 }, 2000);
		if (window && window.scroll) {
			window.scroll({ top: 0, behavior: 'smooth' });
		}
	};
	// ununsed functions
	// const setRapidView = (value) => {
	// 	setShowRapidManualPopup(value);
	// };
	// ununsed functions		
	// const updateEditableState = (state) => {
	// 	setRapidManualAnnotation([]);
	// 	if (editM_Annotation) {
	// 		setEditM_Annotation(state);
	// 		document.body.click();
	// 		setShowRapidManualPopup(true);
	// 	}
	// 	if (getObjectFromLocal('editmodeNormal') || getObjectFromLocal('editmodeDocument')) {
	// 		// props.history.push()
	// 		props.history.push(`${constants.ROUTE_URL}/dashboard/datarapid/${docId}`);
	// 	}
	// };

	const onMenuClick = (eventId) => {
		if (relatedEncounter?.length == 1) {
			props.history.push(
				`${constants.ROUTE_URL}/dashboard/document/${relatedEncounter[0].subdocument_id}`
			);
		} else {
			setShowRelatedEncounter(true);
		}
	};

	const cancelClick = () => {
		setShowRelatedEncounter(false);
	};

	const switchToEditMode = () => {
		// setIsLoading(true);
		setEditM_Annotation(true);
		setNewRapid(getObjectFromLocal(`selectedConcept_${docId}`));
		setToggleRapidAnnotationPopup(false);
		setOpenMessageLevelPopup(true);
		getManualAnnuatationList();
	};

	const switchToEditMultipleManual = () => {
		setObjectToLocal(`fromEditMultipleManual_${docId}`, true);
		removeItemFromLocal(`encounterAnnotator_${docId}`);
		setIsLoading(true);
		setEditMultipleManual(true);
		getManualAnnuatationList();
		setSelectedAnnotator({});
	};

	const switchToViewMode = () => {
		// setIsLoading(true);
		removeItemFromLocal(`selectedConcept_${docId}`);
		removeItemFromLocal(`editmodeNormal_${docId}`);
		setEditM_Annotation(false);
		setOpenMessageLevelPopup(false);
		setEditMultipleManual(false);
		let annotator = getObjectFromLocal(`encounterAnnotator_${docId}`);
		if(annotator){
			setSelectedAnnotator(annotator)
		} else {
			setSelectedAnnotator(null)
		}
		getManualAnnuatationList();
	};

	const exitMessagePopup = () => {
		setOpenDocumentLevelPopup(false);
	};

	const onToggleRapidAnnotationPopup = (obj) => {
		setToggleRapidAnnotationPopup(!toggleRapidAnnotationPopup);
		setHighlightManualAnnotation(false)
	};

	const fetchRapidManualAnnotation = () => {
		setIsLoading(true);
		let currentRole = props.redSidenav?.userRole?.Role;
		let annotator = getObjectFromLocal(`encounterAnnotator_${docId}`);
		let annotatorId;
	    if(currentRole === roleAccess.SUPERVISOR && annotator && annotator.value){
			annotatorId = annotator.value;
		}
		getRapidManualAnnotationData(docId, annotatorId).then(
			() => {
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
			}
		);
	};

	const reloadPageData = (getNewManualAnnotation) => {
		// setIsLoading(true);
		if (getNewManualAnnotation) {
			setEditM_Annotation(false);
			setOpenMessageLevelPopup(false);
		}
		getManualAnnuatationList(getNewManualAnnotation);
	};

	const closeMessageLevelPopup = () => {
		setOpenMessageLevelPopup(false);
	};

	const handleSettingOptionClick = () => {
		const popover = document.querySelector('#popover-contained');
		if (popover) popover.style.display = 'none';
		closeMessageLevelPopup();
	};

	const openDocumentLevelPopupRoot = () => {
		setNewRapid(getObjectFromLocal(`selectedConcept_${docId}`));
		setOpenDocumentLevelPopup(true);
		setToggleRapidAnnotationPopup(false);
	};

	const cancelUpdate = (url) => {
		removeItemFromLocal(`newRapidAnnotation_${docId}`);
		removeItemFromLocal(`updatedAnnotation_${docId}`);
		removeItemFromLocal(`deletedAnnotation_${docId}`);
		removeItemFromLocal(`selectedConcept_${docId}`);
		removeItemFromLocal(`editmodeNormal_${docId}`);
		removeItemFromLocal(`fromEditMultipleManual_${docId}`);
		reloadPageData(true);
		if (url) {
			setTimeout(() => {
				props.history.push(url);
			}, 1000);
		}
	};

	const toggleHighlighManualAnnotatedText = () =>{

		let existingManualAnnotation = manualAnnotatioDetailList.filter((items)=> items.section_number != -1);
		let currentRole = props.redSidenav?.userRole?.Role
	    if(currentRole === roleAccess.ANNOTATOR){
			setIsLoading(true);
			let annotatorAnnotation = existingManualAnnotation.filter((items)=>{ return items.user_id == props.redUsermanagement?.loggedInUser?.user_id});
			if(!highlightManualAnnotation){
				
				annotatorAnnotation.map((manualAnnotation)=>{
					let startPoint = parseInt(manualAnnotation['begin_offset']);
					let endPoint = parseInt(manualAnnotation['end_offset']);
					let sectionId = manualAnnotation.section_number;
					for (var i = startPoint; i <= endPoint; i++) {
						$(`span[ost=${i}][sid=${sectionId}]`).addClass('highlighted2');
					}
				})
			} else {
				$('span').removeClass('highlighted2');
			}
			setHighlightManualAnnotation(!highlightManualAnnotation)
			setIsLoading(false);
		} else if(currentRole === roleAccess.SUPERVISOR){
			setIsLoading(true);
			getUserManualAnnotationsWithoutFlag(docId).then((annotation)=>{
				let annotationIds = annotation
					.map((annotate) => {
						return annotate.annotation_details.map((detail) => {
							return detail.annotation_id;
						});
					})
					.flat()
				// console.log('annotationIds', annotationIds);
				let nonConflictAnnotation = existingManualAnnotation.filter((items)=>{
					if(annotationIds.indexOf(items.annotation_id) == -1){
						return items
					}
				})
				if(!highlightManualAnnotation){
					nonConflictAnnotation.map((manualAnnotation)=>{
						let startPoint = parseInt(manualAnnotation['begin_offset']);
						let endPoint = parseInt(manualAnnotation['end_offset']);
						let sectionId = manualAnnotation.section_number;
						for (var i = startPoint; i <= endPoint; i++) {
							$(`span[ost=${i}][sid=${sectionId}]`).addClass('highlighted2');
						}
					})
				} else {
					$('span').removeClass('highlighted2');
				}
				setIsLoading(false);
				setHighlightManualAnnotation(!highlightManualAnnotation)
				// console.log('nonConflictAnnotation', nonConflictAnnotation);
			})

		} else {
			setIsLoading(true);
			if(!highlightManualAnnotation){
				existingManualAnnotation.map((manualAnnotation)=>{
					let startPoint = parseInt(manualAnnotation['begin_offset']);
					let endPoint = parseInt(manualAnnotation['end_offset']);
					let sectionId = manualAnnotation.section_number;
					for (var i = startPoint; i <= endPoint; i++) {
						$(`span[ost=${i}][sid=${sectionId}]`).addClass('highlighted2');
					}
				})
			} else {
				$('span').removeClass('highlighted2');
			}
			setHighlightManualAnnotation(!highlightManualAnnotation);
			setIsLoading(false);
		}
		
	}

	const highlighManualCallback = () => {
		const isHighlighted = getObjectFromLocal(`highlightAnnotation_${docId}`);
		if (isHighlighted) {
			toggleHighlighManualAnnotatedText();
		}
	};

	const nextEncounter = () => {
		let encounterIndex = annotatorEncounters.indexOf(parseInt(docId));
		let nextEncounter;
		if(encounterIndex ==-1){
			return;
		}
		if(encounterIndex + 1 == annotatorEncounters.length){
			nextEncounter = annotatorEncounters[0];
		} else {
			nextEncounter = annotatorEncounters[encounterIndex + 1 ];
		}
		props.history.push(constants.ROUTE_URL + '/dashboard/document/' + nextEncounter);
		window.location.reload();
	}

	return (
		<div className={`${editM_Annotation ? 'editable' : ''}`}>
			{fetchingEncounterText || props?.loading?.loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			{/* {props.loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)} */}

			{doc_name && (
				<ErrorBoundary>
					<HeaderTitles
						data={headerData}
						search={false}
						history={props.history}
						match={props.match}
						doc_type={doc_type}
						onMenuClick={onMenuClick}
						relatedEncounter={relatedEncounter}
						showRapid={true}
						switchToEditMode={switchToEditMode}
						toggleRapidAnnotationPopup={toggleRapidAnnotationPopup}
						onToggleRapidAnnotationPopup={onToggleRapidAnnotationPopup}
						reloadPageData={reloadPageData}
						switchToEditMultipleManual={switchToEditMultipleManual}
						handleSettingOptionClick={handleSettingOptionClick}
						closeMessageLevelPopup={closeMessageLevelPopup}
						showRapidBadgeIcon={showRapidBadgeIcon}
						openDocumentLevelPopupRoot={openDocumentLevelPopupRoot}
						confirmPopup={confirmPopup.current}
						fetchRapidManualAnnotation={fetchRapidManualAnnotation}
						highlightManualAnnotation = {highlightManualAnnotation}
						toggleHighlighManualAnnotatedText = {toggleHighlighManualAnnotatedText}
						nextEncounter = {nextEncounter}
						switchToViewMode={switchToViewMode}
						selectedAnnotator={selectedAnnotator}
					/>
				</ErrorBoundary>
			)}

			<ErrorBoundary>
				<>
					{doc_name && (
						<Suspense fallback={<div className="defaultSpinner" />}>
							<DocumentHeader
								// m_annotationList = {manualAnnotationList}
								automatedAnnotationList={automatedAnnotationList}
								// documentConceptId={documentConceptId}
								doc_id={docId}
								getEditMode={editMode}
								patientConcepts={patientConcepts}
								// manualAnnotationPopupRef={manualAnnotationPopupReffunction}
								getDocumentAnnotationList={getDocumentAnnotationList}
								editM_Annotation={editM_Annotation}
								newRapid={newRapid}
								history={props.history}
								match={props.match}
								doc_name={doc_name}
								doc_type={doc_type}
								openMessageLevelPopup={openMessageLevelPopup}
								editMultipleManual={editMultipleManual}
								exitMessagePopup={exitMessagePopup}
								onToggleRapidAnnotationPopup={onToggleRapidAnnotationPopup}
								switchToViewMode={switchToViewMode}
								openDocumentLevelPopup={openDocumentLevelPopup}
								selectedAnnotator = {selectedAnnotator}
							/>
						</Suspense>
					)}
				</>
			</ErrorBoundary>
			{sectionedText && !fetchingManualannotation && doc_type != 'discrete' && (
				<ErrorBoundary>
					<Suspense fallback={<div className="defaultSpinner" />}>
						<SectionalText
							patientConcepts={patientConcepts}
							conceptCohorts={props?.redCohort?.conceptCohorts}
							sectionedText={sectionedText}
							manualAnnotationList={manualAnnotationList}
							manualAnnotatioDetailList={manualAnnotatioDetailList}
							// updateEditableState={updateEditableState}
							editM_Annotation={editM_Annotation}
							newRapid={newRapid}
							doc_id={docId}
							annotationid={annotationid}
							reopenManualPopup={reopenManualPopup}
							selectedAnnotation={selectedAnnotation}
							refreshSelectedAnnotation={refreshSelectedAnnotation}
							refreshSectionText={refreshSectionText}
							openManualAnnotationPop={openManualAnnotationPop}
							history={props.history}
							match={props.match}
							descriptorOptions={descriptorOptions}
							progressionOptions={progressionOptions}
							severityOptions={severityOptions}
							sectionedJson={sectionedJson}
							numericValueUnitOptions={numericValueUnitOptions}
							medicationUnitOptions={medicationUnitOptions}
							medicationFormOptions={medicationFormOptions}
							medicationRoutesOptions={medicationRoutesOptions}
							conceptCategoriesOptions={conceptCategoriesOptions}
							onToggleRapidAnnotationPopup={onToggleRapidAnnotationPopup}
							switchToViewMode={switchToViewMode}
							exitMessagePopup={exitMessagePopup}
							reloadPageData={reloadPageData}
							notExperiencedReasons={notExperiencedReasons}
							notDefinitiveDetails={notDefinitiveDetails}
							highlightManualAnnotation = {highlightManualAnnotation}
							highlighManualCallback={highlighManualCallback}
						/>{' '}
					</Suspense>
				</ErrorBoundary>
			)}
			{doc_type == 'discrete' && (
				<div>
					<div className="sub-header">{convertToPlural(doc_name.split(' ')[0])}</div>
					<div className="col-sm-12 historyData" id="dataDetailsTextDiv">
						{ReactHtmlParser(
							sectionedJson
								.replace(/\r\n/g, '<br/>')
								.replace(/\r/g, '<br />')
								.replace(/\n/g, '<br />')
								.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
						)}
					</div>
				</div>
			)}
			{
				<ModalComponent
					open={showRelatedEncounter}
					backdrop={true}
					header={'Encounter list'}
					content={
						<div className="jconfirm-box-container select-study-popup">
							<ul style={{ marginTop: '10px' }}>
								{relatedEncounter?.length > 0 &&
									relatedEncounter.map((items, index) => {
										return (
											<li
												key={`relatedencounter_${index}`}
												className="alt-bg"
												style={{ textAlign: 'left', padding: '8px' }}
											>
												<Link
													to={
														`${constants.ROUTE_URL 
														}/dashboard/document/${ 
														items.subdocument_id}`
													}
												>
													{items.subdocument_name}
												</Link>
											</li>
										);
									})}
							</ul>

							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										type="button"
										className="btn btn-default"
										onClick={cancelClick}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					}
					className={''}
					handleClose={() => {}}
				/>
			}
			<Copyright />
			<CancelRapidManual
				cancelRapidUpdate={cancelUpdate}
				open={(c) => (confirmPopup.current = c)}
				revertRapidChanges={() => {}}
			/>
		</div>
	);
};

DocumentContainer.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
	redCohort: PropTypes.shape({
		conceptCohorts: PropTypes.any,
		documentCohorts: PropTypes.arrayOf(PropTypes.shape({
			map: PropTypes.func,
		})),
	}),
	redDocument: PropTypes.shape({
		conceptCategoriesOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		descriptorOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		medicationFormOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		medicationRoutesOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		medicationUnitOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		notDefinitiveDetails: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		notExperiencedReasons: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		numericValueUnitOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		progressionOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
		severityOptions: PropTypes.arrayOf(PropTypes.shape({
			length: PropTypes.number,
		})),
	}),
	redUsermanagement: PropTypes.shape({
		loggedInUser: PropTypes.shape({
			user_id: PropTypes.any,
		}),
	}),
	showEncounter: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DocumentContainer);
