import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';

import dataIcon from '../../images/data_icon.png';
import {
	setHistoryAction
} from './DataFilterHelper';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import localforage from 'localforage';
import { setIsLoading } from '../../actions/common';
import actions from '../../actions';
import constants from '../../constants';
import { setObjectToLocal, getObjectFromLocal } from '../../utilities/utils';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';

const headerData = {
	title: 'Data',
	searchTitle: 'data',
	list: 'list',
	img: (
		<div className="data_icon">
			<img src={dataIcon} alt="Data List" style={{ width: 20 }} />
		</div>
	),
	lastColumn: 'Patients',
};

function DataFilter(props) {

	const [showError, setShowError] = useState(false);
	const [batchOptions, setBatchOptions] = useState([]);
	const [batchIds, setBatchIds] = useState([]);
	const [disableOkFlag,setDisableOkFlag]=useState(true);
	const [trainingBatchIds, setTrainingBatchIds] = useState([]);
	const [validationBatchIds, setValidationBatchIds] = useState([]);

	useEffect(() => {
		let study_name = props.redStudy?.getstudylist[0]?.study_name;
		let validStatusOption = [
			'Manual annotation project created',
			'Reprocessing completed',
			'Reprocessing failed'
		];
		
		if(study_name){
			props.dispatch(actions.getStudyInformation(study_name)).then((resp) => {
				let trainingData = resp.training || [];
				let validationData = resp.validation || [];
				let batchOption = [...batchOptions];
				let trainingBatchId = [...trainingBatchIds]
				let validationBatchId = [...validationBatchIds]
				let trainingAnnotationGenerated =
					trainingData.filter((item) => {
						return item.active == 'Yes' && item.status && validStatusOption.indexOf(item.status) != -1;
					});
				let validationAnnotationGenerated =
					validationData.filter((item) => {
						return item.active == 'Yes' && item.status && validStatusOption.indexOf(item.status) != -1;
					});
				if (
					trainingData.length > 0 &&
					trainingAnnotationGenerated.length > 0
				) {
					batchOption.push({ value: 'Training', label: 'Training' });
					setDisableOkFlag(false);
				}
				if (
					validationData.length > 0 &&
					validationAnnotationGenerated.length > 0
				) {
					batchOption.push({ value: 'Validation', label: 'Validation' });
					setDisableOkFlag(false);
				}
				setBatchOptions(batchOption);
				if (trainingAnnotationGenerated.length > 0) {
					trainingAnnotationGenerated.forEach((item) => {
						trainingBatchId.push({ value: item?.batch_name.charAt(0).toUpperCase()+item?.batch_name.slice(1), label: item?.batch_name.charAt(0).toUpperCase()+item?.batch_name.slice(1) })
					})
				}
				setTrainingBatchIds(trainingBatchId);
				if (validationAnnotationGenerated.length > 0) {
					validationAnnotationGenerated.forEach((item) => {
						validationBatchId.push({ value: item?.batch_name.charAt(0).toUpperCase()+item?.batch_name.slice(1), label: item?.batch_name.charAt(0).toUpperCase()+item?.batch_name.slice(1) })
					})
				}
				setValidationBatchIds(validationBatchId);
			});
			setIsLoading(false);
		}
		
	}, [props.redStudy]);

	
	const [selectedBatchId, setSelectedBatchId] = useState(getObjectFromLocal('selectedBatchId'));
	const [selectedBatch, setSelectedBatch] = useState(getObjectFromLocal('selectedBatch'));
	/****************************************
	 * Function Name: batchSelection
	 * Description: This function sets the state with selected value.
	 * Method has one signatures.
     * @param - batch - The value of the selected batch option.
	****************************************/
	const batchSelection = (batch) => {
		if(batch.value =='Validation')
		{
			setBatchIds(validationBatchIds);
		}
		if(batch.value =='Training')
		{
			setBatchIds(trainingBatchIds);
		}
		setSelectedBatchId(null);
		setSelectedBatch(batch);
	};

	/****************************************
	 * Function Name: batchIdSelection
	 * Description: This function sets the state with selected value values.
	 * Method has one signatures.
     * @param - batchId - The values of the selected batch Ids.
	****************************************/
	const batchIdSelection = (batchId) =>{
		setSelectedBatchId(batchId);
	}
	
	/****************************************
	 * Function Name: saveStudyFilter
	 * Description: This function save the filter values selected by user in session and set filter and history.
	 * Method has no signatures.
	 ****************************************/

	const saveDataFilter = () => {

		if (selectedBatchId == null) {
			setShowError(true);
		} else {
			setShowError(false);
			setObjectToLocal('selectedBatch', selectedBatch)
			setObjectToLocal('selectedBatchId', selectedBatchId)

			let filterLabel = {
				page: 'study',
				options: [
					{
						filterLabel: 'Batch',
						name: selectedBatchId?.value,
					},
				],
			};
	
			setFilterLabel(filterLabel);
			setHistoryAction({
				...window.location,
				display_name: `Data list`,
			});
			props.history.push(
					`${constants.ROUTE_URL}/dashboard/dataworkqueue`
				);
		}
		
	};

	const setFilter = (options) => {
		let filterLabel = {
			page: 'datalist',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	return (
		<div>
			{/* Using the Reusable Component HeaderTitles to show the
				 Component Heading */}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					route={props.route}
					search={false}
					noMenu={true}
					{...props}
				/>
			</ErrorBoundary>
			<div className="edit-study-validity-option">
					<div className="txt">Batch:</div>
					<div className="drpdwn">
						<CustomSelect
							name="rows"
							id="rows"
							value={selectedBatch}
							onChange={batchSelection}
							ignoreCase={false}
							autoload={false}
							placeholder="Select Batch"
							clearable={false}
							searchable={false}
							options={batchOptions}
						/>
					</div>
				</div>
				<div className="edit-study-validity-option">
					<div className="txt">Batch Id:</div>
					<div className="drpdwn">
						<CustomSelect
							name="batchId"
							id="batchId"
							value={selectedBatchId}
							onChange={batchIdSelection}
							ignoreCase={false}
							autoload={false}
							placeholder="Select Batch Id"
							clearable={false}
							searchable={false}
							options={batchIds}
						/>
					</div>
				</div>
			<div className="edit-study-validity-option">
				<div className="txt"></div>
				{showError && <div className="drpdwn txt">** All fields are mandatory</div>}
			</div>
			<div className="row footer">
				<div className="offset-md-6 col-md-6 text-right">
					<button type="button" disabled={disableOkFlag} className="btn-action" onClick={saveDataFilter}>
						Ok
					</button>
				</div>
			</div>
		</div>
	);
}

export default connect((state) => state)(DataFilter);
