/* istanbul ignore file */
import actionTypes from '../actions/actionTypes';

let initialState = {
	type: ''
}

export const modalReducer = (state = initialState, action) => {

	switch(action.type) {
	
		case actionTypes.OPEN_MODAL_POPUP : {
			return {...state, type: action.payload}
		}

		default : return state;
	}

}