import PropTypes from 'prop-types';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import queryString from 'query-string';
import { setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HeaderAccordion from '../../../components/HeaderAccordion/HeaderAccordion';
import { setIsLoading } from '../../../actions/common';
import {
	getPatientList,
	GetPatientManualAnnotationsWithConflict,
	getStudyDetail,
	getSubdocumentById,
	getUserDetails,
	searchConceptsById,
	getDerivedManualAnnotations,
	getDescriptorValues,
	getSeverityValues,
	getProgressionValues,
	getManualAnnotationProjectAttributes,
} from './DataPLAReviewHelper';
import { Link } from 'react-router-dom';
import { roleAccess } from '../../../utilities/AppConstants';

import PaginationComponent from '../../../common/Pagination/PaginationComponent';

const TextLevelAnnotation = lazy(() =>
	import(
		/* webpackChunkName: "TextLevelAnnotation" */ '../../../components/TextLevelAnnotation/TextLevelAnnotation'
	)
);
const PatientLevelConflicts = lazy(() =>
	import(
		/* webpackChunkName: "PatientLevelConflicts" */ '../../../components/PatientLevelConflicts/PatientLevelConflicts'
	)
);
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
	// img: <img src={constants.IMG_URL+'data_icon.png'} className={'App-DataListImage'} alt={'Data List'} style={{width: '20px', marginRight: '5px'}}/>
};

function DataPLAReview(props) {
	const [doc_name, setDoc_name] = useState('');
	const [study_name, setStudy_name] = useState('');
	const [conceptName, setConceptName] = useState('');
	const [PatientLevelAnnotation, setPatientLevelAnnotation] = useState([]);
	const [patientId, setPatientId] = useState('');
	const [patientIdentifier, setPatientIdentifier] = useState({});
	const [patientInfo, setPatientInfo] = useState('');
	const [pageNo, setPageNo] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [totalPatientCount, setTotalPatientCount] = useState(0);
	const [patient_id, setPatient_id] = useState('');
	const [annotationAttribute, setAnnotationAttribute] = useState('');
	const [annotationProps, setAnnotationProps] = useState(null);

	useEffect(() => {
		let { docId } = props.match.params;
		let study_id = queryString.parse(props?.location?.search).study_id;
		let concept_id = queryString.parse(props?.location?.search).concept_id;
		setIsLoading(true);

		getUserDetails().then((res) => {});
		getSubdocumentById(docId).then((resp) => {
			setPatientId(resp.data.data.patient_id);

			getPatientList(resp.data.data.patient_id).then((res) => {
				setIsLoading(false);
				setPatientInfo(res.data.data[0]);
				setPatient_id(resp.data.data.patient_id);
				GetPatientManualAnnotationsWithConflicts(resp.data.data.patient_id, 1, 10);
			});
		});
		if (study_id) {
			getStudyDetail(study_id).then((studyDetail) => {
				setStudy_name(studyDetail.study_name);
				if (concept_id) {
					searchConceptsById(concept_id).then((resp) => {
						if (resp) {
							setConceptName(resp[0].text);
						}
						if (concept_id && study_id) {
							let filterLabel = {
								page: 'datastudyreview',
								options: [
									{
										filterLabel: 'Study',
										name: studyDetail.study_name,
										fixed: true,
									},
									{
										filterLabel: 'Concept',
										name: conceptName,
									},
								],
							};
							setFilterLabel(filterLabel);
						}
					});
				} else {
					let filterLabel = {
						page: 'datastudyreview',
						options: [
							{
								filterLabel: 'Study',
								name: studyDetail.study_name,
								fixed: true,
							},
						],
					};
					setFilterLabel(filterLabel);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (props?.redDocument?.descriptorOptions?.length == 0) {
			getDescriptorValues();
		}
		if (props?.redDocument?.progressionOptions?.length == 0) {
			getSeverityValues();
		}
		if (props?.redDocument?.severityOptions?.length == 0) {
			getProgressionValues();
		}
		setIsLoading(true);
		getManualAnnotationProjectAttributes().then((resp) => {
			setAnnotationAttribute(resp?.data?.data?.RequiredAnnotationAttribute || '');
			setIsLoading(false);
		});
		getDerivedManualAnnotations(props.derivedAnnotationId, patientId, 'Outcome').then(
			(resp) => {
				setAnnotationProps(resp.data.data[0] || []);
			}
		);
	}, []);

	const GetPatientManualAnnotationsWithConflicts = (patient_id, pageNo, recordsPerPage) => {
		setIsLoading(true);
		GetPatientManualAnnotationsWithConflict(patient_id, pageNo, recordsPerPage).then((res) => {
			setIsLoading(false);
			if (pageNo == 1 && res?.data?.payload) {
				setPatientLevelAnnotation(res.data?.payload[0]?.PatientAnnotationDetail);
				setTotalPatientCount(
					res.data.totalRecords
						? res.data.totalRecords
						: res.data?.payload[0]?.PatientAnnotationDetail.length
				);
			} else {
				if (res?.data?.payload) {
					setPatientLevelAnnotation(res.data?.payload[0]?.PatientAnnotationDetail);
				}
			}
		});
	};

	/****************************************
	 * Function Name: gotoPage
	 * Description: Switches to different pages from pagination.
	 * @param pageNo Page number
	 ****************************************/
	const gotoPage = (pageNo, recordsPerPage) => {
		setPageNo(pageNo);
		setRecordsPerPage(recordsPerPage);
		GetPatientManualAnnotationsWithConflicts(patient_id, pageNo, recordsPerPage);
	};

	const gotoPatientDetail = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/${patientId}`);
	};

	let { loading } = props.loading;
	let concept_id = queryString.parse(props?.location?.search).concept_id;

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<div className="document-header-container">
					<span> Manual annotation conflicts: </span>
					{props.redSidenav?.userRoles?.Role == roleAccess.ANNOTATOR ? (
					<span className={''}>{patientInfo.patient_Identifier}</span>
					) : (
						<Link
							to={constants.ROUTE_URL + '/dashboard/patient/' + patientId}
							onClick={gotoPatientDetail}
						>
							{patientInfo.patient_Identifier}
						</Link>
					)}
				</div>
			</ErrorBoundary>
			<ErrorBoundary>
				{PatientLevelAnnotation?.map((concept, conceptIndex) => {
					return (
						<HeaderAccordion
							type={'header'}
							identifier={concept.ConceptID + conceptIndex}
							referenceId={concept.ConceptID}
							text={concept.ConceptName}
							leftPad={20}
							isOpen={true}
						>
							{concept.TextAnnotation && concept.TextAnnotation.length > 0 && (
								<Suspense fallback={<div className="defaultSpinner" />}>
									<TextLevelAnnotation
										concept={concept}
										annotations={concept.TextAnnotation}
										identifier={concept.ConceptID}
									></TextLevelAnnotation>
								</Suspense>
							)}
							{concept.PatientlevelAnnotation &&
								concept.PatientlevelAnnotation.length > 0 && (
									<Suspense fallback={<div className="defaultSpinner" />}>
										<PatientLevelConflicts
											concept={concept}
											identifier={concept.ConceptID}
											annotations={concept.PatientlevelAnnotation}
											requiredAnnotationAttribute={annotationAttribute}
											listAnnoation={annotationProps}
										></PatientLevelConflicts>
									</Suspense>
								)}
						</HeaderAccordion>
					);
				})}
			</ErrorBoundary>
			{totalPatientCount > 0 && (
				<PaginationComponent
					totalItemsCount={totalPatientCount}
					gotoPage={(pageNo, recordsPerPage) => gotoPage(pageNo, recordsPerPage)}
					currentPage={pageNo}
				/>
			)}
			{/* <SubAccordion subdata={concept.PatientAnnotationDetail}/> */}
		</div>
	);
}

DataPLAReview.propTypes = {
	derivedAnnotationId: PropTypes.any,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
	redDocument: PropTypes.shape({
		descriptorOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		progressionOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		severityOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataPLAReview);
