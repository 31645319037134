/* eslint-disable */
/* istanbul ignore file */
import { apiGet, getRoutePath, sortByDate, setQueryParams } from '../../utilities/utils';
import { setFilterLabel, setHistory } from '../../common/FilterFunctions/FilterFunctions';
import constants from '../../constants';
import store from '../../store';
import { getCohortList, cohortEdited } from './cohortActions';
import history from '../../common/CreateBrowserHistory/CreateBrowserHistory';
import actions from '../../actions';

/**************************************** 
     * Function Name: getQueryParamObject
     * Description: This method determines query parameter object.
     * @param - query - Parameter from url.
	****************************************/
export const getQueryParamObject = (query) => {

    if (!query) {
        return {}
    }

    if (query.document_list && query.patient_id && query.organization_id) {
        return { m_filter: true, m_DocIdList: query.document_list, m_organizationId: query.organization_id, m_PatientIdList: query.patient_id }
    } else if (query.organization_id && query.patient_id) {
        return { m_filter: true, m_organizationId: query.organization_id, m_PatientIdList: query.patient_id }
    } else if (query.organization_id && query.study_id) {
        return { m_filter: true, m_organizationId: query.organization_id , m_StudyIdList : query.study_id }
    } else if (query.organization_id ) {
        return { m_filter: true, m_organizationId: query.organization_id }
    }else if (query.patient_id) {
        return { m_filter: true, m_PatientIdList: query.patient_id }
    } else if (query.study_id) {
        return { m_filter: true, m_StudyIdList: query.study_id }
    } else if (query.cohort_id) { //THIS CONDITION IS FOR STUDY LIST PAGE
        return { m_filter: true, m_cohortIdList: query.cohort_id };
    } else if (query.filter && query.studyId) {
        return { m_filter: true, m_StudyIdList: query.studyId };
    }else if (query.filter) {
        return { m_filter: true };
    }

    return {}
}

/**************************************** 
    * Function Name: getCohortById
    * Description: Returns a cohort object by id. First it checks in redux store, 
    if not found then fires API call, and then find requested cohort by id given.
    @param cohortId Cohort id
****************************************/
export const getCohortById = (cohortId) => {
    return new Promise((resolve, reject) => {
        //CHECK IF COHORT PRESENT IN REDUX, ALSO CHECK FOR REQUIRED COHORT
        const cohortInStore = store.getState().redCohort?.cohortList;
        const isCohortEdited = store.getState().redCohort?.isCohortEdited;
        if (!isCohortEdited && cohortInStore.length && cohortInStore.findIndex(cohort => cohort.cohort_id === cohortId) !== -1) {
            const cohort = cohortInStore.find(cohort => cohort.cohort_id === cohortId);
            if (cohort) {
                resolve(cohort);
            } else {
                reject();
            }
        } else {
            //FETCH ALL COHORTS, STORE IN REDUX, PROVIDE REQUESTED COHORT BY ID
            store.dispatch(actions.getCohortList({})).then(res => {
                if(isCohortEdited) {
                    store.dispatch(cohortEdited(false))
                }
                const cohorts = res.data.data;
                const cohort = cohorts.find(cohort => cohort.cohort_id === cohortId);
                resolve(cohort);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        }
    });
}

/**************************************** 
    * Function Name: getManAnnotationIcon
    * Description: Fetch manual annotation information of cohorts, return existing cohort list 
    * with hasManAnnotation flag property to each object in list.
    * @param cohortList - Existing cohort list from state.
    * @param cohortIds - Comma seperated list of cohort ids. 
    ****************************************/
export const getManAnnotationIcon = (cohortList, cohortIds) => {
    return new Promise((resolve, reject) => {
        const promiseArr = [];

        cohortIds.forEach(m_CohortId => {
            promiseArr.push(apiGet('GetCohortManualAnnotations',
                { m_CohortId, m_DocLevelManAnnotation: false, pageNumber: 1, pageSize: 10 }));
        })

        Promise.all(promiseArr).then(res => {
            //MAP WITH COHORT LIST IN STATE
           
            cohortList.forEach((cohort, index) => {
                cohort.hasManAnnotation = res[index].data?.data?.length > 0;
            })
            resolve(cohortList);
        })
    });
}

/**************************************** 
    * Function Name: getCohortCounts
    * Description: Returns study, patient and data counts for each cohort.
    * @param cohortList Existing cohort list from state
    * @param cohortIds - Comma seperated list of cohort ids
    ****************************************/
export const getCohortCounts = (cohortList, cohortIds) => {
    return new Promise((resolve, reject) => {
        const promiseArr = [];

        promiseArr.push(apiGet('GetStudyCounts', { cohortIds }));
        promiseArr.push(apiGet('GetPatientCounts', { cohortIds }));
        promiseArr.push(apiGet('GetDataCounts', { cohortIds }));

        Promise.all(promiseArr).then(res => {
            cohortList.forEach((cohort, index) => {
                const result1 = res[0].data.data.cohort.find(res1 => res1.cohort_id === cohort.cohort_id);
                cohort.counts.study_count = result1 && result1.study_count || 0;

                const result2 = res[1].data.data.cohort.find(res2 => res2.cohort_id === cohort.cohort_id);
                cohort.counts.patient_count = result2 && result2.patient_count || 0;

                const result3 = res[2].data.data.cohort.find(res3 => res3.cohort_id === cohort.cohort_id);
                cohort.counts.data_count = result3 && result3.data_count || 0;
            })
            resolve(cohortList);
        }).catch(err => {
            console.error(err);
            reject();
        })
    });
}

/* *************************************** 
    * Function Name: gotoPage
    * Description: Navigate to requested page with url parameter cohort id
    * @param page - Target page
    * @param cohort_id Cohort id
    * @param cohortName Name of cohort
    ****************************************/
// eslint-disable-next-line camelcase
export const gotoPage = (page, cohort_id, cohortName) => {
    let filterLabel ={
        page: page,
        options:[
            {
                filterLabel:'Cohort',
                name:cohortName
            }
        ]
    }

    setFilterLabel(filterLabel);

    setHistory();

    const path = getRoutePath(page);

     setQueryParams(
        { pathname: path, query: { cohort_id } }
    )
}

/* *************************************** 
    * Function Name: gotoDataDetail
    * Description: Navigate to data detail page.
    * @param document_id Document id
    * @param concept_id Concept id
    * @param annotation_id Annotation id
    ****************************************/
export const gotoDataDetail = (documentId, concept_id, annotationId) => {
     setQueryParams({
        pathname: constants.ROUTE_URL + '/dashboard/document/' + documentId,
        query: {
            concept_id,
            // route_doc_status: annotationId ? 'true' : '',
            route_annotation_id: annotationId || '',
            route_from: 'cohort',
            route_doc_status: 'manual'
        }
    });
}

/* *************************************** 
	 * Function Name: sortCriteria
	 * Description: This method sort criteria in following order
	 * 		index event, inclusion, exclusion
	 * @param - criteria - array of object as criteria
	****************************************/
export const sortCriteria = criteria => {
    const indexEvent = [];
    const inclusions = [];
    const exclusions = [];
    // SEPARATE OUT ALL CRITERIA.
    criteria.map(criterion => {
        if (criterion.is_index_event === 'true') {
            indexEvent.push(criterion);
        } else if (criterion.is_inclusion === 'true') {
            inclusions.push(criterion)
        } else {
            exclusions.push(criterion)
        }
    });

    // FURTHER SORT BY DATE EVENT.
    const sortedIndexEvent = sortByDate(indexEvent, 'criterion_absolute_start_date');
    const sortedInclusions = sortByDate(inclusions, 'criterion_absolute_start_date');
    const sortedExclusions = sortByDate(exclusions, 'criterion_absolute_start_date');

    // MERGE AND RETURN ALL CRITERIA
    return [...sortedIndexEvent, ...sortedInclusions, ...sortedExclusions]
}

/***************************************
    * Function Name: getCriterionType
    * Description: Determine and return type of criterion.
    * @param criterion Criterion object
    **************************************/
export const getCriterionType = (criterion) => {
    if (criterion.is_index_event === 'true') {
        return 'Index event:';
    } else if (criterion.is_inclusion === 'true') {
        return 'Inclusion criterion:';
    } else {
        return 'Exclusion criterion:';
    }
}

/***************************************
* Function Name: gotoCohortList
* Description: Navigate to cohort list
* Method has no parameter
**************************************/
export const gotoCohortList = () => {
    history.push(constants.ROUTE_URL + "/dashboard/cohortlist");
}

export const getCohortListByParams = (queryparams) =>{
    return new Promise((resolve, reject) => {
        store.dispatch(actions.getCohortList(queryparams)).then((data) => {
            resolve(data)
        }).catch((error)=>{
            console.log(error);
            reject(error);
        })
    })
}