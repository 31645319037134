import React, { useState, useEffect } from 'react';
import Select, { components, createFilter } from 'react-select';
import PropTypes from 'prop-types';

const propTypes = {
	// value: PropTypes.any.isRequired,
};

const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: '#fff',
			border: state.isFocused
				? '1px solid rgba(156, 156, 156, 0.5)'
				: '1px solid rgba(156, 156, 156, 0.5)',
			minheight: '35px',
			height: '35px',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: state.isFocused ? null : null
			},
			borderRadius: '5px ',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			height: '35px',
			padding: '0 6px'
		}),
		input: (provided, state) => ({
			...provided,
			margin: '0px',
			padding: '7px 0',
			minheight: '35px',
			height: '35px'
		}),
		indicatorSeparator: (state) => ({
			display: 'none'
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: '35px',
			fontSize: '18px'
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 50,
			borderRadius: '0px 0px 5px 5px',
			margin: '0px'
		})
	
};

const CustomSelect = (props) => {
	const [selectedValue, setSelectedValue] = useState(props.value);
	const [options, setOptions] = useState(props.options);

	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);

	useEffect(() => {
		setSelectedValue(props.value);
	}, [props.value]);

	const onSelectBlur = (event) => {
		event.nativeEvent?.stopImmediatePropagation();
		if (props.onBlur) {
			props.onBlur(event);
		}
	};

	const onSelectFocus = (event) => {
		event.nativeEvent?.stopImmediatePropagation();
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	const onChange = (newValue) => {
		if (props.onChange) {
			props.onChange(newValue);
		}
	};

	const DropdownIndicator = (prop) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...prop}>
					{prop.selectProps.menuIsOpen ? (
						<i className="fas fa-caret-up" />
					) : (
						<i className="fas fa-caret-down" />
					)}
				</components.DropdownIndicator>
			)
		);
	};

	return (
		<div className="customselect">
			<Select
				id={props.id || ''}
				name={props.name || ''}
				value={selectedValue}
				isClearable={props.clearable || false}
				isSearchable={props.searchable || false}
				options={options}
				placeholder={props.placeholder || ''}
				className={props.className || ''}
				ignoreCase={props.ignoreCase || false}
				isMulti={props.multi || false}
				isDisabled={props.disabled || false}
				onChange={onChange}
				styles={customStyles}
				classNamePrefix={'drdw'}
				// menuIsOpen={true}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						color: 'neutral0',
						...theme.colors,
						primary: '#2E3F55'
					}
				})}
				menuPlacement={props.menuPlacement || 'bottom'}
				onBlur={(value) => onSelectBlur(value)}
				onFocus={(value) => onSelectFocus(value)}
				components={{ DropdownIndicator }}
				required={props.required}
			/>
		</div>
	);
};

export default CustomSelect;

CustomSelect.propTypes = propTypes;
