import { apiGet } from "../../../utilities/utils";
import actionTypes from "../../../actions/actionTypes";
import store from "../../../store";

export const getEncounterLevelOutcomeScoreDetail = (
  conceptId,
  exactMatch,
  type = ""
) => {
  return new Promise((resolve, reject) => {
    apiGet("GetEncounterLevelOutcomeScoreDetails", {
      m_conceptId: conceptId,
      m_exactMatch: exactMatch,
      m_classification: type,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
export const getPatientLevelProgressionDetails = (
  conceptId,
  cohortCriteria = "All",
  type = ""
) => {
  return new Promise((resolve, reject) => {
    apiGet("GetPatientLevelProgressionDetails", {
      m_conceptId: conceptId,
      m_cohortCriteria: cohortCriteria,
      m_classification: type,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
export const setIsLoading = (loading) => {
  store.dispatch({
    type: actionTypes.IS_LOADING,
    payload: loading,
  });
};

export const updateStudyValidation = (updatedStudyValidationValues) => {
  store.dispatch({
    type: actionTypes.UPDATE_STUDY_VALIDITY,
    payload: updatedStudyValidationValues,
  });
};

export const setSideNavList = (list) => {
  store.dispatch({
    type: actionTypes.SET_SIDE_NAVLIST,
    payload: list,
  });
};
export const setHistory = (list) => {
  store.dispatch({
    type: actionTypes.SET_HISTORY,
    payload: list,
  });
};

export function getStudyInformation(studyName) {
  return new Promise((resolve, reject) => {
    apiGet("GetStudyInformation", { m_studyName: studyName })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_INFO,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function refreshStudyValidation(generatedReport, batchType, batchId) {
  return new Promise((resolve, reject) => {
    apiGet("RefreshStudyValidation", {
      generateReport: generatedReport,
      m_batchType: batchId ? batchId : batchType
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}