/* istanbul ignore file */
/* eslint-disable react/wrap-multilines */
import { apiGet, getRoutePath } from '../../utilities/utils';
import { setFilterLabel, setHistory } from '../../common/FilterFunctions/FilterFunctions';
//import history from '../../history';
import browserHistory from '../../common/CreateBrowserHistory/CreateBrowserHistory';
import { setQueryParams } from '../../utilities/utils';
import store from '../../store';
import actionTypes from '../../actions/actionTypes';
import actions from '../../actions';

// COUNTS = '', MEANS API IS NOT FETCHED YET, SHOW LOADER
const initialCounts = {
	study_count: '',
	cohort_count: '',
	data_count: '',
	patient_count: '',
};

/** ************************************
 * Function Name: getStudyList
 * Description: Fetch study list from API
 * @param query Object of parameters to supply
 *************************************/
export const getStudyList = (query) => {
	return new Promise((resolve, reject) => {
		apiGet('GetStudyList', query).then((res) => {
			if (res.data.data && res.data.data.length) {
				res.data.data.forEach((study) => {
					study.counts = { ...initialCounts };
				});
				resolve(res.data.data);
			} else {
				resolve([]);
			}
		});
	}).catch((err) => {
		console.error(err);
		reject();
	});
};
/****************************************
 * Function Name: getCohortList
 * Description: exports an action to get the cohort list data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getCohortList = (params) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortList', params)
			.then((res) => {
				const option = {
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				};
				store.dispatch(option);
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
};
/** ************************************
 * Function Name: getCohortCount
 * Description: Get cohort count
 * @param studyList Array of studies from state of component
 * @param studyIds Comma seperated list of study ids.
 *************************************/
export const getCohortCount = (studyList, studyIds) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortCounts', { studyIds })
			.then((res) => {
				const studies = [...(res.data?.data?.study || [])];
				studyList.forEach((study) => {
					const searchedCohort = studies.find(
						(studyResult) => studyResult.study_id === study.study_id
					);
					if (searchedCohort) {
						study.counts.cohort_count = searchedCohort.cohort_count;
					} else {
						study.counts.cohort_count = null;
					}
				});
				resolve(studyList);
			})
			.catch((err) => {
				console.error(err);
				reject();
			});
	});
};

/** ************************************
 * Function Name: getPatientCount
 * Description: Get patient count
 * @param studyList Array of studies from state of component
 * @param studyIds Comma seperated list of study ids.
 *************************************/
export const getPatientCount = (studyList, studyIds) => {
	return new Promise((resolve, reject) => {
		apiGet('GetPatientCounts', { studyIds })
			.then((res) => {
				const studies = [...(res.data.data.study || [])];
				studyList.forEach((study) => {
					const searchedStudy = studies.find(
						(studyResult) => studyResult.study_id === study.study_id
					);
					if (searchedStudy) {
						study.counts.patient_count = searchedStudy.patient_count;
					} else {
						study.counts.patient_count = null;
					}
				});
				resolve(studyList);
			})
			.catch((err) => {
				console.error(err);
				reject();
			});
	});
};

/** ************************************
 * Function Name: getDataCount
 * Description: Get data count
 * @param studyList Array of studies from state of component
 * @param studyIds Comma seperated list of study ids.
 *************************************/
export const getDataCount = (studyList, studyIds) => {
	return new Promise((resolve, reject) => {
		apiGet('GetDataCounts', { studyIds })
			.then((res) => {
				const studies = [...(res.data.data.study || [])];
				studyList.forEach((study) => {
					const searchedStudy = studies.find(
						(studyResult) => studyResult.study_id === study.study_id
					);
					if (searchedStudy) {
						study.counts.data_count = searchedStudy.data_count;
					} else {
						study.counts.data_count = null;
					}
				});
				resolve(studyList);
			})
			.catch((err) => {
				console.error(err);
				reject();
			});
	});
};

/* ***************************************
 * Function Name: gotoPage
 * Description: Navigate to requested page with url parameter cohort id
 * @param page - Target page
 * @param studyId Cohort id
 * @param cohortName Name of cohort
 ****************************************/
export const gotoPage = (page, studyId, studyName) => {
	let filterLabel = {
		page: page,
		options: [
			{
				filterLabel: 'Study',
				name: studyName,
			},
		],
	};
	setFilterLabel(filterLabel);
	setHistory();
	const path = getRoutePath(page);
	setQueryParams({ pathname: path, query: { study_id: studyId } });
};

export const gotoTrial = (props) => {
	history.push({ pathname: '/dashboard/cohortlist', search: '?name=verantos' });
};

/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export const searchConceptsById = (conceptIds, eventIds) => {
// 	return new Promise((resolve, reject) => {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };

/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getEvent = () => {
	return new Promise((resolve, reject) => {
		apiGet('GetEvent', {
			eventType: 'outcome',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data.data.reverse(),
				});
				resolve(res.data.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: deleteStudy
 * Description: exports an action to delete the study data from the backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export const deleteStudy = (m_studyId) => {
	return new Promise((resolve, reject) => {
		apiGet('DeleteStudy', {
			m_studyId: m_studyId,
		})
			.then((res) => {
				resolve(res);
				// console.log(res)
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
