import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';

const StudyBatchPopup = forwardRef((props,ref) => {
	const [open, setOpen] = useState(props.forceOpen ? true : false);
	const [classname, setClassName] = useState('testClass');
	const [selectedOption, setSelectedOption] = useState('current');

	useImperativeHandle(ref,() => {
			handleClick: handleClick
		}
	)

	useEffect(() => {
		if(props.open){
			props.open({
				handleClick: () => handleClick()
			})
		}
		return () => {
			if(props.open){
				props.open(null);
			}
		}
	}, []);
	
	/*****************************************
	 * Function name: closeModal
	 * Description: This function alters the state of the modal. 
	 * Sets the open state of the modal to false to close the modal.
	 * Also adds a css class to it for designing.
	****************************************/

	const cancelClick = (event) => {
		setClassName('testClass');
		setOpen(false)

		props.rootClosexit(event);
	};

	/*****************************************
	 * Function name: handleClick
	 * Description: This function alters the state of the modal. 
	 * Sets the open state of the modal to true to open the modal.
	****************************************/

	const handleClick = () => {
		setOpen(true)
	};

	/*****************************************
	 * Function name: handleClose
	 * Description: This function alters the state of the modal. 
	 * Sets the open state of the modal to true and adds the shake animation
	 * class to the modal box whenever user clicks outside the modal.
	 * setTimeout is is used for continuous use of shake animation.
	****************************************/

	const handleClose = () => {
		setOpen(true);
		setClassName('animated shake Data-Management');
		setTimeout(() => setClassName('Data-Management'), 1000);
	};

	/*****************************************
	 * Function name: handleRevert
	 * Description: This function calls a callback function to revert the changes made to the rapid annotation list
	 * by the manual annotator.
	****************************************/

	const okClick = (event) => {
		setClassName('Data-Management')
		setOpen(false)
		props.confirmClick(event);
    };
    
    const onValueChange = (event) =>{
		setSelectedOption(event.target.value);
    }

	/**************************************** 
	 * Function Name: render.
	 * Description: React Lifecycle method that renders the HTML to the DOM.
	 * Used to display the UI to the user for this particular component.
	 * Returns the JSX code which renders the ModalComponent 
	 * to show the modal box to the user.  
	****************************************/

		return (
			<div>
				<ErrorBoundary>
					<ModalComponent
						open={open}
						backdrop={true}
						content={
							<div className="jconfirm-box-container" style={{ padding: '20px 40px' }}>
								<div className="">
									{/* <div style={{fontSize:18}}>{props.text}</div> */}
									<div style={{ fontSize: 18, textAlign:'left' }}>
										Please choose an option for processing of batch(s) :-
										<br />
										<div>
											<input
												type="radio"
												value="current"
												name="gender"
												checked={selectedOption === 'current'}
												onChange={onValueChange}
											/>{' '}
											Current batch
										</div>
										<div>
											<input
												type="radio"
												value="all"
												name="gender"
												checked={selectedOption === 'all'}
												onChange={onValueChange}
											/>{' '}
											All batches
										</div>
										<br />
										Note:- your changes will be lost on ok click.
									</div>
								</div>
								<div className="jconfirm-content-pane">
									<div className="jconfirm-buttons">
										<button
											type="button"
											className="btn btn-default"
											onClick={(event) => cancelClick(event)}
										>
											Cancel
										</button>
										<button
											type="button"
											className="btn btn-default"
											onClick={(event) => okClick(selectedOption)}
										>
											Ok
										</button>
									</div>
								</div>
							</div>
						}
						className={'testClass'}
						handleClose={handleClose}
					/>
				</ErrorBoundary>
			</div>
		);
});

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(StudyBatchPopup));
