/* istanbul ignore file */
import React, { Component } from 'react';
import { connect } from 'react-redux';


// export default 
class App extends Component {

	render() {
	    return (
	    	<React.Fragment> {this.props.children}</React.Fragment>
	    );
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = {};

export default connect(mapStateToProps,mapDispatchToProps)(App);