import React, { useState, lazy, Suspense } from 'react';
import ModalHeader from '../../../common/ModalHeader/ModalHeader';
import { connect } from 'react-redux';

const SimpleDiv = lazy(() =>
	import(/* webpackChunkName: "SimpleDiv" */ '../../SimpleDiv/SimpleDiv')
);

function AccountSecurity(props) {
	const [mobileNumber, setMobileNumber] = useState(props.mobileNumber || '');

	let containerStyle = {
		overflow: 'hidden',
		textAlign: 'left',
	};
	return (
		<div style={containerStyle}>
			<div style={{ marginTop: 16 }}></div>
			<ModalHeader title1="Password" />

			<Suspense fallback={<div className="defaultSpinner" />}>
				<SimpleDiv
					cols1="col-sm-3"
					cols2="col-sm-8"
					title1="Password:"
					title2="********************"
				/>
			</Suspense>

			<ModalHeader cols1="col-sm-12" title1="Two-step verification" />

			<Suspense fallback={<div className="defaultSpinner" />}>
				<SimpleDiv
					cols1="col-sm-3"
					cols2="col-sm-8"
					title1="Two-step verification:"
					title2="Enable"
				/>
				<SimpleDiv
					cols1="col-sm-3"
					cols2="col-sm-8"
					title1="Primary:"
					title2={mobileNumber}
				/>
			</Suspense>
		</div>
	);
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AccountSecurity);
