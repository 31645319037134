import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../constants';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import PanelHeading from '../../components/PanelHeading/PanelHeading';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import Copyright from '../../components/Copyright/Copyright';
import {
	getStudyDetail,
	getCohortList,
	getStudyArmList,
	getCohortCriteria,
	searchConceptsById,
	saveStudyCohorts,
	clearStudyCohorts,
} from './StudyDesignHelper';
import { setIsLoading } from '../../actions/common';
import PropTypes from 'prop-types';
const BaseCohort = lazy(() =>
	import(/* webpackChunkName: "BaseCohort" */ '../../components/BaseCohort/BaseCohort')
);
const StudyCohort = lazy(() =>
	import(/* webpackChunkName: "StudyCohort" */ '../../components/StudyCohort/StudyCohort')
);

import trialIcon from '../../images/trial_icon.png';

const headerData = {
	title: 'Study',
	list: 'detail',
	img: <img src={trialIcon} className={'App-TrialList'} alt={'Trial List'} />,
};

function StudyDesign(props) {
	let [baseCohort, setBaseCohort] = useState({});

	const { studyCohorts, studyDetail } = props.study;
	const baseCohortCriteria = studyCohorts[0] || [];
	const baseCohort2 = (baseCohortCriteria.length > 0 && baseCohortCriteria[0].cohort) || [];
	let studyArms = studyCohorts.slice(1);
	let { loading } = props.loading;

	useEffect(() => {
		const studyId = props.match.params.studyId;
		setIsLoading(true);
		setFilterLabel({});

		getStudyDetail(studyId).then((studyDetail) => {
			getCohortList().then((cohortList) => {
				const _baseCohort =
					cohortList.data.data &&
					cohortList.data.data.filter((cohort) => {
						if (cohort.cohort_id == studyDetail.base_cohort) {
							return cohort;
						}
					});
				setBaseCohort(_baseCohort[0]);

				getStudyArmList(studyId).then((studyArmList) => {
					const PromiseArrCohort =
						(studyArmList &&
							studyArmList.map((studyArm, index) => {
								return getCohortCriteria(studyArm.cohort_id);
							})) ||
						[];

					PromiseArrCohort.splice(0, 0, getCohortCriteria(studyDetail.base_cohort));

					Promise.all(PromiseArrCohort).then((cohortCriterion) => {
						setIsLoading(false);
						let conceptIds = [];
						let eventIds = [];
						const studyCohorts = [];

						if (cohortCriterion[0]) {
							cohortCriterion.map((criterion, index) => {
								const cohortIndex = index;
								if (criterion?.data?.data) {
									criterion.data.data.map((criteria) => {
										if (criteria.criteria_type == 'EventId') {
											eventIds.push(criteria.event_id);
										} else {
											conceptIds.push(criteria.concept_id);
										}
									});
								}
								if (conceptIds[0] || eventIds[0]) {
									searchConceptsById(
										conceptIds.join(','),
										eventIds.join(',')
									).then((criteriaConcepts) => {
										if (criterion?.data?.data) {
											criterion.data.data.map((criteria) => {
												const cohortObj =
													cohortList.data.data &&
													cohortList.data.data.find((cohort) => {
														return (
															cohort.cohort_id == criteria.cohort_id
														);
													});
												let associatedConcept;
												if (criteria.criteria_type == 'EventId') {
													associatedConcept = criteriaConcepts.filter(
														(items) =>
															items.event_id == criteria.event_id
													)[0];
												} else {
													associatedConcept = criteriaConcepts.filter(
														(items) => items.cuid == criteria.concept_id
													)[0];
												}
												studyCohorts.push({
													...criteria,
													cohort: cohortObj,
													concepts: associatedConcept,
												});
											});
										} else {
											if (cohortIndex != 0) {
												const cohort = cohortList.data.data.find(
													(coh) =>
														coh.cohort_id ==
														studyArmList[cohortIndex - 1].cohort_id
												);
												studyCohorts.push({ cohort, concepts: [] });
											} else {
												const cohort = cohortList.data.data.find(
													(coh) =>
														coh.cohort_id == studyDetail.base_cohort
												);
												studyCohorts.push({ cohort, concepts: [] });
											}
										}
									});
								} else {
									if (cohortIndex != 0) {
										const cohort = cohortList.data.data.find(
											(coh) =>
												coh.cohort_id ==
												studyArmList[cohortIndex - 1].cohort_id
										);
										studyCohorts.push({ cohort, concepts: [] });
									} else {
										const cohort = cohortList.data.data.find(
											(coh) => coh.cohort_id == studyDetail.base_cohort
										);
										studyCohorts.push({ cohort, concepts: [] });
									}
								}
								saveStudyCohorts(studyCohorts, cohortIndex);
							});
						}
					});
				});
			});
		});
	}, []);

	useEffect(() => {
		return () => {
			clearStudyCohorts();
		};
	}, []);

	const gotoTechnology = (id, text) => {
		let filterLabel = {
			page: 'study',
			options: [
				{
					filterLabel: 'Cohort',
					name: text,
				},
			],
		};

		setFilterLabel(filterLabel);
		setHistory(false, text);
		props.history.push(`${constants.ROUTE_URL}/dashboard/techdetail`);
	};

	const gotoCohortDesign = (cohortId) => {
		setHistory(false, props.study.studyDetail.study_name);
		props.history.push(`${constants.ROUTE_URL}/dashboard/cohortdesign/${cohortId}`);
	};

	return (
		<div>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<HeaderTitles
				data={headerData}
				search={false}
				studyId={props.match.params.studyId}
				{...props}
			/>

			<div>
				<h3 className="v-cohort-name">Design: {studyDetail.study_name}</h3>
			</div>
			<PanelHeading heading="Base cohort" />
			<Suspense fallback={<div className="defaultSpinner" />}>
				<BaseCohort
					baseCohort={baseCohort2.length ? baseCohort2 : baseCohort}
					gotoTechnology={gotoTechnology}
					userRole={props.redSidenav && props.redSidenav.userRole}
					baseCohortCriteria={baseCohortCriteria}
					gotoCohortDesign={gotoCohortDesign}
				/>
			</Suspense>

			{studyArms.map((elem, index) => {
				return (
					<div key={index}>
						<PanelHeading heading={`Study arm ${index + 1}`} />
						<Suspense fallback={<div className="defaultSpinner" />}>
							<StudyCohort
								data={elem}
								baseCohort={baseCohort2.length ? baseCohort2 : baseCohort}
								gotoCohortDesign={gotoCohortDesign}
							/>
						</Suspense>
					</div>
				);
			})}
			<Copyright />
		</div>
	);
}

StudyDesign.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			studyId: PropTypes.any,
		}),
	}),
	redSidenav: PropTypes.shape({
		userRole: PropTypes.any,
	}),
	study: PropTypes.shape({
		studyCohorts: PropTypes.any,
		studyDetail: PropTypes.shape({
			base_cohort: PropTypes.any,
			study_name: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => {
	return {
		study: state.redStudy,
		loading: state.loading,
		redSidenav: state.redSidenav,
		// userRole: state.redSidenav.userRole,
		// studyDetail: state.study.studyDetail,
		// studyCohorts: state.study.studyCohorts
	};
};
export default connect(mapStateToProps)(StudyDesign);
