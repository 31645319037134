/* istanbul ignore file */
let host = window.location.host;
let protocol = window.location.protocol;

let apiUrl = host.indexOf('localhost') != -1 ? 'http://localhost:8080/AnalyticsProjectServer/' : protocol + '//' +  host + '/AnalyticsServer/';
let assetsPrefix = host == 'localhost:3000' ? 'http://' + host + '/images/' : (host == 'localhost:8080' ? 'http://' + host + '/AnalyticsProjectServer/images/' : protocol + '//' + host + '/images/');
let routeUrl = host == 'localhost:3000' ? '' : (host == 'localhost:8080' ?  '/AnalyticsProjectServer' : '');
let pathUrl = host == 'localhost:3000' ? '/' : (host == 'localhost:8080' ?  '/AnalyticsProjectServer' : '');
let callbackUrl = host == 'localhost:3000' ? 'http://' + host : (host == 'localhost:8080' ? 'http://' + host + '/AnalyticsProjectServer' : protocol + '//' + host);
let allowAuth0Access = host.indexOf('localhost') != -1 ? 'N' : 'Y';
let domainName = 'verantos.auth0.com';
let clientID = 'SY8C1Y1ae38WjUMvrIooRaK8suaJfsSC';

export default {
	API_URL: apiUrl,
	IMG_URL: assetsPrefix,
	ROUTE_URL: routeUrl,
	PATH_URL: pathUrl,
	CALLBACK_URL: callbackUrl,
	ALLOW_AUTH0_ACCESS_URL:allowAuth0Access,
	AUTH0_DOMAIN:domainName,
	AUTH0_CLIENT_ID:clientID
}
