import PropTypes from "prop-types"
import React from 'react';

const PageTitle = ({title,subTitle}) =>{
    document.title = title + " " + subTitle;
    return(
        <h2 className="heading">{title} {subTitle}</h2>
    )
}

PageTitle.propTypes = {
  subTitle: PropTypes.any,
  title: PropTypes.string
}

export default PageTitle;