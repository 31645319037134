import { apiPost, apiPostForm, apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import { setObjectToLocal } from '../../../utilities/utils';
import store from '../../../store';

export const changePasswordAPI = (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
        resolve({
            status: 200,
        }).catch((err) => {
            reject('Password update failed')
        })
		
        // apiGet('').then(res => {
		// 	resolve(res.data)
		// })
		// .catch(err => {
		// 	reject(err);
		// });
	})
}