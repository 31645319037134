/* istanbul ignore file */
import { apiGet, apiPostForm } from "../../../utilities/utils";
import actionTypes from "../../../actions/actionTypes";
import queryString from 'query-string';
import store from "../../../store";
import actions from "../../../actions";

export function getUserDetails(userIdList) {
	return new Promise(function(resolve, reject) {
			let params = {};
			if (userIdList) {
				params.userIdList = userIdList;
			}

			apiGet('FetchUserDetails', params)
				.then((res) => {
					resolve(res);
					store.dispatch({
						type: actionTypes.GET_USER_DETAILS,
						payload: res.data.data
					});
				})
				.catch((err) => {
					reject(err);
					console.log(err);
				});
		});
}

/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
 export function getSubdocumentById(subddoc_id) {
	return new Promise(function (resolve, reject) {
			let storedData = store.getState();
			let storedSubdocument = storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
			if (storedSubdocument) {
				resolve(storedSubdocument);
			} else {
				apiGet('GetSubdocumentById', {
					m_documentId: subddoc_id,
				})
					.then((res) => {
						if (res?.data?.data) {
							store.dispatch({
								type: actionTypes.GET_SUBDOCUMENT_DETAIL,
								payload: { [`subdocument_${subddoc_id}`]: res },
							});
						}
						resolve(res);
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});
			}
		});
}

/****************************************
 * Function Name: getPatientList
 * Description: exports an action to get the patients list
 * data from the backend
 * The data received gets set in the patients reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getPatientList(
	patient_ids,
	index,
	record,
	cohort_id,
	concept_id,
	isflagForPatientWithConcept,
	searchText,
	study_id,
	searchByPatient
) {
	return new Promise(function (resolve, reject) {
			let forKappaScore = queryString.parse(window.location.search).forKappaScore;
			// if(forKappaScore){
			// 	query.forKappaScore =true;
			// }
			let kappa = forKappaScore ? '&forKappaScore=true' : '';
			let cohort = cohort_id ? '&m_cohortId=' + cohort_id : '';
			let study = study_id ? '&m_studyId=' + study_id : '';
			let concept = concept_id ? '&m_conceptId=' + concept_id : '';
			let stext = searchText ? '&m_searchString=' + searchText : '';
			let m_searchByPatient =
				searchByPatient != undefined && searchByPatient.toString()
					? '&m_searchByPatient=' + searchByPatient
					: '';
			let flagForPatientWithConcept =
				isflagForPatientWithConcept != undefined
					? '&flagForPatientWithConcept=' + isflagForPatientWithConcept
					: '';
			let url =
				index != null && index != undefined
					? 'GetPatient?pageNumber=' +
					  index +
					  '&pageSize=' +
					  record +
					  cohort +
					  study +
					  concept +
					  flagForPatientWithConcept +
					  stext +
					  kappa +
					  m_searchByPatient
					: 'GetPatient?m_start';
			apiGet(url, patient_ids ? { m_PatientIdList: patient_ids } : {})
				.then((res) => {
					store.dispatch({
						type: actionTypes.GET_PATIENT_DATA,
						payload: res.data,
					});
					resolve(res);
				})
				.catch((err) => {
					store.dispatch({
						type: actionTypes.IS_LOADING,
						payload: false,
					});
					console.log(err);
					reject(err);
				});
		});
}


/** **************************************
     * Function Name: getStudyDetail
     * Description: exports an action to get the study details from the backend
     * The details received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getStudyDetail(study_id) {
	return new Promise(function(resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		}).then(res => {
			store.dispatch({
				type: actionTypes.GET_STUDY_DETAIL,
				payload: res.data,
			});
			resolve(res.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: searchConceptsById
     * Description: get concept detail based on concept ids provided.
     * Method has one signature.
****************************************/

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function(resolve, reject) {
// 		let params ={};
// 		params['conceptTableEntry.umls_concept_category']= 'All';
// 		if(conceptIds){
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if(eventIds){
// 			params['eventIds'] = eventIds
// 		}
// 		apiGet('SearchConceptsById', params).then(res => {
// 			store.dispatch({
// 				type: actionTypes.GET_STUDY_CONCEPTS,
// 				payload: res.data.data,
// 			});
// 			resolve(res.data.data);
// 		})
// 		.catch(err => {
// 			reject(err);
// 		});
// 	});
// }

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
 export function GetPatientManualAnnotationsWithConflict(
	m_PatientId,
	pageNumber = 1,
	pageSize = 0
) {
	return new Promise(function (resolve, reject) {
			apiGet('GetPatientManualAnnotationsWithConflicts', {
				m_PatientId: m_PatientId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				forKappaScore: false,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function GetPatientManualAnnotationsWithConflictsStudys(
	m_studyId,
	pageNumber = 1,
	pageSize = 0
) {
	return new Promise(function (resolve, reject) {
			apiGet('GetPatientManualAnnotationsWithConflicts', {
				m_studyId: m_studyId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				forKappaScore: false,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

// outcome API
export function getDerivedManualAnnotations(derivedAnnotationId, patientId, annotationSource) {
	return new Promise(function (resolve, reject) {
		let temp = {
			DerivedAnnotationId: derivedAnnotationId,
			PatientId: patientId,
			AnnotationLevel: 'Patient',
			AnnotationSource: annotationSource,
			ConceptId: '',
		};
		apiPostForm('GetDerivedManualAnnotations', temp)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getSeverityValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetSeverityValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_SEVERITY_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getDescriptorValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetDescriptorValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_DESCRIPTOR_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getProgressionValues() {
	return new Promise(function (resolve, reject) {
		apiGet('GetProgressionValues', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_PROGRESSION_VALUES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getManualAnnotationProjectAttributes() {
	return new Promise(function (resolve, reject) {
		apiGet('GetManualAnnotationProjectAttributes', {})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
