/* istanbul ignore file */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SideBar from '../../common/Sidebar/Sidebar';
import { Route, Switch } from 'react-router-dom';
import StudyList from '../StudyList/StudyList';
import PatientDetail from '../PatientDetail/PatientDetail';
import EditCohort from '../EditCohort/EditCohort';
import Technology from '../Technology/Technology';
import TechnologyDetail from '../TechnologyDetail/TechnologyDetail';
import AddCohort from '../AddCohort/AddCohort';
import TechnologyItem from '../TechnologyItem/TechnologyItem';
import AddStudyContainer from '../AddStudy/AddStudyContainer';
import EditStudyValidity from '../EditStudyValidity/EditStudyValidity';
import PatientList from '../PatientList/PatientListComponent';
import DataList from '../DataList/DataList';
import DataFilter from '../DataFilter/DataFilter';
import DataWorkQueue from '../DataList/DataWorkQueue/DataWorkQueue';
import CohortList from '../CohortList/CohortList';
import CohortDesign from '../CohortDesign/CohortDesign';
import CohortResult from '../CohortResult/CohortResult';
import StudyDesign from '../StudyDesign/StudyDesign';
import StudyResult from '../StudyResult/StudyResult';
import StudyValidity from '../StudyValidity/StudyValidity';
import StudyValidityOutcomeScore from '../StudyValidity/StudyValidityOutcomeScore/StudyValidityOutcomeScore';
import StudyValidityOutcomeScoreDetails from '../StudyValidity/StudyValidityOutcomeScoreDetails/StudyValidityOutcomeScoreDetails';
import StudyValidityDrilldown from '../StudyValidity/StudyValidityDrilldown/StudyValidityDrilldown';
import StudyValidityDrilldownDetails from '../StudyValidity/StudyValidityDrilldownDetails/StudyValidityDrilldownDetails';
import constants from '../../constants';
import DocumentContainer from '../Document/DocumentContainer/DocumentContainer';
import DataManual from '../Document/DataManual/DataManual';
import EditDataManual from '../Document/EditDataManual/EditDataManual';
import DataAutomated from '../Document/DataAutomated/DataAutomated';
import DataCohort from '../Document/DataCohort/DataCohort';
// import DataRapid from '../Document/DataRapid';
import DataReview from '../Document/DataReview/DataReview';
import DataResolve from '../Document/DataResolve/DataResolve';
import DataReviewStudy from '../Document/DataReviewStudy/DataReviewStudy';
import DataResolveStudy from '../Document/DataResolveStudy/DataResolveStudy';
import ConceptKappa from '../ConceptKappa/ConceptKappa';
import DataPreview from '../Document/DataPreview/DataPreview';
import ErrorBoundary from '../../components/ErrorBoundary';
import DataConceptSupport from '../Document/DataConceptSupport/DataConceptSupport';
import DataPatientLevelAnnotation from '../Document/DataPatientLevelAnnotation/DataPatientLevelAnnotation';
import DataPLAReview from '../Document/DataPLAReview/DataPLAReview';
import DataPLAStudyReview from '../Document/DataPLAStudyReview/DataPLAStudyReview';
import DataPLAStudyResolve from '../Document/DataPLAStudyResolve/DataPLAStudyResolve';
import DataPLAResolve from '../Document/DataPLAResolve/DataPLAResolve';
import DemographicDetail from '../../components/DemographicDetail/DemographicDetail';
import DerivedAutomatedAnnotation from '../DerivedAutomatedAnnotation/DerivedAutomatedAnnotation';
import PendingEncounter from '../../containers/PendingEncounter/PendingEncounter';

const routeUrl = constants.ROUTE_URL.length > 0 ? constants.ROUTE_URL : '';

const Dashboard = (props) => {
	const [key, setKey] = useState(Math.random(0, 1));
	const [path, setPath] = useState(window.location.href);

	const resetState = (oldurl) => {
		setKey(Math.random(0, 1));
		setPath(oldurl);
	};

	const sidebarClick = () => {
		const popover = document.querySelector('#popover-contained');
		if (popover && popover.style.display == 'block') {
			popover.style.opacity = 0;
			popover.style.zIndex = -100;
			popover.style.display = 'none';
			$('span').removeClass('highlighted');
			return;
		}
	};
	return (
		<React.Fragment key={key}>
			<div id="sidebar" onMouseUp={sidebarClick}>
				<SideBar resetState={resetState} />
			</div>
			<div className="main_left_div">
				<ErrorBoundary>
					<Switch>
						<Route path={routeUrl + '/dashboard/studylist'} component={StudyList} />
						<Route
							path={routeUrl + '/dashboard/studyedit/:studyId'}
							component={AddStudyContainer}
						/>
						<Route
							path={routeUrl + '/dashboard/studydesign/:studyId'}
							component={StudyDesign}
						/>
						<Route
							path={routeUrl + '/dashboard/studyresult/:studyId'}
							component={StudyResult}
						/>
						<Route
							path={routeUrl + '/dashboard/studyvalidity/:studyId'}
							component={StudyValidity}
						/>
						<Route
							path={
								routeUrl +
								'/dashboard/studyvalidityoutcomescore/:resolution/:studyId'
							}
							component={StudyValidityOutcomeScore}
						/>
						<Route
							path={
								routeUrl +
								'/dashboard/studyvalidityoutcomescoredetail/:resolution/:studyId/:conceptId/:exactMatch?/:classification?/:cohortCriteria?/:concept_name?'
							}
							component={StudyValidityOutcomeScoreDetails}
						/>
						<Route
							path={
								routeUrl +
								'/dashboard/twoclassdrilldown/:studyId/:concept_id?/:arm_type?/:att_name?/:att_value?/:is_event?/:concept_name?/:disabled_drilldown?'
							}
							component={StudyValidityDrilldown}
						/>
						<Route
							path={
								routeUrl +
								'/dashboard/twoclassdrilldowndetails/:studyId/:conceptId?/:encounterId?/:att_name?/:att_value?/:datatype?/:is_event?/:concept_name?'
							}
							component={StudyValidityDrilldownDetails}
						/>
						<Route
							path={routeUrl + '/dashboard/editstudyvalidity/:studyId'}
							component={EditStudyValidity}
						/>
						<Route
							path={routeUrl + '/dashboard/addStudy'}
							component={AddStudyContainer}
						/>
						<Route
							path={routeUrl + '/dashboard/conceptKappa/:studyId'}
							component={ConceptKappa}
						/>

						<Route
							path={routeUrl + '/dashboard/document/:docId'}
							component={DocumentContainer}
						/>

						<Route path={routeUrl + '/dashboard/cohortlist'} component={CohortList} />
						<Route
							path={routeUrl + '/dashboard/cohortdesign/:cohort_id'}
							component={CohortDesign}
						/>
						<Route
							path={routeUrl + '/dashboard/cohortresult/:cohort_id'}
							component={CohortResult}
						/>
						<Route path={routeUrl + '/dashboard/addcohort'} component={AddCohort} />
						<Route
							path={routeUrl + '/dashboard/cohort/edit/:cohort_id'}
							component={EditCohort}
						/>

						<Route
							path={
								routeUrl +
								'/dashboard/patientlist/:twoClass?/:studyId?/:conceptId?/:isConcept?/:is_event?/:att_name?/:att_value?/:cohortCriteria?/:isRefStCount?'
							}
							component={PatientList}
						/>
						<Route
							path={routeUrl + '/dashboard/patient/:patient_id'}
							component={PatientDetail}
						/>

						<Route
							path={
								routeUrl +
								'/dashboard/datalist/:twoClass?/:studyId?/:conceptId?/:isConcept?/:is_event?/:att_name?/:att_value?/:cohortCriteria?/:isRefStCount?'
							}
							component={DataList}
						/>
						<Route
							path={routeUrl + '/dashboard/dataworkqueue'}
							component={DataWorkQueue}
						/>
						<Route
							path={routeUrl + '/dashboard/datafilter'}
							component={DataFilter}
						/>
						<Route path={routeUrl + '/dashboard/technology'} component={Technology} />
						<Route
							path={routeUrl + '/dashboard/techdetail'}
							component={TechnologyDetail}
						/>
						<Route
							path={routeUrl + '/dashboard/techdetail/:concept_id'}
							component={TechnologyDetail}
						/>
						<Route
							path={routeUrl + '/dashboard/techitem/:lexicon_id'}
							component={TechnologyItem}
						/>

						<Route
							path={routeUrl + '/dashboard/datamanual/:docId'}
							component={DataManual}
						/>
						<Route
							path={routeUrl + '/dashboard/editdatamanual/:docId'}
							component={EditDataManual}
						/>
						<Route
							path={routeUrl + '/dashboard/dataautomated/:docId'}
							component={DataAutomated}
						/>
						<Route
							path={routeUrl + '/dashboard/datacohort/:docId'}
							component={DataCohort}
						/>
						{/* <Route
							path={routeUrl + '/dashboard/datarapid/:docId'}
							component={DataRapid}
						/> */}
						<Route
							path={routeUrl + '/dashboard/datareview/:docId'}
							component={DataReview}
						/>
						<Route
							path={routeUrl + '/dashboard/dataplareview/:docId'}
							component={DataPLAReview}
						/>
						<Route
							path={routeUrl + '/dashboard/dataplastudyreview/:docId'}
							component={DataPLAStudyReview}
						/>
						<Route
							path={routeUrl + '/dashboard/dataplastudyresolve/:docId'}
							component={DataPLAStudyResolve}
						/>
						<Route
							path={routeUrl + '/dashboard/dataresolve/:docId'}
							component={DataResolve}
						/>
						<Route
							path={routeUrl + '/dashboard/datastudyreview/:docId'}
							component={DataReviewStudy}
						/>
						<Route
							path={routeUrl + '/dashboard/datastudyresolve/:docId'}
							component={DataResolveStudy}
						/>
						<Route path={routeUrl + '/dashboard/datapreview'} component={DataPreview} />
						<Route
							path={routeUrl + '/dashboard/conceptsupport/:docId'}
							component={DataConceptSupport}
						/>
						<Route
							path={routeUrl + '/dashboard/patientlevelannotation/:docId'}
							component={DataPatientLevelAnnotation}
						/>
						<Route
							path={routeUrl + '/dashboard/dataplaresolve/:docId'}
							component={DataPLAResolve}
						/>
						<Route
							path={routeUrl + '/dashboard/demographicdata'}
							component={DemographicDetail}
						/>
						<Route
							path={routeUrl + '/dashboard/derivedautomatedannotation/:docId'}
							component={DerivedAutomatedAnnotation}
						/>
						<Route
							path={routeUrl + '/dashboard/pendingencounters'}
							component={PendingEncounter}
						/>
					</Switch>
				</ErrorBoundary>
			</div>
		</React.Fragment>
	);
};

const DashboardMemo = React.memo(Dashboard, (nextProps, nextState) => {
	if (
		nextState.path != window.location.href &&
		!localStorage.getItem('selectedConcept') &&
		window.location.pathname.indexOf('/document/') == -1 &&
		window.location.pathname.indexOf('/datapreview') == -1
	) {
		// resetState(window.location.href);
	} else {
		return false;
	}
	return true;
});

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DashboardMemo);
