import React, { useEffect, lazy, Suspense, useState, useSelector } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import { roleAccess } from '../../utilities/AppConstants';
import dataIcon from '../../images/data_icon.png';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import './PendingEncounter.css';
import { getAnnotatotList, getEncounters } from './PendingEncounterHelper';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { Link } from 'react-router-dom';
import constants from '../../constants';
import Loading from 'react-loading-spinner';
import { setIsLoading } from '../../actions/common';
import localforage from 'localforage';

const headerData = {
	title: 'Data',
	searchTitle: 'data',
	list: 'list',
	img: (
		<div className="data_icon">
			<img src={dataIcon} alt="Data List" style={{ width: 20 }} />
		</div>
	),
};

function PendingEncounter(props) {
	let { userRole } = props.redSidenav;
	const [annotatorList, setAnnotatorList] = useState([]);
	const [selectedAnnotator, setSlectedAnnotator] = useState({});
	const [encountersList, setEncountersList] = useState([]);
	const [noAnnotator, setNoAnnotator] = useState(false);

	useEffect(() => {
		//RESET PATIENT COUNT FROM PATIENT LIST FOR PAGINATION
		if (props?.redSidenav?.userRole?.Role) {
			setIsLoading(true);
			setNoAnnotator(false);
			getAnnotatotList()
				.then((res) => {
					if (res.length > 0) {
						setAnnotatorList(res);
					} else {
						setNoAnnotator(true);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					setIsLoading(false);
				});
		}
	}, [props?.redSidenav?.userRole?.Role]);

	useEffect(() => {
		if (annotatorList.length > 0) {
			setSlectedAnnotator(annotatorList[0]);
			localforage.getItem('selectedAnnotator').then((item) => {
				if (item) {
					setSlectedAnnotator(item);
					getEncounterList(item.value);
				} else {
					getEncounterList(annotatorList[0].value);
				}
			});
		}
	}, [annotatorList]);

	const getEncounterList = (annotatorId) => {
		setIsLoading(true);
		getEncounters(annotatorId)
			.then((res) => {
				let filterredEncounter = res.filter((items) => items.personal_count == 0);
				setEncountersList(filterredEncounter);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const onChangeAnnotator = (newValue) => {
		setSlectedAnnotator(newValue);
		localforage.setItem('selectedAnnotator', newValue);
		getEncounterList(newValue.value);
	};

	return (
		<React.Fragment>
			{userRole && userRole.Role && (
				<ErrorBoundary>
					<Suspense fallback={<div className="defaultSpinner" />}>
						<HeaderTitles
							data={headerData}
							search={true}
							searchText=""
							history={props.history}
							noMenu={true}
						/>
					</Suspense>
				</ErrorBoundary>
			)}
			<div className="pending-encounter-header">
				{props?.loading?.loading ? (
					<Loading isLoading={true} loadingClassName="loading">
						<span />
					</Loading>
				) : (
					''
				)}
				<div className="text">
					Pending annotation encounters list : {selectedAnnotator.label}
				</div>
				<React.Fragment>
					<div className="drpdown">
						{
							!noAnnotator && annotatorList.length > 0 ? <span>Total records: {encountersList.length == 0 ? '' : encountersList.length} </span> : <span></span>
						}
						{!noAnnotator && annotatorList.length > 0 ? (
							<CustomSelect
								id="is-currentChange"
								className="uniqueTriaArm custom editFont"
								placeholder={'Select current'}
								value={selectedAnnotator}
								onChange={onChangeAnnotator}
								clearable={false}
								options={annotatorList}
							/>
						) : (
							<div> No annotator found</div>
						)}
					</div>
				</React.Fragment>
			</div>
			<React.Fragment>
				<div className="v-grid-header">
					<div className="v-col-link">Encounters</div>
					<div className="v-col-link">Total annotations</div>
				</div>
			</React.Fragment>
			<div>
				{encountersList.map((encounters) => {
					return (
						<div className="v-title-wrapper">
							<div className="v-col-link">
								<Link
									to={
										constants.ROUTE_URL +
											'/dashboard/document/' +
											encounters.subdocument_id || ''
									}
								>
									{encounters.name}
								</Link>
							</div>
							<div className="v-col-link">{encounters.total_count}</div>
						</div>
					);
				})}
			</div>
		</React.Fragment>
	);
}

PendingEncounter.propTypes = {
	dispatch: PropTypes.func,
	history: PropTypes.any,
	match: PropTypes.shape({
		params: PropTypes.shape({
			att_name: PropTypes.string,
			att_value: PropTypes.string,
			cohortCriteria: PropTypes.any,
			conceptId: PropTypes.any,
			isConcept: PropTypes.string,
			is_event: PropTypes.string,
			studyId: PropTypes.any,
			twoClass: PropTypes.string,
		}),
	}),
	redSidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
	sidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};
export default connect((state) => state)(PendingEncounter);
