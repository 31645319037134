/* istanbul ignore file */
import actionTypes from './actionTypes';
import store from '../reducers/index';
import { apiGet, apiPostForm } from '../utilities/utils';

/****************************
	 * Function Name: setSideNavList
     * Description: exports an action to set the side nav list 
     * with the listitem components to be display the history to the user. 
     * The data received gets set in the sidenav reducer which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - list - An Array having a list of React Components which need to
     * be rendered to the SideNav.
*****************************/

export function setSideNavList(list) {

	return dispatch => {
		dispatch({
			type: actionTypes.SET_SIDE_NAVLIST,
			payload: list
		});
	}
}

export function setHistory(list) {
	
	return dispatch => {
		dispatch({
			type: actionTypes.SET_HISTORY,
			payload: list
		});
	}
}

export function removeHistory(list) {
	
	return dispatch => {
		dispatch({
			type: actionTypes.REMOVE_HISTORY
		});
	}
}


export function setFilterText(filterText) {

	return dispatch => {
		dispatch({
			type: actionTypes.SET_FILTER_TEXT,
			payload: filterText
		})
	}
}

export function removeFilterText(filterText) {

	return dispatch => {
		dispatch({
			type: actionTypes.REMOVE_FILTER_TEXT
		})
	}
}

export function GetUserRole() {
	return dispatch => new Promise((resolve, reject) => {
		apiGet('GetUserRole').then(res => {
			dispatch({
				type: actionTypes.GET_USER_ROLE,
				payload: res.data.data
			})
			resolve(res.data.data)
		})
		.catch(err => {
			reject(err);
		});
	})
}


export function SetUserRole(userRole) {
	return dispatch => new Promise((resolve, reject) => {
		// apiGet('GetUserRole').then(res => {
			dispatch({
				type: actionTypes.SET_USER_ROLE,
				payload: userRole
			})
			resolve(userRole);
		// })
		// .catch(err => {
		// 	reject(err);
		// });
	})
}