import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../../actions';
import constants from '../../constants';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';
import {
	getLexiconCount,
	getLexiconsByConceptID,
	getLexicons,
	setHistory,
	setItemDetail,
} from './TechnologyDetailHelper';

const TechDetailComponent = lazy(() =>
	import(
		/* webpackChunkName: "TechDetailComponent" */ '../../components/TechDetailComponent/TechDetailComponent'
	)
);

function TechnologyDetail(props) {
	const [loading, setLoading] = useState(true);
	let { concept_id } = props.match.params;
	let { LexiconConcepts, lexiconCount, LexiconConceptsByID } = props.technology;
	useEffect(() => {
		getLexiconCount();
		setLoading(true);
		if (concept_id != undefined) {
			getLexiconsByConceptID(concept_id, 0, 10).finally(() => {
				setLoading(false);
			});
		} else {
			getLexicons(0, 10).finally(() => {
				setLoading(false);
			});
		}
	}, []);

	const gotoPage = (pageNum, recordsPerPage) => {
		if (window.scrollTo) {
			window.scrollTo(0, 0);
		}

		setLoading(true);
		if (concept_id != undefined) {
			getLexiconsByConceptID(concept_id, (pageNum - 1) * 10, recordsPerPage).finally(() => {
				setLoading(false);
			});
		} else {
			getLexicons((pageNum - 1) * 10, recordsPerPage).finally(() => {
				setLoading(false);
			});
		}
	};

	const techItemDetail = (lexicon_id, lexicon_name, lexicon_code) => {
		let itemDetail = {};
		itemDetail['id'] = lexicon_id;
		itemDetail['name'] = lexicon_name;
		itemDetail['code'] = lexicon_code;
		setItemDetail(itemDetail);
		setHistory({
			...window.location,
			display_name: 'Technology details: Natural language processing lexicon',
		});
		props.history.push(`${constants.ROUTE_URL}/dashboard/techitem/${lexicon_id}`);
	};

	let lexicons = '';
	let records = 0;
	if (concept_id != undefined) {
		lexicons = LexiconConceptsByID;
		records = lexicons.recordsTotal;
	} else {
		lexicons = LexiconConcepts;
		records = lexiconCount;
	}
	return (
		<div>
			<Loading isLoading={loading} loadingClassName="loading">
				<span />
			</Loading>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<TechDetailComponent
						records={records}
						lexicons={lexicons}
						gotoPage={gotoPage}
						techItemDetail={techItemDetail}
					/>
				</Suspense>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
}

TechnologyDetail.propTypes = {
	dispatch: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			concept_id: PropTypes.any,
		}),
	}),
	technology: PropTypes.shape({
		LexiconConcepts: PropTypes.any,
		lexiconCount: PropTypes.any,
		LexiconConceptsByID: PropTypes.any,
	}),
};
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(TechnologyDetail);
