import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import constants from '../../constants';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../actions';
import { withRouter } from 'react-router-dom';
import Copyright from '../../components/Copyright/Copyright';
import { setIsLoading } from '../../actions/common';
import dataIcon from '../../images/data_icon.png';

const EncounterLevelAnnotationPopover = lazy(() =>
	import(
		/* webpackChunkName: "EncounterLevelAnnotationPopover" */ '../../components/EncounterLevelAnnotation/EncounterLevelAnnotationPopover'
	)
);

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	loading: { loading: false },
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DerivedAutomatedAnnotation = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [patientId, setPatientId] = useState(0);
	const [patientInfo, setPatientInfo] = useState('');
	const [encounterLevelAnnotation, setEncounterLevelAnnotation] = useState([]);
	let { loading } = props.loading;

	const getEncounterLevelAutomatedAnnotations = (docId) => {
		props.dispatch(actions.getEncounterLevelAutomatedAnnotations(docId)).then((resp) => {
			setEncounterLevelAnnotation(resp.data);
		});
	};

	const gotoPatientDetail = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/${patientId}`);
	};

	useEffect(() => {
		setIsLoading(true);
		let { docId, doc_name } = props.match.params;
		props.dispatch(actions.getUserDetails()).then((res) => {});
		props.dispatch(actions.getSubdocumentById(docId)).then((resp) => {
			setPatientId(resp.data?.data?.patient_id);

			props.dispatch(actions.getPatientList(resp.data?.data?.patient_id)).then((res) => {
				setIsLoading(false);

				setPatientInfo(res.data.data[0]);
			});
			getEncounterLevelAutomatedAnnotations(docId);
		});
	}, []);

	return (
		<div className="DataAutomated">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}

			<HeaderTitles
				data={headerData}
				editMode={editMode}
				search={false}
				history={props.history}
				match={props.match}
			/>
			<div className="document-header-container">
				<span>Derived automated annotations: </span>
				<span className="hyperlink" onClick={gotoPatientDetail}>
					{patientInfo?.patient_Identifier}
				</span>
			</div>
			<div className="section-header">Encounter-level annotations</div>
			<ul className="section-container">
				{encounterLevelAnnotation ? (
					<li>
						<Suspense fallback={<div className="defaultSpinner" />}>
							<EncounterLevelAnnotationPopover
								automated={true}
								annotation={encounterLevelAnnotation}
							/>
						</Suspense>
					</li>
				) : (
					<li />
				)}
			</ul>

			<div className="section-header">Episode-level annotations</div>
			<ul className="section-container">
				<li />
			</ul>

			<div className="section-header">Patient-level annotations</div>
			<ul className="section-container">
				<li />
			</ul>

			<Copyright />
		</div>
	);
};

DerivedAutomatedAnnotation.propTypes = {
	dispatch: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
			doc_name: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(DerivedAutomatedAnnotation));
