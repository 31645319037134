import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import constants from '../../constants';
import Loading from 'react-loading-spinner';
import { setQueryParams } from '../../utilities/utils';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import ErrorBoundary from '../../components/ErrorBoundary';
import locationhistory from '../../common/CreateBrowserHistory/CreateBrowserHistory';
import Copyright from '../../components/Copyright/Copyright';
import { setIsLoading } from '../../actions/common';
import {
	getPatientAlgorithmicProblemList,
	getExcludedAlgorithmicProblemList,
	getPatientList,
	getDocuments,
	getUserEntityCounts,
	getCohortListForPatient,
	getDiagnosesInfo,
	getPatient_Encounters,
	getAlgorithmicProblemList,
	setDocName,
	setHistoryAction,
	emptyPatientDeatils,
	getPatient_Episodes,
	getEncounterDocuments,
} from './PatientDetailHelper';

const PatientDetailComponent = lazy(() =>
	import(
		/* webpackChunkName: "PatientDetailComponent" */ '../../components/PatientDetail/PatientDetailComponent'
	)
);
import patientIcon from '../../images/patient_icon.png';

const headerData = {
	title: 'Patient',
	list: 'detail',
	img: (
		<img
			src={patientIcon}
			style={{ marginRight: '20px', width: '15px' }}
			className={'App-PatientListImage'}
			alt={'Patients List'}
		/>
	),
};

const PatientDetail = (props) => {
	let { patient_id } = props.match.params;
	let { loading } = props.loading;
	let { patientDocuments, patientDiagnoses } = props.redPatient;
	// let { cohortList } = props.cohort;
	let { userRole } = props.redSidenav;
	const [recordCount, setRecordCount] = useState(0);
	const [patientInfo, setPatientInfo] = useState({});
	const [algoProblemList, setAlgoProblemList] = useState([]);
	const [algoProbListFetched, setAlgoProbListFetched] = useState(false);
	const [excludedProbList, setExcludedProbList] = useState([]);
	const [excludedProbListFetched, setExcludedProbListFetched] = useState(false);
	const [diagnosesFetching, setDiagnosesFetching] = useState(false);
	const [patientPreviewProbList, setPatientPreviewProbList] = useState(false);
	let [uniqueEncounters, setUniqueEncounters] = useState([]);
	let [episodes, setEpisodes] = useState([]);
	const usePrevious = (value) => {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	};
	const prevDatamanagement = usePrevious(props.redDatamanagement?.fetching);

	useEffect(() => {
		if (prevDatamanagement === false && props.redDatamanagement?.fetching === true) {
			getAPIData();
		}
	}, [props.redDatamanagement?.fetching]);

	useEffect(() => {
		if (window) {
			window.scrollTo(0, 0);
		}
		getAPIData();
		setFilterLabel({});
	}, []);

	const encounterDocumentsCallback = (encountId) => {
		getEncounterDocuments(encountId).then(()=>{
			setIsLoading(false);
		})
	};

	const getAPIData = () => {
		setIsLoading(true);
		let { patient_id } = props.match.params;

		if (patient_id == 0) {
			if (sessionStorage.getItem('patientpreviewdata')) {
				let patientlist = JSON.parse(sessionStorage.getItem('patientpreviewdata'));
				setIsLoading(false);
				setPatientPreviewProbList(patientlist);
			} else {
				getPatientAlgorithmicProblemList(
					JSON.parse(sessionStorage.getItem('nlpsectiondata'))
				).then((res) => {
					setIsLoading(false);
					if (res.data.data) {
						setPatientPreviewProbList(res.data.data);
						sessionStorage.setItem('patientpreviewdata', JSON.stringify(res.data.data));
					}
				});
			}
			return;
		}

		getPatientList(patient_id).then((res) => {
			setIsLoading(false);
			setPatientInfo(res.data.data[0]);
		});

		getDocuments(patient_id);
		getUserEntityCounts({ patientId: patient_id }).then((res) => {
			setRecordCount(res.data_count);
		});
		getCohortListForPatient(patient_id).then((resp) => {
			let isConcept = resp.data.data.filter((patient) => {
				return patient.concept_id != '';
			});
		});
		setDiagnosesFetching(true);
		getDiagnosesInfo(patient_id)
			.then((resp) => {
				setDiagnosesFetching(false);
			})
			.catch((err) => {
				setDiagnosesFetching(false);
			});
		getPatient_Encounters(patient_id).then((res) => {
			uniqueEncounters = res.data.data.filter(function (elem, index, self) {
				return (
					self.findIndex((t) => {
						return t.encounter_id === elem.encounter_id;
					}) === index
				);
			});
			setUniqueEncounters(uniqueEncounters);

			let episodeIDs = [
				...new Set(
					uniqueEncounters.map((encounter) => {
						return encounter.episode_id;
					})
				),
			].join();

			getPatient_Episodes(episodeIDs).then((episodesRes) => {
				setEpisodes(episodesRes);
			});
		});
		getAlgorithmicProblemList(patient_id).then((res) => {
			setAlgoProblemList(res.data);
			setAlgoProbListFetched(true);
		});

		getExcludedAlgorithmicProblemList(patient_id).then((res) => {
			setExcludedProbList(res.data);
			setExcludedProbListFetched(true);
		});
	};
	const getDocumentData = (doc_id, doc_name, concept_id, doc_type) => {
		setDocName(doc_name);
		setHistoryAction({
			...window.location,
			display_name: `Patient detail: ${props.redPatient.patients.data[0].patient_Identifier}`,
		});
		const toolTipElement = document.querySelector(`#automatetooltip-${concept_id}`);
		if (toolTipElement) {
			toolTipElement.click();
		}
	};

	const goToDataDetail = (document_id, concept_id, annotation_id) => {
		setQueryParams({
			pathname: `${constants.ROUTE_URL}/dashboard/document/${document_id}`,
			query: {
				concept_id: concept_id,
				route_doc_status: annotation_id ? 'true' : '',
				route_annotation_id: annotation_id || '',
				route_from: 'patient',
				route_doc_status: 'manual',
			},
		});
	};

	const redirectToDocument = (concepts) => {
		setHistory(false, patientInfo.patient_Identifier || '');
	};

	useEffect(() => {
		return () => {
			let { patientDocuments, patientDetails, patient_name, patients, patientDiagnoses } =
				props.redPatient;
			emptyPatientDeatils(
				patientDocuments,
				patientDetails,
				patient_name,
				patients,
				patientDiagnoses
			);
		};
	}, []);

	const endPreviewClick = () => {
		sessionStorage.removeItem('patientpreviewdata');
		let goBackUrl = sessionStorage.getItem('EndPreviewUrl');
		if (goBackUrl) {
			props.history.push(constants.ROUTE_URL + goBackUrl);
		} else {
			locationhistory.goBack();
		}
	};

	const goToDemographic = () => {
		sessionStorage.setItem('patientInfo', JSON.stringify(patientInfo));
		props.history.push(`${constants.ROUTE_URL}/dashboard/demographicdata`);
	};

	const redirectToAnnotation = (items, subdocumentId) => {
		let automatedAnnotationId =
			`automated_annotation_id=${ 
			items.section_number 
			}_${ 
			items.begin_offset 
			}_${ 
			items.end_offset}`;
		props.history.push(
			`${constants.ROUTE_URL 
				}/dashboard/document/${ 
				subdocumentId 
				}?${ 
				automatedAnnotationId}`
		);
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles data={headerData} search={false} {...props} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<PatientDetailComponent
						patient_id={patient_id}
						patientInfo={patientInfo}
						documents={patientDocuments}
						uniqueEncounters={uniqueEncounters}
						episodes={episodes}
						diagnoses={patientDiagnoses}
						diagnosesFetching={diagnosesFetching}
						getDocumentData={getDocumentData}
						patientCohorts={props?.redCohort?.patientCohorts}
						cohortList={props?.redCohort?.cohortList}
						goToDataDetail={goToDataDetail}
						redirectToDocument={redirectToDocument}
						recordCount={recordCount}
						userRole={userRole}
						algoProblemList={algoProblemList}
						algoProbListFetched={algoProbListFetched}
						excludedProbList={excludedProbList}
						patientPreviewProbList={patientPreviewProbList}
						excludedProbListFetched={excludedProbListFetched}
						endPreviewClick={endPreviewClick}
						goToDemographic={goToDemographic}
						redirectToAnnotation={redirectToAnnotation}
						encounterDocumentsCallback={encounterDocumentsCallback}
					/>
				</Suspense>
			</ErrorBoundary>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<Copyright />
		</div>
	);
};

PatientDetail.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			patient_id: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			  ]),
		}),
	}),
	redCohort: PropTypes.shape({
		cohortList: PropTypes.any,
		patientCohorts: PropTypes.any,
	}),
	redDatamanagement: PropTypes.shape({
		fetching: PropTypes.bool,
	}),
	redPatient: PropTypes.shape({
		patientDetails: PropTypes.any,
		patientDiagnoses: PropTypes.any,
		patientDocuments: PropTypes.any,
		patient_name: PropTypes.any,
		patients: PropTypes.any,
	}),
	redSidenav: PropTypes.shape({
		userRole: PropTypes.any,
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(PatientDetail);
