/* istanbul ignore file */
import actionTypes from './actionTypes';
import { apiGet, apiPostForm } from '../utilities/utils';
import queryString from 'query-string';

/****************************************
 * Function Name: getPatientList
 * Description: exports an action to get the patients list
 * data from the backend
 * The data received gets set in the patients reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getPatientList(
	patient_ids,
	index,
	record,
	cohort_id,
	concept_id,
	isflagForPatientWithConcept,
	searchText,
	study_id,
	searchByPatient
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let forKappaScore = queryString.parse(window.location.search).forKappaScore;
			// if(forKappaScore){
			// 	query.forKappaScore =true;
			// }
			let kappa = forKappaScore ? '&forKappaScore=true' : '';
			let cohort = cohort_id ? '&m_cohortId=' + cohort_id : '';
			let study = study_id ? '&m_studyId=' + study_id : '';
			let concept = concept_id ? '&m_conceptId=' + concept_id : '';
			let stext = searchText ? '&m_searchString=' + searchText : '';
			let m_searchByPatient =
				searchByPatient != undefined && searchByPatient.toString()
					? '&m_searchByPatient=' + searchByPatient
					: '';
			let flagForPatientWithConcept =
				isflagForPatientWithConcept != undefined
					? '&flagForPatientWithConcept=' + isflagForPatientWithConcept
					: '';
			let url =
				index != null && index != undefined
					? 'GetPatient?pageNumber=' +
					  index +
					  '&pageSize=' +
					  record +
					  cohort +
					  study +
					  concept +
					  flagForPatientWithConcept +
					  stext +
					  kappa +
					  m_searchByPatient
					: 'GetPatient?m_start';
			apiGet(url, patient_ids ? { m_PatientIdList: patient_ids } : {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_PATIENT_DATA,
						payload: res.data,
					});
					resolve(res);
				})
				.catch((err) => {
					dispatch({
						type: actionTypes.IS_LOADING,
						payload: false,
					});
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getOrganizations
 * Description: exports an action to get the organizations list
 * data from the backend
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getOrganizations() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetOrganization', {})
				.then((res) => {
					// console.log(res, "res")
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: setOrganizations
 * Description: exports an action to set the manipulated data
 * in the patients reducer.
 * Method has one signature.
 * @param - organization - manipulated data that has to be set in
 * the patients reducer.
 ****************************************/

// export function setOrganizations(organization) {
// 	return (dispatch) => {
// 		// console.log("patientActions setOrganizations: ",organization);
// 		dispatch({
// 			type: actionTypes.GET_ORGANIZATIONS,
// 			/* payload: JSON.parse(JSON.stringify(organization)) */
// 			payload: organization,
// 		});
// 	};
// }

/****************************************
 * Function Name: getPatients_Organisation
 * Description: exports an action to get the patients list
 * for a particular organization from the backend
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - organization_id - The organization ID based on which the
 * list of patients has to be fetched.
 ****************************************/

export function getPatients_Organisation(
	organization_id,
	patient_ids,
	index,
	record,
	cohort_id,
	concept_id,
	isflagForPatientWithConcept,
	searchText,
	searchByPatient,
	study_id
) {
	let forKappaScore = queryString.parse(window.location.search).forKappaScore;
	// if(forKappaScore){
	// 	query.forKappaScore =true;
	// }
	let cohort = cohort_id ? '&m_cohortId=' + cohort_id : '';
	let concept = concept_id ? '&m_conceptId=' + concept_id : '';
	let searctString = searchText ? '&m_searchString=' + searchText : '';
	let flagForPatientWithConcept =
		isflagForPatientWithConcept != undefined ? isflagForPatientWithConcept : '';
	//This returns a promise which is used to form an array of promises
	let url =
		'GetPatient' +
		(flagForPatientWithConcept
			? '?flagForPatientWithConcept=' + flagForPatientWithConcept
			: '');
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet(url, {
				getOrganizationPatient: true,
				m_PatientIdList: patient_ids,
				Organization_id: organization_id,
				pageNumber: index,
				pageSize: record,
				m_cohortId: cohort_id || '0',
				m_conceptId: concept_id || '',
				m_searchString: searchText || '',
				m_searchByPatient:
					searchByPatient != undefined ? searchByPatient.toString() : 'true',
				m_studyId: study_id || 0,
				forKappaScore: forKappaScore || false,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
				});
		});
}

/****************************************
 * Function Name: getDiagnosesInfo
 * Description: exports an action to get the diagnoses info
 * for a particular patient from the backend
 * The data received is set in the patients reducer which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - patient_id - The patient ID based on which the
 * diagnoses info has to be fetched.
 ****************************************/

export function getDiagnosesInfo(patient_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('getDiagnosesInfo', {
				patient_id: patient_id,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.SET_DIAGNOSES,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					dispatch({
						type: actionTypes.SET_DIAGNOSES,
						payload: [],
					});
					reject(err);
					console.log(err);
				});
		});
}

/****************************************
 * Function Name: removeDiagnosesInfo
 * Description: exports an action to set the annotation text
 * in the reducer.
 * Method has one signature.
 ****************************************/
export function emptyPatientDeatils() {
	return (dispatch) => {
		dispatch({
			type: actionTypes.EMPTY_PATIENT_DETAILS,
			payload: '',
		});
	};
}

/****************************************
 * Function Name: getDocuments
 * Description: exports an action to get the documents
 * for a particular patient_id and organization_id from the backend
 * The data received is set in the patients reducer and also returned
 * via promise which can be used later for manipulation.
 * Method has one signature.
 * @param - patient_id - The patient ID based on which the
 * documents have to be fetched.
 * @param - organization_id - The organization id based on which the
 * documents have to be fetched.
 ****************************************/

export function getDocuments(patient_id, organization_id, cohort_id, searchString) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			// Call the GetDocuments API on the backend which will fetch data based on patient_id and organization_id
			apiGet('GetDocuments', {
				patient_id: patient_id,
				organization_id: organization_id,
				m_cohortId: cohort_id,
				m_searchString: searchString != undefined ? searchString.toString() : '',
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_DOCUMENTS,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPatient_Encounters
 * Description: exports an action to get the encounters
 * for a particular patient from the backend.
 * The data received is returned via promise which
 * can be used later for manipulation.
 * Method has one signature.
 * @param - patient_id - The patient ID based on which the
 * encounters have to be fetched.
 ****************************************/

export function getPatient_Encounters(patient_id, organization_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetEncounter', {
				patient_id: patient_id,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPatient_Episodes
 * Description: exports an action to get the episodes
 * for a particular patient from the backend.
 * The data received is returned via promise which
 * can be used later for manipulation.
 * Method has one signature.
 * @param - episodeIDs - All The episode IDs separated by comma
 * based on which the episodes have to be fetched.
 ****************************************/

export function getPatient_Episodes(episodeIDs) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetEpisode', {
				episodeID: episodeIDs,
			})
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getEncounterDocuments
 * Description: exports an action to get the encounter documents
 * for a particular encounter from the backend.
 * The data received is returned via promise which
 * can be used later for manipulation.
 * Method has one signature.
 * @param - encounter_id - The encounter ID based on which
 * the encounter documents have to be fetched.
 ****************************************/

export function getEncounterDocuments(encounter_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetEncounterDocuments', {
				encounter_id: encounter_id,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: setPatientDetailData
 * Description: exports an action to set the manipulated Patient Detail data
 * in the patients reducer.
 * Method has one signature.
 * @param - patientDetail - manipulated data that has to be set in
 * the patients reducer.
 ****************************************/

export function setPatientDetailData(patientDetail) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_PATIENT_DETAIL_DATA,
			payload: patientDetail,
		});
	};
}

/****************************************
 * Function Name: setPatientName
 * Description: exports an action to set Patient name
 * to be shown on Patient Detail page.
 * Method has one signature.
 * @param - patient_name - patient name that has to be set in
 * the patients reducer.
 ****************************************/

// export function setPatientName(patient_name) {
// 	return (dispatch) => {
// 		dispatch({
// 			type: actionTypes.SET_PATIENT_NAME,
// 			payload: patient_name,
// 		});
// 	};
// }

/****************************************
 * Function Name: getOrgHierarchyLevels
 * Description: exports an action to get the organization hierarchy levels
 * from the bcakend.
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getOrgHierarchyLevels() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetOrgHierarchyLevels', {})
				.then((res) => {
					/*dispatch({
				type: actionTypes.GET_ORGANIZATION_HIERARCHY,
				payload: res.data.data
			});*/
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getOrganizationDetails
 * Description: exports an action to get the organization details of a
 * particular organization from the backend.
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - organization_id - The id of the organization for which the details have
 * to be fetched from the backend.
 ****************************************/

export function getOrganizationDetails(organization_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetOrganization', {
				organization_id: organization_id,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getPatientManualAnnotations(m_PatientId, m_annotationLevel) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let data = {
				m_PatientId: m_PatientId,
				m_DocLevelManAnnotation: false,
				pageNumber: 1,
				pageSize: 10,
				m_responseType: 'ConceptWise',
			};
			if (m_annotationLevel) {
				data.m_annotationLevel = m_annotationLevel;
			}

			apiGet('GetPatientManualAnnotations', data)
				.then((res) => {
					dispatch({
						type: actionTypes.GET_PATIENT_MANUAL_ANNOTATION,
						payload: res.data,
					});

					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function AddPatientManualAnnotation(m_PatientId, conceptIds) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let data = {
				m_PatientId: m_PatientId,
				m_ConceptIds: conceptIds,
			};
			apiGet('AddPatientManualAnnotation', data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function DeletePatientManualAnnotations(m_PatientId, conceptIds) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let data = {
				m_PatientId: m_PatientId,
				m_ConceptIds: conceptIds,
			};
			apiGet('DeletePatientManualAnnotations', data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getUserEntityCounts
 * Description: exports an action to get the organization details of a
 * particular organization from the backend.
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - organization_id - The id of the organization for which the details have
 * to be fetched from the backend.
 ****************************************/

export function getUserEntityCounts(query = {}) {
	// let data = organization_id ? { "organizationId": organization_id } : {};
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let forKappaScore = queryString.parse(window.location.search).forKappaScore;
			if (forKappaScore) {
				query.forKappaScore = true;
			}
			apiGet('GetUserEntityCounts', query)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getUserEntityCountsAnnotator
 * Description: exports an action to get the organization details of a
 * particular organization from the backend.
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - organization_id - The id of the organization for which the details have
 * to be fetched from the backend.
 ****************************************/

export function getUserEntityCountsAnnotator(query = {}) {
	// let data = organization_id ? { "organizationId": organization_id } : {};
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetQueueCount', query)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getAlgorithmicProblemList
 * Description:
 ****************************************/
export function getAlgorithmicProblemList(patientId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetAlgorithmicProblemList', {
				patientId: patientId,
			})
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getEncounterByProblemList
 * Description:
 ****************************************/
export function getEncounterByProblemList(patientProblemId, isEnhancedList) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let data = {
				patientProblemId: patientProblemId,
			};
			if (isEnhancedList) {
				data['isEnhancedList'] = true;
			}
			apiGet('GetEncounterByProblemList', data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getExcludedAlgorithmicProblemList
 * Description:
 ****************************************/
export function getExcludedAlgorithmicProblemList(patientId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetExcludedAlgorithmicProblemList', {
				patientId: patientId,
			})
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getPatientAlgorithmicProblemList(sectionData) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('GetPatientAlgorithmicProblemList', sectionData)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getManualAnnotationProjectAttributes() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			// resolve({
			// 	AnnotationProjectId: 1,
			// 	DerivedAnnotationSource: 'Outcome',
			// 	RequiredAnnotationAttribute: 'Progression',
			// 	study_id: '1'
			// });
			apiGet('GetManualAnnotationProjectAttributes', {})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getStudyOutcomes(studyId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			// resolve({
			// 	data: {
			// 		data: [
			// 			{
			// 				ConceptName: 'Asthma',
			// 				ConceptId: 'C00001',
			// 				OutcomeId: 1,
			// 				StudyId: '1'
			// 			}
			// 		]
			// 	}
			// });
			apiGet('GetStudyOutcomes', { m_studyId: studyId })
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getPatientLevelOutcome(patientId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			// resolve({
			// 	data: {
			// 		data: [
			// 			{
			// 				ConceptName: 'Asthma',
			// 				ConceptId: 'C00001',
			// 				OutcomeId: 1,
			// 				StudyId: '1'
			// 			}
			// 		]
			// 	}
			// });
			apiGet('GetPatientLevelOutcome', { m_studyId: studyId })
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function addDerivedAnnotation(outcomeConcepts) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			// resolve({
			// 	data: {
			// 		data: [
			// 			{
			// 				ConceptName: 'Asthma',
			// 				ConceptId: 'C00001',
			// 				OutcomeId: 1,
			// 				StudyId: '1'
			// 			}
			// 		]
			// 	}
			// });
			apiPostForm('AddDerivedAnnotation', outcomeConcepts)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getDerivedManualAnnotations(derivedAnnotationId, patientId, annotationSource) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let temp = {
				DerivedAnnotationId: derivedAnnotationId,
				PatientId: patientId,
				AnnotationLevel: 'Patient',
				AnnotationSource: annotationSource,
				ConceptId: '',
			};
			// resolve({
			// 	data: {
			// 		data: [
			// 			{
			// 				DerivedAnnotationId: 1,
			// 				PatientId: 1,
			// 				AnnotationLevel: 'Patient',
			// 				AnnotationSource: 'Outcome',
			// 				ConceptId: 'C00001',
			// 				Descriptor: '',
			// 				NumericResult: '',
			// 				Progression: 'Worsened',
			// 				Severity: '',
			// 				ManualAnnotationConceptAttributeId: '1'
			// 			},
			// 			{
			// 				DerivedAnnotationId: 2,
			// 				PatientId: 1,
			// 				AnnotationLevel: 'Patient',
			// 				AnnotationSource: 'Text',
			// 				ConceptId: 'C00001',
			// 				Descriptor: '',
			// 				NumericResult: '',
			// 				Progression: '',
			// 				Severity: 'Mild',
			// 				ManualAnnotationConceptAttributeId: 2
			// 			}
			// 		]
			// 	}
			// });
			apiPostForm('GetDerivedManualAnnotations', temp)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getEncounterLevelManualAnnotations(subdocumentId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetEncounterLevelManualAnnotations', {
				subdocumentId: subdocumentId,
			})
				.then((res) => {
					resolve(res);
					// resolve({
					// 	data: {
					// 		severity: null,
					// 		score: 8,
					// 		conceptName: 'Migraine',
					// 		codeType: 'SNOMED',
					// 		conceptId: 'C0149931',
					// 		conceptCode: '37796009',
					// 		progression: null,
					// 		annotationDetails: [
					// 			{
					// 				annotation_id: 6425220,
					// 				user_id: 64,
					// 				subdocument_id: 1061754,
					// 				section_number: 3,
					// 			},
					// 			{
					// 				annotation_id: 6425226,
					// 				user_id: 64,
					// 				subdocument_id: 1061754,
					// 				section_number: 3,
					// 			},
					// 			{
					// 				annotation_id: 6425234,
					// 				user_id: 64,
					// 				subdocument_id: 1061754,
					// 				section_number: 19,
					// 			},
					// 			{
					// 				annotation_id: 6425234,
					// 				user_id: 64,
					// 				subdocument_id: 1061754,
					// 				section_number: 19,
					// 			}
					// 		],
					// 		descriptor: null,
					// 	},
					// 	status: 200,
					// 	statusText: '',
					// 	headers: {
					// 		connection: 'keep-alive',
					// 		'content-length': '429',
					// 		'content-type': 'application/json;charset=UTF-8',
					// 		date: 'Fri, 21 May 2021 13:39:54 GMT',
					// 		'keep-alive': 'timeout=20',
					// 		vary: 'Origin',
					// 	},
					// 	config: {
					// 		transformRequest: {},
					// 		transformResponse: {},
					// 		timeout: 0,
					// 		xsrfCookieName: 'XSRF-TOKEN',
					// 		xsrfHeaderName: 'X-XSRF-TOKEN',
					// 		maxContentLength: -1,
					// 		headers: {
					// 			Accept: 'application/json, text/plain, */*',
					// 		},
					// 		method: 'get',
					// 		params: {
					// 			subdocumentId: '1061754',
					// 			userRole: 'Administrator',
					// 		},
					// 		withCredentials: true,
					// 		url: 'http://localhost:8080/AnalyticsProjectServer/GetEncounterLevelManualAnnotations',
					// 	},
					// 	request: {},
					// });
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function getEncounterLevelAutomatedAnnotations(subdocumentId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetEncounterLevelAutomatedAnnotations', {
				subdocumentId: subdocumentId,
			})
				.then((res) => {
					resolve(res);
					// resolve({
					// 	data: {
					// 		severity: null,
					// 		score: 7,
					// 		conceptName: 'Migraine',
					// 		codeType: 'SNOMED',
					// 		conceptId: 'C0149931',
					// 		conceptCode: '37796009',
					// 		progression: null,
					// 		annotationDetails: [
					// 			{
					// 				subdocument_id: 1061754,
					// 				end_offset: 800,
					// 				begin_offset: 789,
					// 				section_number: 5
					// 			},
					// 			{
					// 				subdocument_id: 1061754,
					// 				end_offset: 70,
					// 				begin_offset: 62,
					// 				section_number: 19
					// 			}
					// 		],
					// 		descriptor: null
					// 	},
					// 	status: 200,
					// 	statusText: '',
					// 	headers: {
					// 		connection: 'keep-alive',
					// 		'content-length': '342',
					// 		'content-type': 'application/json;charset=UTF-8',
					// 		date: 'Fri, 21 May 2021 13:44:09 GMT',
					// 		'keep-alive': 'timeout=20',
					// 		vary: 'Origin'
					// 	},
					// 	config: {
					// 		transformRequest: {},
					// 		transformResponse: {},
					// 		timeout: 0,
					// 		xsrfCookieName: 'XSRF-TOKEN',
					// 		xsrfHeaderName: 'X-XSRF-TOKEN',
					// 		maxContentLength: -1,
					// 		headers: {
					// 			Accept: 'application/json, text/plain, */*'
					// 		},
					// 		method: 'get',
					// 		params: {
					// 			subdocumentId: '1061754',
					// 			userRole: 'Administrator'
					// 		},
					// 		withCredentials: true,
					// 		url: 'http://localhost:8080/AnalyticsProjectServer/GetEncounterLevelAutomatedAnnotations'
					// 	},
					// 	request: {}
					// });
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}
