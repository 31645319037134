/* istanbul ignore file */
import actionTypes from '../actions/actionTypes';

let initialState = {
	doc_name: '',
	annotatedText: '',
	annotationList: [],
	annotationDetail: [],
	manualAnnotationList: [],
	// manualAnnotationDetail: [],
	// m_annotationList: [],
	// doc_conceptTemporality: [],
	rapidannotationList: [],
	// rapidManualDocConceptIDMapping: [],
	automatedAnnotationList: [],
	conceptCategoryList: [],
	severityOptions: [],
	descriptorOptions: [],
	progressionOptions: [],
	numericValueUnitOptions: [],
	medicationUnitOptions: [],
	medicationFormOptions: [],
	medicationRoutesOptions: [],
	conceptCategoriesOptions: [],
	subdocumetDetails: {},
	// subDocManualAnnotationList: {},
	notExperiencedReasons: [],
	notDefinitiveDetails: [],
	rapidList : []
};

//reducer that matches the action type for history actions and changes the state
export const documentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_DOC_NAME: {
			return { ...state, doc_name: action.payload };
		}

		case actionTypes.GET_ANNOTATED_TEXT: {
			return { ...state, annotatedText: action.payload };
		}

		case actionTypes.GET_ANNOTATION_LIST: {
			return { ...state, annotationList: state.annotationList.concat(action.payload) };
		}

		case actionTypes.GET_ANNOTATION_DETAIL: {
			return { ...state, annotationDetail: state.annotationDetail.concat(action.payload) };
		}
		case actionTypes.GET_MANUAL_ANNOTATIONS: {
			return { ...state, manualAnnotationList: action.payload };
		}

		// case actionTypes.GET_MANUAL_ANNOTATION_DETAIL: {
		// 	return { ...state, manualAnnotationDetail: action.payload };
		// }
		// case actionTypes.SET_MANUAL_ANNTATION_LIST: {
		// 	return { ...state, m_annotationList: action.payload };
		// }
		// case actionTypes.GET_DETAIL_DOC_CONCEPT_TEMPORALITY: {
		// 	return { ...state, doc_conceptTemporality: action.payload };
		// }
		case actionTypes.SET_RAPID_MANUAL_ANNOTATION: {
			return { ...state, rapidannotationList: action.payload };
		}
		// case actionTypes.SET_RAPID_MANUAL_DOCUMENT_CONCEPT_MAP: {
		// 	return { ...state, rapidManualDocConceptIDMapping: action.payload };
		// }
		case actionTypes.GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT: {
			return { ...state, automatedAnnotationList: action.payload };
		}
		case actionTypes.GET_CONCEPT_CATEGORIES: {
			return { ...state, conceptCategoryList: action.payload };
		}
		case actionTypes.GET_SEVERITY_VALUES: {
			return { ...state, severityOptions: action.payload };
		}
		case actionTypes.GET_DESCRIPTOR_VALUES: {
			return { ...state, descriptorOptions: action.payload };
		}
		case actionTypes.GET_PROGRESSION_VALUES: {
			return { ...state, progressionOptions: action.payload };
		}
		case actionTypes.GET_NUMERIC_VALUE_UNITS: {
			return {
				...state,
				numericValueUnitOptions: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_MEDICATION_UNITS: {
			return {
				...state,
				medicationUnitOptions: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_MEDCATION_FORMS: {
			return {
				...state,
				medicationFormOptions: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_MEDICATION_ROUTES: {
			return {
				...state,
				medicationRoutesOptions: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_CONCEPT_CATEGORIES_OPTIONS: {
			return {
				...state,
				conceptCategoriesOptions: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_NOT_EXPERIENCED_REASONS : {
			return {
				...state,
				notExperiencedReasons: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_NOT_DEFINITIVE_DETAILS : {
			return {
				...state,
				notDefinitiveDetails: action?.payload?.map((record) => {
					return {
						label: record,
						value: record,
					};
				}),
			};
		}
		case actionTypes.GET_SUBDOCUMENT_DETAIL: {
			let key = Object.keys(action.payload)[0];
			let value = action.payload[key];
			let list = state.subdocumetDetails || {};
			list[key] = value;

			return { ...state, subdocumetDetails: list };
		}

		case actionTypes.GET_RAPID_MANUAL_ANNOTATION : {
			return {...state, rapidList: action.payload}
		}
		// case actionTypes.GET_MANUAL_ANNOTATIONS_SUBDOC: {
		// 	let key = Object.keys(action.payload)[0];
		// 	let value = action.payload[key];
		// 	let list = state.subDocManualAnnotationList || {};
		// 	list[key] = value;

		// 	return { ...state, subDocManualAnnotationList: list };
		// }
		

		default: {
			return state;
		}
	}
};
