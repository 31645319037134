/* istanbul ignore file */
import actions from '../../actions';
import store from '../../store';

export const getLexiconCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getLexiconCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getTemporalOrderingCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getTemporalOrderingCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getGrammarCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getGrammarCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getNegationsCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getNegationsCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getConceptAssociationsCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getConceptAssociationsCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getNECount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getNECount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getConceptSupportCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getConceptSupportCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const setHistory = (location) => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.setHistory(location))
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};



