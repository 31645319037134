import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import { getObjectFromLocal } from '../../../utilities/utils';
import pencilIconBlue from '../../../images/pencil-icon-blue.svg';
import backIcon from '../../../images/bk.png';

import {
	getUserDetails,
	updateProfile,
	getProviderDetails,
	getProviderAdress,
} from './AccountProfileHelper';

function AccountProfile(props) {
	const [userDetails, setUserDetails] = useState({});
	const [edit, setEdit] = useState(props.editMode ? props.editMode : false);
	const [editPic, setEditPic] = useState(false);
	const [errors, setErrors] = useState([]);

	const editProfile = () => {
		setEdit(true);
	};

	const editProfileData = (e) => {
		setUserDetails({
			...userDetails,
			[e.target.name]: e.target.value,
		});
	};

	const alertMassage = () => {
		setEdit(false);
	};

	const closeModal = () => {
		userData();
		setEdit(false);
		setErrors([]);
	};

	const onSubmit = () => {
		let {
			provider_id,
			user_id,
			name,
			phone_number,
			address_lines,
			city,
			state,
			postal_code,
			address_id,
		} = userDetails;
		let errors = [];
		name = name?.trim();
		let sp = name?.indexOf(' ');
		let first_name = name?.substring(0, sp);
		let last_name = name?.substring(sp).trim();

		if (!name?.trim()) {
			errors['name'] = 'Name is required';
		}

		if (/\d/.test(name)) {
			if (!errors['name']) {
				errors['name'] = 'Name cannot contain numeric value';
			}
		} else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
			if (!errors['name']) {
				errors['name'] = 'Name cannot contain special character';
			}
		}

		if (!first_name || !first_name.trim() || !last_name || !last_name.trim()) {
			if (!errors['name']) {
				errors['name'] = 'Please enter full name';
			}
		}

		if (!phone_number?.trim()) {
			errors['phone_number'] = 'Phone number is required';
		}

		if (!/(^[0-9]{10}$)/.test(phone_number)) {
			if (!errors['phone_number']) {
				errors['phone_number'] = 'Phone number should be a valid number';
			}
		}

		if (!address_lines) {
			errors['address_lines'] = 'Address is required';
		}

		if (!city?.trim()) {
			errors['city'] = 'City is required';
		}
		if (/\d/.test(city)) {
			errors['city'] = 'City name cannot contain numeric values';
		}
		if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(city)) {
			errors['city'] = 'City name cannot contain special character ';
		}
		if (!state?.trim()) {
			errors['stateObj'] = 'State is required';
		}
		if (/\d/.test(state)) {
			errors['stateObj'] = 'State name cannot contain numeric values';
		}
		if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(state)) {
			errors['stateObj'] = 'state name cannot contain special character ';
		}
		if (!postal_code?.trim()) {
			errors['postal_code'] = 'Zip code is required';
		}
		if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postal_code)) {
			errors['postal_code'] = 'Zip code is invalid';
		}

		setErrors(errors);
		if (Object.keys(errors).length == 0) {
			let userDetailCriterionString = `${provider_id}:${
				first_name + ' ' + last_name
			},${user_id}:${phone_number.trim()},${address_id}:${address_lines.trim()},${city.trim()},${state.trim()},${postal_code.trim()}`;
			updateProfile(userDetailCriterionString)
				.then((res) => {
					alertMassage();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	useEffect(() => {
		userData();
	}, []);

	const userData = () => {
		let details = {};
		let profileUser = getObjectFromLocal('user');
		getUserDetails().then((res) => {
			let userWithEmail =
				res.data.data &&
				res.data.data.find((element) => {
					return element?.email == profileUser?.email;
				});
			details = { ...details, ...userWithEmail };
			details.name = details.first_name + ' ' + details.last_name;

			getProviderDetails(userWithEmail?.provider_id).then((response) => {
				const provider = { ...response?.data?.data[0] };
				getProviderAdress(userWithEmail?.provider_id).then(
					(res) => {
						setUserDetails({ ...details, ...res.data, ...provider });
					},
					(error) => {
						setUserDetails(details);
					}
				);
			});
		});
	};

	return (
		<div className="tab_modal_table">
			<div className="profileTab">
				{edit ? (
					''
				) : (
					<div
						className="editCohortIcon"
						style={{ textAlign: 'right' }}
						onClick={() => editProfile()}
					>
						<div className="hyperlink">
							<img src={pencilIconBlue} className="App-EditIcon" alt="EditIcon" />{' '}
						</div>{' '}
					</div>
				)}
				<div className="row nopadding profile_Data_list">
					<div className="col-4 image_profile">
						<img src={backIcon} />
						<div className="btn-edit-profile-img"></div>
					</div>
					<div className="col-8 detail_profile_tab">
						<UserHeader
							Title="Username:"
							name="email"
							Value={userDetails.email}
							edit={edit}
						/>
						<UserHeader
							Title="Full name:"
							name="name"
							editValue={editProfileData}
							Value={userDetails.name}
							error={errors['name']}
							edit={edit}
						/>
						<UserHeader
							Title="Email:"
							name="email"
							Value={userDetails.email}
							edit={edit}
						/>
						<UserHeader
							Title="Phone:"
							name="phone_number"
							editValue={editProfileData}
							Value={userDetails.phone_number}
							error={errors['phone_number']}
							edit={edit}
						/>
						<UserHeader
							Title="Address:"
							name="address_lines"
							editValue={editProfileData}
							Value={userDetails.address_lines}
							error={errors['address_lines']}
							edit={edit}
						/>
						<UserHeader
							Title="City:"
							name="city"
							editValue={editProfileData}
							Value={userDetails.city}
							error={errors['city']}
							edit={edit}
						/>
						<UserHeader
							Title="State:"
							name="state"
							editValue={editProfileData}
							Value={userDetails.state}
							error={errors['stateObj']}
							edit={edit}
						/>
						<UserHeader
							Title="Zip code:"
							name="postal_code"
							editValue={editProfileData}
							Value={userDetails.postal_code}
							error={errors['postal_code']}
							edit={edit}
						/>
					</div>
				</div>
				<div className="trialButton addNewTrial">
					{edit || editPic ? (
						<button type="button" className="trialArm" onClick={() => closeModal()}>
							Cancel
						</button>
					) : (
						''
					)}
					{edit || editPic ? (
						<button
							type="button"
							id="submit-btn"
							className="trialArm"
							onClick={onSubmit}
						>
							Save
						</button>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}

AccountProfile.propTypes = {
	editMode: PropTypes.any,
};

export default AccountProfile;

function UserHeader(props) {
	let inputStyling = {
		width: 330,
	};
	return (
		<div className="row">
			<div className="col-3" style={{ marginTop: 8 }}>
				<h4>{props.Title ? props.Title : ''}</h4>
			</div>
			<div className="col-9">
				<h4>
					{!props.edit ? (
						<div style={{ marginTop: 8 }}> {props.Value}</div>
					) : (
						<div className="rdt rdtOpen">
							<input
								type="text"
								value={props.Value}
								name={props.name}
								className="form-control2"
								onChange={(e) => props.editValue && props.editValue(e)}
								style={inputStyling}
							/>
							{props.error ? (
								<div style={inputStyling}>
									<span className="help-block error error3">{props.error}</span>
								</div>
							) : null}
						</div>
					)}
				</h4>
			</div>
		</div>
	);
}
