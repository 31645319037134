/* istanbul ignore file */
import actionTypes from './actionTypes';
import { apiGet, partial } from '../utilities/utils';

let GetSearchConcepts = partial(apiGet, 'SearchConcepts');

/**************************************** 
     * Function Name: getCohortList
     * Description: exports an action to get the cohort list data from the backend
     * The data received gets set in the cohorts reducer which can be used
     * later for manipulation. 
     * Method has no signature.
****************************************/

export function getCohortList(params) {
	
	return dispatch => new Promise(function(resolve, reject) {

		apiGet('GetCohortList', params).then(res => {
			
			dispatch({
				type: actionTypes.GET_COHORT_LIST,
				payload: res.data.data
			});
			resolve(res);
		})
		.catch(err => {
			dispatch({
				type: actionTypes.IS_LOADING,
				payload: false
			})
			console.log(err);
			reject(err);
		});
	})
}

/**************************************** 
     * Function Name: updateCohortList
     * Description: exports an action to get the cohort list data from the backend
     * The data received gets set in the cohorts reducer which can be used
     * later for manipulation. 
     * Method has no signature.
****************************************/

export function updateCohortList(cohortList) {
	return dispatch => {
	  dispatch({
		type: actionTypes.GET_COHORT_LIST,
		payload: cohortList
	  });
	};
  }



/**************************************** 
     * Function Name: getCohort
     * Description: exports an action to get the cohort
     * data from the backend
     * The data received gets set in the cohorts reducer which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

/*export function getCohort(cohort_id) {
	return dispatch => new Promise(function(resolve, reject) {

		apiGet('GetCohort', {
			'cohortObj.cohort_id' : cohort_id
		}).then(res => {
			
			dispatch({
				type: actionTypes.SET_COHORT_PATIENT,
				payload: res.data.data
			});
			resolve(res.data.data);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	})
}*/


/**************************************** 
     * Function Name: getCohortPatient
     * Description: exports an action to get the cohort patients
     * data from the backend
     * The data received gets set in the cohorts reducer which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

export function getCohortPatient(cohort_id) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetCohortPatient', {
			'cohortObj.cohort_id' : cohort_id,
		}).then(res => {
			
			dispatch({
				type: actionTypes.SET_COHORT_PATIENT,
				payload: res.data.data
			});
			resolve(res.data.data);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	})
}

/**************************************** 
     * Function Name: getCohortData
     * Description: exports an action to get the cohort data from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

export function getCohortData(cohort_id) {
	return dispatch => new Promise(function(resolve, reject) {

		apiGet('GetCohortData', {

			'cohortObj.cohort_id' : cohort_id
		}).then(res => {
			
			resolve(res.data);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	})
}

/**************************************** 
     * Function Name: setCohortDetails
     * Description: exports an action to set the manipulated Cohort Detail data 
     * in the cohorts reducer.
     * Method has one signature.
     * @param - cohortData - manipulated data that has to be set in the reducer.
****************************************/

// export function setCohortDetails(cohortData) {
// 	return dispatch => {
// 		dispatch({
// 			type: actionTypes.SET_COHORT_DETAILS,
// 			payload: cohortData
// 		});
// 	}
// }

/**************************************** 
     * Function Name: getCohortCriteria
     * Description: exports an action to get the cohort criterion
     * from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

export function getCohortCriteria(cohort_id) {
	return dispatch => new Promise(function(resolve, reject){

		apiGet('GetCohortCriterion', {

			'cohortObj.cohort_id' : cohort_id

		}).then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: GetConceptForAnnotationByCohortId
     * Description: exports an action to get the cohort criterion
     * from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

export function GetConceptForAnnotationByCohortId(cohort_id) {
	return dispatch => new Promise(function(resolve, reject){

		apiGet('GetConceptForAnnotationByCohortId', {

			'm_cohortId' : cohort_id

		}).then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: getSearchConcepts
     * Description: exports an action to get the Search Concepts
     * from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - concept_id - used to make the api call based on the concept ID.
****************************************/

export function getSearchConcepts(concept_id, event_id) {
	return dispatch => new Promise(function(resolve, reject){
		let searchConceptsForID;

		if(event_id){
			searchConceptsForID = GetSearchConcepts({
				'conceptTableEntry.umls_concept_category': 'All',
				'eventId' : event_id
			});
		} else{
			searchConceptsForID = GetSearchConcepts({
				'conceptTableEntry.umls_concept_category': 'All',
				'conceptTableEntry.concept_id' : concept_id
			});
		}

		searchConceptsForID.then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
		/*apiGet('SearchConcepts', {

			'conceptTableEntry.concept_category': 'All',
			'conceptTableEntry.concept_id' : concept_id

		}).then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});*/
	});
}

/**************************************** 
     * Function Name: setCriterion_Concepts
     * Description: exports an action to set the manipulated data 
     * in the cohorts reducer.
     * Method has one signature.
     * @param - cohortData - manipulated data that has to be set in the reducer.
****************************************/

export function setCriterion_Concepts(cohortData) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_CRITERION_CONCEPTS,
			payload: cohortData
		});
	}
}

/**************************************** 
     * Function Name: deleteCohort
     * Description: exports an action to delete a particular cohort
     * from the backend.
     * The response received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

export function deleteCohort(cohort_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('DeleteCohort', {
			'cohortObj.cohort_id' : cohort_id
		}).then(res => {
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		})
	})
}

/**************************************** 
     * Function Name: getEvent
     * Description: exports an action to get the Events
     * from the backend.
     * The data received is set in the reducer which can be used
     * later for manipulation. 
     * Method has no signature.
****************************************/

export function getEvent(){
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetEvent', {
			'eventType' : 'outcome'
		}).then(res => {
			dispatch({
				type: actionTypes.GET_EVENT,
				payload: res.data.data.reverse()
			});
			resolve(res.data.data.reverse());
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	})
}

/**************************************** 
     * Function Name: getConceptsForText
     * Description: exports an action to get the Search Concepts
     * from the backend based on input entered by the user.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - text - used to make the api call based on the text input.
****************************************/

export function getConceptsForText(text) {
	return dispatch => new Promise((resolve, reject) => { 
		
		let searchConceptsForText = GetSearchConcepts({
			
			'conceptTableEntry.umls_concept_category': 'All',
			'conceptTableEntry.display_name' : text
		});

		searchConceptsForText.then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});

		/*apiGet('SearchConcepts', {

			'conceptTableEntry.concept_category': 'All',
			'conceptsTableEntry.text' : text

		}).then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});*/
	});
}

/**************************************** 
     * Function Name: setCriteriaData
     * Description: dispatches an action to set the Criterion Data
     * in the cohort add and edit pages. This data is manipulated to 
     * show a dropdown to the user on change.
     * Method has one signature.
     * @param - criteriaData - Manipulated data to be set in the reducer.
****************************************/

export function setCriteriaData(criteriaData) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_CRITERIA_DATA,
			payload: criteriaData
		});
	}
}

/**************************************** 
     * Function Name: addCohort
     * Description: dispatches an action to add the Cohort 
     * in the backend. 
     * Method has two signatures.
     * @param - cohort_name - Cohort Name that has to be sent.
     * @param - criterionString - Manipulated string that is required
     * in the backend.
	 * @param - eventIds - Comma seperated list of outcome event ids
****************************************/

export function addCohort(cohort_name, criterionString, eventIds) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('AddCohort', {
			'cohortObj.cohort_name' : cohort_name,
			'criterionString' : criterionString,
			eventIds
		}).then(res => {
			
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err);
		})
	});
}

/**************************************** 
     * Function Name: editCohort
     * Description: dispatches an action to edit the Cohort 
     * in the backend. 
     * Method has two signatures.
     * @param - cohort_name - Cohort Name that has to be sent.
     * @param - criterionString - Manipulated string that is required
     * in the backend.
     * @param - eventIds - Comma seperated list of outcome event ids
****************************************/

export function editCohort(cohort_id, cohort_name, criterionString, eventIds) {
	
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('EditCohort', {
			'cohortObj.cohort_id' : cohort_id,
			'cohortObj.cohort_name' : cohort_name,
			'criterionString' : criterionString,
			eventIds
		}).then(res => {
			resolve(res)
		})
		.catch(err => {
			console.log(err);
			reject(err);
		})
	});
}

/**************************************** 
     * Function Name: getCohortListForPatient
     * Description: exports an action to get the cohort list data for a 
     * particular patient from the backend.
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - patient_id - The id of the patient for which the cohorts list has to be 
     * fetched.
****************************************/

export function getCohortListForPatient(patient_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetCohortListForPatient', {
			m_PatientIdList : patient_id
		}).then(res => {
			dispatch({
				type: actionTypes.SET_PATIENT_COHORTS,
				payload: res.data.data
			});
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			
			reject(err);
		})
	});
}

/**************************************** 
     * Function Name: getCohortListForDocument
     * Description: exports an action to get the cohort list data for a 
     * particular document from the backend.
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - document_id - The id of the document for which the cohorts list has to be 
     * fetched.
****************************************/

export function getCohortListForDocument(document_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetCohortListForDocument', {
			m_DocIdList : document_id
		}).then(res => {

			dispatch({
				type: actionTypes.SET_DOCUMENT_COHORTS,
				payload: res.data.data
			});
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			
			reject(err);
		})
	});
}
	
/**************************************** 
     * Function Name: getCohortListForConcept
     * Description: exports an action to get the cohort list data for a 
     * particular document from the backend.
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - document_id - The id of the document for which the cohorts list has to be 
     * fetched.
****************************************/

export function getCohortListForConcept(concept_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetCohortListForConcept', {
			concept_id : concept_id
		}).then(res => {
			dispatch({
				type: actionTypes.SET_CONCEPT_COHORTS,
				payload: res.data.data
			});
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			
			reject(err);
		})
	});
}

/**************************************** 
     * Function Name: setCohortPatients
     * Description: dispatches an action to set all the Cohort Patients in
     * an array to show the number of patients in the Cohort List Page.
     * Method has one signature.
     * @param - cohortPatients - Manipulated cohort patients list to be set in the reducer.
****************************************/

// export function setCohortPatients(cohortPatients) {
// 	return dispatch => {
// 		dispatch({
// 			type: actionTypes.SET_COHORT_PATIENT,
// 			payload: cohortPatients
// 		})
// 	}
// }

/**************************************** 
     * Function Name: setCohortData
     * Description: dispatches an action to set all the Cohort Patients in
     * an array to show the number of patients in the Cohort List Page.
     * Method has one signature.
     * @param - cohortData - Manipulated cohort patients data to be set in the reducer.
****************************************/

// export function setCohortData(cohortData) {
// 	return dispatch => {
// 		dispatch({
// 			type: actionTypes.SET_COHORT_DATA,
// 			payload: cohortData
// 		})
// 	}
// }

/**************************************** 
     * Function Name: getConceptsForLinks
     * Description: exports an action to get the concept id for the links from
     * the cuid for each and every single link on the data detail page.
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - document_id - The id of the document for which the cohorts list has to be 
     * fetched.
****************************************/

export function getConceptsForLinks(text, cuid) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('SearchConcepts', {
			'conceptTableEntry.umls_concept_category': 'All',
			'conceptTableEntry.display_name': text,
			'conceptTableEntry.concept_id' : cuid
		}).then(res => {

			resolve(res);
		})
		.catch(err => {

			console.log(err);
			reject(err);
		})
	});
}

/**************************************** 
     * Function Name: getCohortManualAnnotations
     * Description: exports an action to get the Cohort Manual Annotations list data from the backend. 
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - m_CohortId - The id of the patient for which the cohorts list has to be 
     * fetched.
****************************************/
// export function getCohortManualAnnotations(m_CohortId) {
// 	return dispatch => new Promise(function(resolve, reject){
// 		apiGet('GetCohortManualAnnotations', {
// 			m_CohortId : m_CohortId,
// 			m_DocLevelManAnnotation: false,
// 			pageNumber: 1,
// 			pageSize: 1
// 		}).then(res => {
		
// 			dispatch({
// 				type: actionTypes.GET_COHORT_MANUAL_ANNOTATIONS,
// 				payload: res.data.data
// 			});
// 			resolve(res.data.data);
// 		})
// 		.catch(err => {
// 			console.log(err);
			
// 			reject(err);
// 		})
// 	});
// }

/**************************************** 
     * Function Name: setCohortManualAnnotations
     * Description: exports an action to set the Cohort Manual Annotations list data from the backend. 
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
****************************************/

// export function setCohortManualAnnotations(cohortsManualAnnotation){
// 	return dispatch => {
// 		dispatch({
// 			type: actionTypes.SET_COHORTS_MANUAL_ANNOTATIONS,
// 			payload: cohortsManualAnnotation
// 		})
// 	}
// }

/**************************************** 
     * Function Name: getPatientForConcept
     * Description: exports an action to get the total Patient data from the backend. 
     * The data received is returned via promise which can be used
     * later for manipulation. 
     * Method has one signature.
     * @param - concept_id - The id of the patient for which the concept list has to be 
     * fetched.
****************************************/

export function getPatientForConcept(concept_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetPatientForConcept', {
			concept_id : concept_id
		}).then(res => {
			dispatch({
				type: actionTypes.GET_PATIENT_FOR_CONCEPT,
				payload: res.data.data
			});
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			
			reject(err);
		})
	});
}


/**************************************** 
     * Function Name: getCohortDocuments
     * Description: exports an action to fetch a particular cohort
     * from the backend.
     * The response received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - cohort_id - used to make the api call based on the cohort ID.
****************************************/

// export function getCohortDocuments(cohort_id) {
// 	return dispatch => new Promise(function(resolve, reject) {

// 		apiGet('GetCohortDocuments', {
// 			'cohortObj.cohort_id' : cohort_id
// 		}).then(res => {
			
// 			dispatch({
// 				type: actionTypes.GET_COHORT_DOCUMENTS,
// 				payload: res.data.data
// 			});
// 			resolve(res.data.data);
// 		})
// 		.catch(err => {
// 			console.log(err);
// 			reject(err);
// 		});
// 	})
// }

/**************************************** 
     * Function Name: setDateRangeDeleteMode
     * Description: Set status of mode of delete dateranges.
	 * This method has one parameter
     * @param - status - boolean value indicating whether to enable or disable
****************************************/
// export function setDateRangeDeleteMode(status){
// 	return dispatch => {
// 		dispatch({
// 			type: actionTypes.SET_DATERANGE_DELETE_MODE,
// 			payload: status
// 		})
// 	}
// }

export function confirmCohortDelete(status) {
	return dispatch => {
		dispatch({
			type: actionTypes.TOGGLE_CONFIRM_DELETE,
			payload: status
		})
	}
}