import { apiGet } from "../../utilities/utils";
import actionTypes from "../../actions/actionTypes";
import store from "../../store";

/****************************************
 * Function Name: getStudyInformation
 * Description: get detail of study information
 * Method has one signature.
 ****************************************/
export function getStudyInformation(studyName) {
  return new Promise((resolve, reject) => {
    apiGet("GetStudyInformation", { m_studyName: studyName })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_INFO,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setHistoryAction(list) {
	store.dispatch({
		type: actionTypes.SET_HISTORY,
		payload: list,
	});
}