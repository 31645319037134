import React from 'react';
import PropTypes from 'prop-types';

function HeaderAccordion(props) {
	return (
		<div
			className={`${props.type == 'header' ? 'header-accordion' : 'subheader-accordion'} ${
				props.className ? props.className : ''
			}`}
		>
			<div className="panel-group" id="accordion">
				<div className="panel-default">
					<div className="panel-heading">
						<h4 className="panel-title" style={{ paddingLeft: props.leftPad }}>
							<span
								className={
									'accordion-toggle episode ' + (props.isOpen ? '' : 'collapsed')
								}
								aria-expanded="true"
								data-toggle="collapse"
								data-parent="#accordion"
								href={'#accordionContainer' + props.identifier}
							>
								{props.handleClick ? (
									<span
										className="hyperlink titleText"
										onClick={() => props.handleClick(props.referenceId)}
									>
										{props.text}
									</span>
								) : (
									<span className="hyperlink titleText">{props.text}</span>
								)}
							</span>
						</h4>
					</div>
					<div
						id={'accordionContainer' + props.identifier}
						className={
							'accordionContainer panel-collapse collapse ' +
							(props.isOpen ? 'show' : '')
						}
					>
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
}

HeaderAccordion.propTypes = {
	children: PropTypes.any,
	className: PropTypes.any,
	handleClick: PropTypes.func,
	identifier: PropTypes.any,
	isOpen: PropTypes.any,
	leftPad: PropTypes.any,
	referenceId: PropTypes.any,
	text: PropTypes.any,
	type: PropTypes.string,
};

export default HeaderAccordion;
