import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { setObjectToLocal } from '../../../utilities/utils';
import auth0 from 'auth0-js';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.svg';
import { getUserRole } from '../Auth0Login/Auth0LoginHelper';
import { withRouter } from 'react-router';

const ForgotPassword = (props) => {
	const [err, setErr] = useState('');
	const [forgot, setForgot] = useState(props.forgotView ? props.forgotView : false);
	const [user, setUser] = useState({
		email: '',
	});
	const [userPlaceholder, setUserPlaceholder] = useState('Email');

	const focusPlaceholder = (e) => {
		setUserPlaceholder('');
	};

	const blurPlaceholder = (e) => {
		setUserPlaceholder('Email');
	};

	useEffect(() => {
		//adds background image to the body
		const element = document.getElementsByTagName('body')[0];
		element?.classList?.add('bg-image');
	}, []);

	useEffect(() => {
		if (props.redAuth?.success) {
			setObjectToLocal('user', { email: user.email });
			//switching the route to Step2 Verification Page after successful authentication.
			// props.history.push(constants.ROUTE_URL + '/step2');
			if (!props?.sidenav?.userRole?.Role) {
				getUserRole().then((resp) => {
					const element = document.getElementsByTagName('body')[0];
					element?.classList?.remove('bg-image');
					if (props?.sidenav?.userRole?.Role === roleAccess.ANNOTATOR) {
						props.history.push(constants.ROUTE_URL + '/dashboard/datalist');
					} else {
						props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
					}
				});
			}
		}
	}, [props.redAuth?.success]);

	useEffect(() => {
		if (props.redAuth?.error) {
			setErr(props.redAuth.error);
		}
	}, [props.redAuth?.error]);

	/****************************************
	 * Function Name: validate.
	 * Description: This function performs the necessary validations on the
	 * front end whenever the user submits a form.
	 * Checks for valid email and if true it makes
	 * a call to the function ForgotPassword to make Api call to Auth0.
	 ****************************************/

	const validate = () => {
		//form validations on the front end
		const { email } = user;
		setErr('');

		if (!email?.trim()) {
			setErr('Email is required.');
			return;
		}

		if (email) {
			//checking the email pattern entered by the user
			let emailReg = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
			let res = emailReg.test(email);
			if (!res) {
				setErr('Provide a valid email.');
				return;
			}
		}
		forgotPassword();
		gotoResetPassword();
	};
	/****************************************
	 * Function Name: updateValue.
	 * Description: This method updates the value of the credentials
	 * from the form input in the state.
	 * Method has one signature.
	 * @param - e - It is event property through which we get the values.
	 ****************************************/
	const updateValue = (e) => {
		//updates the value from the form input
		const _user = {
			...user,
			[e.target.name]: e.target.value,
		};
		setUser(_user);
		setErr('');
	};

	const forgotPassword = () => {
		const { email } = user;
		// For Auth0 Access
		if (constants.ALLOW_AUTH0_ACCESS_URL == 'Y') {
			//Assigning Auth0 credentials from the config file.
			const webAuth = new auth0.WebAuth({
				domain: constants.AUTH0_DOMAIN,
				clientID: constants.AUTH0_CLIENT_ID,
			});
			// Validating the change password api end point for Auth0.
			webAuth.changePassword(
				{
					connection: 'Username-Password-Authentication',
					email: email,
				},
				(err, authResult) => {
					if (err) {
						setErr(err.error_description);
					}
				}
			);
		}
	};
	/****************************************
	 * Function Name: handleKeyPress.
	 * Description: This function handles form submit when Enter key is pressed.
	 * Checks if enter key is pressed and if true it calls
	 * the function validate to perform validations.
	 ****************************************/
	const handleKeyPress = (target) => {
		//check if enter is pressed.
		if (target.charCode == 13) {
			validate();
		}
	};

	const gotoResetPassword = () => {
		setForgot(!forgot);
	};

	const gotoLogIn = () => {
		props.history.push(`${constants.ROUTE_URL}/`);
	};

	const { email } = user;

	return (
		<div className="login-container">
			{!forgot ? (
				<>
					<div className="login forgot-wrapper">
						<div className="forgot-header">
							<div className="logo-wrapper">
								<img className="logo_img" src={logo} alt="Verantos_Logo" />
							</div>
							<span className="login-text">Forgot password?</span>
						</div>
						<div className="login_form">
							<p className="verifycode forget">
								Please enter your email address. We will send you an email with a
								link to reset your password.
							</p>
							<br />
							<div className="form-field-control">
								<FormControl
									type="text"
									name="email"
									className="form-field-input"
									id="email"
									onClick={focusPlaceholder}
									onBlur={blurPlaceholder}
									placeholder=" "
									onChange={updateValue}
									autoFocus
									onKeyPress={handleKeyPress}
									value={email}
								/>
								<label
									htmlFor="email"
									id="email-label"
									className="form-field-label"
								>
									Email
								</label>
							</div>

							<div className="login-error_field">{err}</div>
							<p className="submit send_password">
								<Button
									type="submit"
									name="commit"
									value="Sign in"
									id="login"
									className="btn-forgot-pass"
									onClick={validate}
								>
									Submit
								</Button>
							</p>
							<p>
								<Link to={`${constants.ROUTE_URL}/`} className="forgot">
									Back to login
								</Link>
							</p>
						</div>
					</div>
				</>
			) : (
				<div className="login">
					<div className="forgot-header">
						<div className="logo-wrapper">
							<img className="logo_img" src={logo} alt="Verantos_Logo" />
						</div>
						<span className="login-text">Forgot password?</span>
					</div>

					<div className="login_form">
						<p className="verifycode forget">
							Password reset instructions have been sent to <br />
							{user.email}. Please check your e-mail and follow the instructions to
							set your new password.
						</p>
						<p className="submit send_password">
							<Button type="submit" onClick={gotoLogIn}>
								Ok
							</Button>
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

ForgotPassword.propTypes = {
  forgotView: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  redAuth: PropTypes.shape({
    error: PropTypes.any,
    success: PropTypes.any,
  }),
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(ForgotPassword));
