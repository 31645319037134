/* istanbul ignore file */
/* eslint-disable react/wrap-multilines */
import { apiGet } from "../../utilities/utils";
import actionTypes from "../../actions/actionTypes";
import store from "../../store";
import actions from "../../actions";

/** **************************************
 * Function Name: getUserCohortAnnotationCount
 * Description: get user cohort detail.
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const getUserCohortAnnotationCount = (cohortId, batchType, batchId) => {
  return new Promise((resolve, reject) => {
    apiGet("GetUserCohortAnnotationCount", {
      m_cohortId: cohortId,
      m_batchType: batchId ? batchId : batchType,
    })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_VALIDITY,
          payload: res.data.data
          //  res.data.data,
          //GetUserCohortAnnotationCount.data,
        });

        resolve(res.data.data);
        // resolve(GetUserCohortAnnotationCount.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

export const searchConceptsById = (conceptIds) => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(actions.searchConceptsById(conceptIds))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// export const searchConceptsById = (conceptIds, eventIds) => {
// 	return new Promise((resolve, reject) => {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };
/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getEvent = (batchType) => {
  return new Promise((resolve, reject) => {
    apiGet("GetEvent", {
      eventType: "outcome",
      m_batchType: batchId ? batchId : batchType,
    })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_EVENT,
          payload: res.data.data.reverse(),
        });
        resolve(res.data.data.reverse());
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
/** **************************************
 * Function Name: updateStudyValidation
 * Description: get detail of document based on document_concept_id or annotation id.
 * Method has one signature.
 ****************************************/
export const updateStudyValidation = (updatedStudyValidationValues) => {
  store.dispatch({
    type: actionTypes.UPDATE_STUDY_VALIDITY,
    payload: updatedStudyValidationValues,
  });
};
/****************************
 * Function Name: setSideNavList
 * Description: exports an action to set the side nav list
 * with the listitem components to be display the history to the user.
 * The data received gets set in the sidenav reducer which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - list - An Array having a list of React Components which need to
 * be rendered to the SideNav.
 *****************************/

export const setSideNavList = (list) => {
  store.dispatch({
    type: actionTypes.SET_SIDE_NAVLIST,
    payload: list,
  });
};
/** **************************************
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const getStudyDetail = (study_id) => {
  return new Promise((resolve, reject) => {
    apiGet("GetStudyDetail", {
      "m_StudyObj.study_id": study_id,
    })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_DETAIL,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** **************************************
 * Function Name: GetCohortDetailsForStudyValidity
 * Description: get kappa score for cohort.
 * Method has one signature.
 ****************************************/

export const GetCohortDetailsForStudyValidity = (cohortId, batchType, batchId) => {
  return new Promise((resolve, reject) => {
    apiGet("GetCohortDetailsForStudyValidity", {
      m_CohortId: cohortId,
      m_batchType: batchId ? batchId : batchType,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/** **************************************
 * Function Name: getDocumentConceptAnnotationResults
 * Description: get user document based on cohort and concept.
 * Method has one signature.
 ****************************************/
export const getDocumentConceptAnnotationResults = (
  cohortId,
  conceptId,
  m_studyArmType,
  eventId,
  Is_concept_value_result_type,
  m_conceptSeverity,
  batchType,
  batchId
) => {
  return new Promise((resolve, reject) => {
    apiGet("GetDocumentConceptAnnotationResults", {
      m_cohortIdLong: cohortId,
      m_conceptId: conceptId,
      m_studyArmType: m_studyArmType,
      m_eventId: eventId,
      Is_concept_value_result_type: Is_concept_value_result_type,
      m_conceptSeverity: m_conceptSeverity,
      m_batchType: batchId ? batchId : batchType,
    })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_VALIDITY,
          payload: res.data.payload,
        });
        resolve(res.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/** **************************************
 * Function Name: GetCohortKappaScores
 * Description: get kappa score for cohort.
 * Method has one signature.
 ****************************************/

export const GetCohortKappaScores = (cohortId, batchType, batchId) => {
  return new Promise((resolve, reject) => {
    apiGet("GetCohortKappaScores", {
      m_CohortId: cohortId,
      m_batchType: batchId ? batchId : batchType, 
    })
      .then((res) => {
        resolve(res.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setHistoory = (list) => {
  store.dispatch({
    type: actionTypes.SET_HISTORY,
    payload: list,
  });
};
/****************************************
 * Function Name: setDocName
 * Description: exports an action to set the document name
 * in the reducer.
 * Method has one signature.
 * @param - doc_name - Document Name that has to be set in the reducer.
 ****************************************/

export const setDocName = (doc_name) => {
  store.dispatch({
    type: actionTypes.SET_DOC_NAME,
    payload: doc_name,
  });
};

export const getStudyValidationCriteria = (study_id) => {
  const value = localStorage.getItem(study_id + "studyCriteria");
  return value ? JSON.parse(value) : "";
};

/****************************************
 * Function Name: getStudyInformation
 * Description: get detail of study information
 * Method has one signature.
 ****************************************/
export function getStudyInformation(studyName) {
  return new Promise((resolve, reject) => {
    apiGet("GetStudyInformation", { m_studyName: studyName })
      .then((res) => {
        store.dispatch({
          type: actionTypes.GET_STUDY_INFO,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/** **************************************
 * Function Name: refreshStudyValidation
 * Description: refreshStudyValidation
 * Method has one signature.
 ****************************************/

export function refreshStudyValidation(generatedReport, batchType, batchId) {
  return new Promise((resolve, reject) => {
    apiGet("RefreshStudyValidation", {
      generateReport: generatedReport,
      m_batchType: batchId ? batchId : batchType,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
