import PropTypes from 'prop-types';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../../actions';
import queryString from 'query-string';
import { setHistory, setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HeaderAccordion from '../../../components/HeaderAccordion/HeaderAccordion';
import { setIsLoading } from '../../../actions/common';
import {
	GetPatientManualAnnotationsWithConflictsStudys,
	getStudyDetail,
	getUserDetails,
	searchConceptsById,
	resolvePatientAnnotationConflicts,
	resolveManualAnnotationConflicts,
	getDerivedManualAnnotations,
	getDescriptorValues,
	getSeverityValues,
	getProgressionValues,
	getManualAnnotationProjectAttributes,
} from './DataPLAStudyResolveHelper';

import PaginationComponent from '../../../common/Pagination/PaginationComponent';

const TextLevelAnnotation = lazy(() =>
	import(
		/* webpackChunkName: "TextLevelAnnotation" */ '../../../components/TextLevelAnnotation/TextLevelAnnotation'
	)
);
const PatientLevelConflicts = lazy(() =>
	import(
		/* webpackChunkName: "PatientLevelConflicts" */ '../../../components/PatientLevelConflicts/PatientLevelConflicts'
	)
);
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
	// img: <img src={constants.IMG_URL+'data_icon.png'} className={'App-DataListImage'} alt={'Data List'} style={{width: '20px', marginRight: '5px'}}/>
};

const DataPLAStudyResolve = (props) => {
	const [doc_name, setDoc_name] = useState('');
	const [study_name, setStudy_name] = useState('');
	const [conceptName, setConceptName] = useState('');
	const [PatientLevelAnnotation, setPatientLevelAnnotation] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [totalPatientCount, setTotalPatientCount] = useState(0);
	const [annotationAttribute, setAnnotationAttribute] = useState('');
	const [annotationProps, setAnnotationProps] = useState(null);
	const [patientId, setPatientId] = useState('');

	let { docId } = props.match.params;
	let { loading } = props.loading;
	let concept_id = queryString.parse(props?.location?.search).concept_id;

	useEffect(() => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		setIsLoading(true);
		getUserDetails().then((res) => {});

		getStudyDetail(study_id).then((studyDetail) => {
			setStudy_name(studyDetail.study_name);

			if (concept_id) {
				searchConceptsById(concept_id).then((resp) => {
					if (resp) {
						setConceptName(resp[0].text);
					}
					if (concept_id && study_id) {
						let filterLabel = {
							page: 'datastudyreview',
							options: [
								{
									filterLabel: 'Study',
									name: studyDetail.study_name,
									fixed: true,
								},
								{
									filterLabel: 'Concept',
									name: conceptName,
								},
							],
						};
						setFilterLabel(filterLabel);
					}
				});
			} else {
				let filterLabel = {
					page: 'datastudyreview',
					options: [
						{
							filterLabel: 'Study',
							name: studyDetail.study_name,
							fixed: true,
						},
					],
				};
				setFilterLabel(filterLabel);
			}
		});
		if (!props.redUsermanagement || props.redUsermanagement?.userList?.length == 0) {
			getUserDetails();
		}
		GetPatientManualAnnotationsWithConflictsStudy(study_id, 1, 10);
	}, []);

	useEffect(() => {
		if (props?.redDocument?.descriptorOptions?.length == 0) {
			getDescriptorValues();
		}
		if (props?.redDocument?.progressionOptions?.length == 0) {
			getSeverityValues();
		}
		if (props?.redDocument?.severityOptions?.length == 0) {
			getProgressionValues();
		}
		setIsLoading(true);
		getManualAnnotationProjectAttributes().then((resp) => {
			setAnnotationAttribute(resp?.data?.data?.RequiredAnnotationAttribute || '');
			setIsLoading(false);
		});
		if (patientId && props.derivedAnnotationId) {
			getDerivedManualAnnotations(props.derivedAnnotationId, patientId, 'Outcome').then(
				(resp) => {
					setAnnotationProps(resp.data.data[0] || []);
				}
			);
		}
	}, []);

	const GetPatientManualAnnotationsWithConflictsStudy = (study_id, pageNo, recordsPerPage) => {
		setIsLoading(true);
		GetPatientManualAnnotationsWithConflictsStudys(study_id, pageNo, recordsPerPage).then(
			(res) => {
				setIsLoading(false);
				if (pageNo == 1 && res?.data?.payload) {
					setPatientLevelAnnotation(res?.data?.payload);
					setTotalPatientCount(
						res.data.totalRecords ? res.data.totalRecords : res.data.payload.length
					);
				} else {
					if (res?.data?.payload) {
						setPatientLevelAnnotation(res.data.payload);
					}
				}
			}
		);
	};

	/****************************************
	 * Function Name: gotoPage
	 * Description: Switches to different pages from pagination.
	 * @param pageNo Page number
	 ****************************************/
	const gotoPage = (pageNo, recordsPerPage) => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		setPageNo(pageNo);
		setRecordsPerPage(recordsPerPage);
		GetPatientManualAnnotationsWithConflictsStudy(study_id, pageNo, recordsPerPage);
	};

	const handleRadioPatientClick = (
		annotation,
		annotationList,
		index,
		conflictIndex,
		conceptIndex,
		patientIndex
	) => {
		let refPatientLevelAnnotation = PatientLevelAnnotation;
		let selectedConflict =
			refPatientLevelAnnotation[patientIndex].PatientAnnotationDetail[conceptIndex]
				.PatientLevelAnnotation[conflictIndex];
		let selectConflictStatus = annotation.isChecked;

		if (index == 0) {
			selectedConflict.AnnotationDetail[0].isChecked = !selectConflictStatus;
			selectedConflict.AnnotationDetail[1].isChecked = selectConflictStatus;
		} else {
			selectedConflict.AnnotationDetail[0].isChecked = selectConflictStatus;
			selectedConflict.AnnotationDetail[1].isChecked = !selectConflictStatus;
		}
		setPatientLevelAnnotation(refPatientLevelAnnotation);
	};

	const handleRadioTextClick = (
		annotation,
		annotationList,
		index,
		conflictIndex,
		conceptIndex,
		patientIndex
	) => {
		let refPatientLevelAnnotation = PatientLevelAnnotation;
		let selectedConflict =
			refPatientLevelAnnotation[patientIndex].PatientAnnotationDetail[conceptIndex]
				.TextAnnotation[conflictIndex];
		let selectConflictStatus = annotation.isChecked;

		if (index == 0) {
			selectedConflict.AnnotationDetail[0].isChecked = !selectConflictStatus;
			selectedConflict.AnnotationDetail[1].isChecked = selectConflictStatus;
		} else {
			selectedConflict.AnnotationDetail[0].isChecked = selectConflictStatus;
			selectedConflict.AnnotationDetail[1].isChecked = !selectConflictStatus;
		}
		setPatientLevelAnnotation(refPatientLevelAnnotation);
	};

	const saveClicked = () => {
		let selectedPatientConflict = [];
		let selectedTextConflict = [];
		PatientLevelAnnotation?.map((patient) => {
			patient?.PatientAnnotationDetail?.map((concept) => {
				concept?.TextAnnotation?.map((pla) => {
					pla.AnnotationDetail.map((items, index) => {
						if (items.isChecked) {
							let notSelectedIndex = index == 0 ? '1' : '0';
							let temp = {
								selected_annotation: items.annotation_id.toString(),
								annotationList:
									pla.AnnotationDetail[notSelectedIndex].annotation_id.toString(),
							};
							selectedTextConflict.push(temp);
						}
					});
				});
				concept?.PatientLevelAnnotation?.map((pla) => {
					pla.AnnotationDetail?.map((items, index) => {
						if (items.isChecked) {
							let temp = {
								Patient: patient.PatientId,
								ConceptId: concept.ConceptID,
								AnnotatorIds:
									`${pla.AnnotationDetail[0].annotator_id 
									},${ 
									pla.AnnotationDetail[1].annotator_id}`,
								SelectedAnnotatorId: items.annotator_id,
								IsPresent: items.is_present,
								AnnotationSource: pla.AnnotationSource || '',
							};
							selectedPatientConflict.push(temp);
						}
					});
				});
			});
		});

		let resolvePromise = [];
		if (selectedPatientConflict.length > 0) {
			resolvePromise.push(resolvePatientAnnotationConflicts(selectedPatientConflict));
		}
		if (selectedTextConflict.length > 0) {
			resolvePromise.push(resolveManualAnnotationConflicts(selectedTextConflict));
		}
		Promise.all(resolvePromise).then((res) => {
			let study_id = queryString.parse(props?.location?.search).study_id;
			let { docId } = props.match.params;
			// setQueryParams({
			// 	pathname: constants.ROUTE_URL + '/dashboard/dataplastudyreview/' + docId +'?study_id='+study_id
			// });
			props.history.push(
				`${constants.ROUTE_URL 
					}/dashboard/dataplastudyreview/${ 
					docId 
					}?study_id=${ 
					study_id}`
			);
		});

		// props.dispatch(actions.resolvePatientAnnotationConflicts(selectedPatientConflict)).then(annotation => {
		// 	let study_id = queryString.parse(props?.location?.search).study_id;
		// 	let { docId } = props.match.params;
		// 	setQueryParams({
		// 		pathname: constants.ROUTE_URL + '/dashboard/dataplastudyreview/' + docId +'?study_id='+study_id
		// 	});
		// });
	};

	const gotoPatientDetail = (patientId) => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/${patientId}`);
	};

	const cancelClicked = () => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		let { docId } = props.match.params;
		props.history.push(
			`${constants.ROUTE_URL}/dashboard/dataplastudyreview/${docId}?study_id=${study_id}`
		);
	};

	const gotoStudyDetail = () => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		props.history.push(`${constants.ROUTE_URL}/dashboard/studydesign/${study_id}`);
	};

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<div className="document-header-container">
					<span> Manual annotation conflicts: </span>
					<span className="hyperlink" onClick={gotoStudyDetail}>
						{study_name}
					</span>
				</div>
			</ErrorBoundary>

			{PatientLevelAnnotation &&
				PatientLevelAnnotation?.map((patient, patientIndex) => {
					return (
						<ErrorBoundary>
							<HeaderAccordion
								type={'header'}
								identifier={patient.PatientId}
								referenceId={patient.PatientId}
								text={patient.PatientIdentifier}
								leftPad={20}
								isOpen={true}
								handleClick={gotoPatientDetail}
							>
								{patient.PatientAnnotationDetail &&
									patient.PatientAnnotationDetail?.map(
										(concept, conceptIndex) => {
											return (
												<HeaderAccordion
													type={'subheader'}
													identifier={
														patient.PatientId + concept.ConceptID
													}
													referenceId={concept.ConceptID}
													text={concept.ConceptName}
													leftPad={40}
												>
													{concept.TextAnnotation &&
														concept.TextAnnotation.length > 0 && (
															<Suspense
																fallback={
																	<div className="defaultSpinner" />
																}
															>
																<TextLevelAnnotation
																	forResolve={true}
																	concept={concept}
																	annotations={
																		concept.TextAnnotation
																	}
																	identifier={
																		patient.PatientId +
																		concept.ConceptID
																	}
																	handleRadioClick={(
																		annotation,
																		annotationList,
																		index,
																		conflictIndex
																	) =>
																		handleRadioTextClick(
																			annotation,
																			annotationList,
																			index,
																			conflictIndex,
																			conceptIndex,
																			patientIndex
																		)
																	}
																></TextLevelAnnotation>
															</Suspense>
														)}
													{concept.PatientLevelAnnotation &&
														concept.PatientLevelAnnotation.length >
															0 && (
															<Suspense
																fallback={
																	<div className="defaultSpinner" />
																}
															>
																<PatientLevelConflicts
																	forResolve={true}
																	concept={concept}
																	identifier={
																		patient.PatientId +
																		concept.ConceptID
																	}
																	annotations={
																		concept.PatientLevelAnnotation
																	}
																	requiredAnnotationAttribute={
																		annotationAttribute
																	}
																	listAnnoation={annotationProps}
																	handleRadioClick={(
																		annotation,
																		annotationList,
																		index,
																		conflictIndex
																	) =>
																		handleRadioPatientClick(
																			annotation,
																			annotationList,
																			index,
																			conflictIndex,
																			conceptIndex,
																			patientIndex
																		)
																	}
																></PatientLevelConflicts>
															</Suspense>
														)}
												</HeaderAccordion>
											);
										}
									)}
							</HeaderAccordion>
						</ErrorBoundary>
					);
				})}
			{totalPatientCount > 0 && (
				<PaginationComponent
					totalItemsCount={totalPatientCount}
					gotoPage={(pageNo, recordsPerPage) => gotoPage(pageNo, recordsPerPage)}
					currentPage={pageNo}
				/>
			)}
			<div className="datarapid-buttoncontainer">
				<div className="col-sm-6 text-right trialButton addNewTrial">
					<button
						data-test="cancel-btn"
						type="button"
						className="trialArm"
						onClick={cancelClicked}
					>
						Cancel
					</button>
					<button
						data-test="save-btn"
						type="button"
						className="trialArm"
						onClick={saveClicked}
					>
						Save
					</button>
				</div>
			</div>

			{/* <SubAccordion subdata={concept.PatientAnnotationDetail}/> */}
		</div>
	);
};

DataPLAStudyResolve.propTypes = {
	derivedAnnotationId: PropTypes.any,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
	redDocument: PropTypes.shape({
		descriptorOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		progressionOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		severityOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
	redUsermanagement: PropTypes.shape({
		userList: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataPLAStudyResolve);
