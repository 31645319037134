/* istanbul ignore file */
import store from '../../../store';
import actions from '../../../actions';

export const getSubdocumentById = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getSubdocumentById(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserManualAnnotationsWithoutFlag = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getUserManualAnnotationsWithoutFlag(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserDetails = (userIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getUserDetails(userIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const searchConceptsById = (distinctConcept) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(distinctConcept))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const resolveManualAnnotationConflicts = (resolveAnnotationsList) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.resolveManualAnnotationConflicts(resolveAnnotationsList))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
