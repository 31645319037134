/* istanbul ignore file */
import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export function getUserDetails(userIdList) {
	return new Promise(function (resolve, reject) {
		let params = {};
		if (userIdList) {
			params.userIdList = userIdList;
		}

		apiGet('FetchUserDetails', params)
			.then((res) => {
				resolve(res);
               
				store.dispatch({
					type: actionTypes.GET_USER_DETAILS,
					payload: res.data.data,
				});
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
}
export function updateProfile(userDetailCriterionString) {
	return new Promise(function (resolve, reject) {
		apiGet('UpdateUserDetails', {
			userDetailCriterionString: userDetailCriterionString,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function getProviderDetails(provider_id) {
	return new Promise(function (resolve, reject) {
		apiGet('FetchProviderDetails', {
			provider_id: provider_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getProviderAdress(provider_id) {
	return new Promise(function (resolve, reject) {
		apiGet('FetchProviderAddress', {
			provider_id: provider_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
