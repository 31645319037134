/* istanbul ignore file */
import { apiGet } from '../../utilities/utils';
import queryString from 'query-string';
import store from '../../store';
import actionTypes from '../../actions/actionTypes';

export function getUserEntityCounts(query = {}) {
	return new Promise(function (resolve, reject) {
		let forKappaScore = queryString.parse(window.location.search).forKappaScore;
		if (forKappaScore) {
			query.forKappaScore = true;
		}
		apiGet('GetUserEntityCounts', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export const getUserDetails = (userIdList) => {
	return new Promise(function (resolve, reject) {
		let params = {};
		if (userIdList) {
			params.userIdList = userIdList;
		}
		apiGet('FetchUserDetails', params)
			.then((res) => {
				resolve(res);
				store.dispatch({
					type: actionTypes.GET_USER_DETAILS,
					payload: res.data.data,
				});
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
};
