import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import ModalComponent from "../../common/ModalComponent/ModalComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import CheckBox from "../../common/CheckBox/CheckBox";

const ReProcessBatches = forwardRef((props, ref) => {
  const [open, setOpen] = useState(props.forceOpen ? true : false);
  const [className, setClassName] = useState("testClass");
  const [reProcessingList, setReProcessingList] = useState([]);

  useImperativeHandle(ref, () => {
    handleClick: handleClick;
  });

  useEffect(() => {
    if (props.open) {
      props.open({
        handleClick: () => handleClick(),
      });
    }
    return () => {
      if(props.open){
				props.open(null);
			}
    };
  }, []);

  useEffect(() => {
	setReProcessingList(props.reProcessBatchList)
  }, [props.reProcessBatchList])
  
  const cancelClick = (event) => {
    setClassName("testClass");
    setOpen(false);
    props.rootClosexit(event);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(true);
    setClassName("animated shake Data-Management");
    setTimeout(() => setClassName("Data-Management"), 1000);
  };

  const okClick = (event) => {
    setOpen(false);
    setClassName("Data-Management");
    props.confirmClick(reProcessingList);
  };
  
  const okEncounterNLP = (event) => {
    setOpen(false);
    setClassName("Data-Management");
    props.confirmEncounterNLP(reProcessingList);
  };

  const okDiscreteProcessing = (event) => {
    setOpen(false);
    setClassName("Data-Management");
    props.confirmDiscreteProcessing(reProcessingList);
  };

  const getStatusText = (studyInfo) => {
    if (studyInfo.status === props.progressStatus.ReprocessComplete) {
      if (!studyInfo.batch_name.includes("prediction")) {
        return "Manual annotation project created";
      } else {
        return studyInfo.status;
      }
    } else {
      return studyInfo.status;
    }
  };

  const handleCheck = (event, index) => {
    let _reprocessBatchList = Object.assign([], props.reProcessBatchList);
    _reprocessBatchList.map((list) => {
      if (list.batch_name === event.target.value) {
        return (list.isChecked = event.target.checked);
      }
    });
	console.log(_reprocessBatchList)
    setReProcessingList(_reprocessBatchList);
  };

  return (
    <div>
      <ErrorBoundary>
        <ModalComponent
          open={open}
          backdrop={true}
          content={
			<>
			<div className="modal-heading headerbar product-setting admin">
				<div
					style={{
						display: 'inline-block',
						width: '50%',
						paddingTop: 4,
						paddingLeft: 0,
						margin: 0
					}}
				>
					Select batches to re-process
				</div>
			</div>
            <div className="jconfirm-box-container" style={{ padding: "20px" }}>
              <div>
                <table className="tbl-study-admin study-status" style={{ marginTop: 20 }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Study name</th>
                      <th>Total patient records</th>
                      <th>Number of patients</th>
                      <th>Number of encounters</th>
                      <th>Batch</th>
                      <th>NLP version</th>
                      <th>Last processed</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.reProcessBatchList.map((studyInfo, index) => (
                      <tr
                        key={index}
                        // className={props.getStatusClass(studyInfo.status)}
                      >
                        <td>
                         <li
							id={`${studyInfo.batch_name}`}
							style={{ paddingLeft: '30px', paddingBottom: '25px' }}
							className="custom-control custom-checkbox "
						>
							<CheckBox
								id={index}
								onClick={(event) =>
									handleCheck(
										event,
										index
									)
								}
								// name={studyInfo.batch_name}
								value={studyInfo.batch_name}
								checked={studyInfo.isChecked}
							/>
						</li>
                        </td>
                        <td>{studyInfo.studyName}</td>
                        <td>{studyInfo.total_num_docs}</td>
                        <td>{studyInfo.num_patients}</td>
                        <td>{studyInfo.num_encounters}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {studyInfo.batch_name}
                        </td>
                        <td>{studyInfo.NLP_processing_version}</td>
                        <td>{studyInfo.NLP_processing_date}</td>
                        <td>
                          {getStatusText(studyInfo)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="jconfirm-content-pane">
                <div className="jconfirm-buttons">
                  <button
                    type="button"
                    className="btn btn-default cancel-reprocess"
                    onClick={(event) => cancelClick(event)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-default encounter-reprocess"
                    onClick={(event) => okEncounterNLP(event)}
                  >
                    Extract encounter-level annotations from notes
                  </button>
                  <button
                    type="button"
                    className="btn btn-default discrete-reprocess"
                    onClick={(event) => okDiscreteProcessing(event)}
                  >
                    Process structured data
                  </button>
                  <button
                    type="button"
                    className="btn btn-default reprocess"
                    onClick={(event) => okClick(event)}
                  >
                    Extract text-level annotations from notes
                  </button>
                </div>
              </div>
            </div>
			</>
          }
          className={"testClass1"}
          handleClose={handleClose}
        />
      </ErrorBoundary>
    </div>
  );
});

ReProcessBatches.propTypes = {
  confirmClick: PropTypes.func,
  confirmEncounterNLP: PropTypes.func,
  confirmDiscreteProcessing: PropTypes.func,
  forceOpen: PropTypes.any,
  open: PropTypes.func,
  rootClosexit: PropTypes.func,
  text: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(ReProcessBatches));
