/* istanbul ignore file */
import actions from '../../actions';
import store from '../../store';

export const getLexiconCount = () => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getLexiconCount())
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getLexiconsByConceptID = (concept_id, start, length) => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getLexiconsByConceptID(concept_id, start, length))
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const getLexicons = (start, length) => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.getLexicons(start, length))
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const setHistory = (location) => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.setHistory(location))
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

export const setItemDetail = (itemDetail) => {
	return new Promise((resolve, reject) => {
        store.dispatch(actions.setItemDetail(itemDetail))
        .then((resp) => {
            resolve(resp)
        }).catch((error)=>{
            reject(error);
        })
    });
};

