import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.svg";
import {changePasswordAPI} from './ForgotPasswordDBDrivenHelper';

const ForgotPassword = (props) => {
  const [err, setErr] = useState("");
  const [forgot, setForgot] = useState(
    props.forgotView ? props.forgotView : false
  );
  const [user, setUser] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [oldPasswordPlaceholder, setOldPasswordPlaceholder] =
    useState("Old password");
  const [newPasswordPlaceholder, setNewPasswordPlaceholder] =
    useState("New password");
  const [confirmNewPasswordPlaceholder, setConfirmNewPasswordPlaceholder] =
    useState("Re-type new password");
  const [isValidPassword, setisValidPassword] = useState('');

  const blurOldPasswordPlaceholder = (e) => {
    setOldPasswordPlaceholder("Old password");
  };

  const blurNewPasswordPlaceholder = (e) => {
    setNewPasswordPlaceholder("New password");
  };

  const blurConfirmNewPasswordPlaceholder = (e) => {
    setConfirmNewPasswordPlaceholder("Re-type new password");
  };

  useEffect(() => {
    //adds background image to the body
    const element = document.getElementsByTagName("body")[0];
    element?.classList?.add("bg-image");
  }, []);

  /****************************************
   * Function Name: validate.
   * Description: This function performs the necessary validations on the
   * front end whenever the user submits a form.
   * Checks for valid email and if true it makes
   * a call to the function ForgotPassword to make Api call to Auth0.
   ****************************************/

    const validate = () => {
      //form validations on the front end
      const { oldPassword, newPassword, confirmNewPassword } = user;
      setErr("");

      if (!oldPassword?.trim() || !newPassword?.trim() || !confirmNewPassword?.trim()) {
        setErr("All fields are required.");
        return;
      }
		//checking if new password and confirm password is same.
		if(confirmNewPassword.trim()){
			if(confirmNewPassword !== newPassword){
				setErr("Opps password did not match.")
		} else {
			forgotPassword();
		}
	  }
    };

  const updateOldPasswordValue = (e) => {
    const _user = {
      ...user,
      [e.target.name]: e.target.value,
    };
    setUser(_user);
    setErr("");
  };

  const updateNewPasswordValue = (e) => {
	const _user = {
      ...user,
      [e.target.name]: e.target.value,
    };	
	setUser(_user);

	let value = e.target.value;
	let validPasswordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/)
	let ifValid = validPasswordReg.test(value);

	console.log(ifValid);

	!ifValid ? setisValidPassword('invalid-password') : setisValidPassword('')
  };

  const confirmNewPasswordValue = (e) => {
    const _user = {
      ...user,
      [e.target.name]: e.target.value,
    };
    setUser(_user);
    setErr("");
  };

  const forgotPassword = () => {
	changePasswordAPI(oldPassword, newPassword).then((res) => {
		if (res.status == 200){
			gotoResetPassword();
		}
	}).catch((err) => {
		setErr(err)
	});
  }

  /****************************************
   * Function Name: handleKeyPress.
   * Description: This function handles form submit when Enter key is pressed.
   * Checks if enter key is pressed and if true it calls
   * the function validate to perform validations.
   ****************************************/
  const handleKeyPress = (target) => {
    //check if enter is pressed.
    if (target.charCode == 13) {
      validate();
    }
  };

  const gotoResetPassword = () => {
    setForgot(!forgot);
  };

  const { oldPassword, newPassword, confirmNewPassword } = user;

  return (
    <div className="login-container">
      {!forgot ? (
        <>
          <div className="login forgot-wrapper">
            <div className="forgot-header">
              <div className="logo-wrapper">
                <img className="logo_img" src={logo} alt="Verantos_Logo" />
              </div>
              <span className="forgot-password-text">Forgot password?</span>
            </div>
            <div className="login_form">
              <div className="form-field-control">
                <FormControl
                  type="password"
                  name="oldPassword"
                  className="form-field-input"
                  id="oldPassword"
                  onBlur={blurOldPasswordPlaceholder}
                  placeholder=" "
                  onChange={updateOldPasswordValue}
                  value={oldPassword}
                  onKeyPress={handleKeyPress}
                />
                <label
                  htmlFor="oldPassword"
                  id="oldpassword-label"
                  className="form-field-label"
                >
                  Enter your old password
                </label>
              </div>

			  <p className="verifycode forget">
				  Password must be minimum 8 character with at least one uppercase, one lowercase, one special character and one number.
			  </p>
              <div className="form-field-control">
                <FormControl
                  type="password"
                  name="newPassword"
                  className={`form-field-input ${isValidPassword}`}
                  id="newPassword"
                  onBlur={blurNewPasswordPlaceholder}
                  placeholder=" "
                  onChange={updateNewPasswordValue}
                  value={newPassword}
                  onKeyPress={handleKeyPress}
                />
                <label
                  htmlFor="newPassword"
                  id="newpassword-label"
                  className="form-field-label"
                >
                  New password
                </label>
              </div>

              <div className="form-field-control">
                <FormControl
                  type="text"
                  name="confirmNewPassword"
                  className="form-field-input"
                  id="confirmNewPassword"
                  onBlur={blurConfirmNewPasswordPlaceholder}
                  placeholder=" "
                  onChange={confirmNewPasswordValue}
                  value={confirmNewPassword}
                  onKeyPress={handleKeyPress}
                />
                <label
                  htmlFor="confirmNewPassword"
                  id="confirmPassword-label"
                  className="form-field-label"
                >
                  Confirm new password
                </label>
              </div>
			  {err ?
			    <div className="forgot-password-error_field">{err}</div>:
				<div className={"forgot-password-visibility"}>err</div>
			  }
              <p className="submit send_password">
                <Button
                  type="submit"
                  name="commit"
                  value="Sign in"
                  id="login"
                  className="btn-forgot-pass"
                  onClick={validate}
                >
                  Submit
                </Button>
				<p>
                <Link to={`${constants.ROUTE_URL}/`} className="forgot">
                  Back to login
                </Link>
              </p>
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="login">
          <div className="forgot-header">
            <div className="logo-wrapper">
              <img className="logo_img" src={logo} alt="Verantos_Logo" />
            </div>
          </div>

          <div className="login_form">
            <p className="verifycode success-message">
              Password is successfully changed.
            </p>
            <p className="submit send_password">
				<Link to={`${constants.ROUTE_URL}/`} className="forgot">
					Back to login
				</Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

ForgotPassword.propTypes = {
  forgotView: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  redAuth: PropTypes.shape({
    error: PropTypes.any,
    success: PropTypes.any,
  }),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ForgotPassword);
