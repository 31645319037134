/* istanbul ignore file */
import actionTypes from '../../actions/actionTypes';
import { apiGet, apiPostForm } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';

/** **************************************
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const getStudyDetail = (study_id) => {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/****************************************
 * Function Name: getCohortList
 * Description: exports an action to get the cohort list data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getCohortList = (params) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortList', params)
			.then((res) => {
				const option = {
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				};
				store.dispatch(option);
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: getStudyArmList
 * Description: exports an action to get the study list data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export const getStudyArmList = (study_id) => {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyArmList', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_ARM_LIST,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/****************************************
 * Function Name: getCohortCriteria
 * Description: exports an action to get the cohort criterion
 * from the backend.
 * The data received is returned via promise which can be used
 * for manipulation.
 * Method has one signature.
 * @param - cohort_id - used to make the api call based on the cohort ID.
 ****************************************/

export const getCohortCriteria = (cohort_id) => {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortCriterion', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

 export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export const searchConceptsById = (conceptIds, eventIds) => {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };
/** **************************************
 * Function Name: saveStudyCohorts
 * Description: exports an action to get the study cohorts data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const saveStudyCohorts = (studyCohort, cohortIndex) => {
	return new Promise((resolve, reject) => {
		store.dispatch({
			type: actionTypes.SAVE_STUDY_COHORTS,
			cohortIndex: cohortIndex,
			payload: studyCohort,
		});
	});
};
/** **************************************
 * Function Name: clearStudyCohorts
 * Description: exports an action to clear the study cohorts data from the backend
 * The data received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function clearStudyCohorts() {
	return new Promise((resolve, reject) => {
		store.dispatch({
			type: actionTypes.CLEAR_STUDY_COHORTS,
		});
	});
}
