/* istanbul ignore file */
import { combineReducers } from 'redux';
import {authReducer} from './auth';
import {patientReducer} from './patients';
import { documentReducer } from './document';
import { cohortReducer } from './cohorts';
import { studyReducer } from './study';
import {technologyReducer} from './technology';
import {loadingReducer} from './loading';
import {sidenavReducer} from './sidenav';
import {dataManagementReducer} from './datamanagement';
import {userManagementReducer} from './usermanagement';
// import {rapidManualReducer} from './rapidList.js.bak';
// import {summaryAnalyticsReducer} from './summaryanalytics';
import { modalReducer } from './modalReducer';
// import { reducerPatient } from './PatientReducer.js.bak';
// import { cohortReducer as cohortReducer_v2 } from '../containers/CohortList/cohortReducer.js.bak';

// const history = createBrowserHistory();
//import {accountManagementReducer} from './accountmanagement';

//combining all the reducers into one and exporting it.

const appReducer = combineReducers({
  // router: connectRouter(history),
  redAuth: authReducer,
  redPatient: patientReducer,
  redDocument: documentReducer,
  redCohort : cohortReducer,
  redStudy : studyReducer, 
  technology: technologyReducer,
  loading: loadingReducer,
  redSidenav: sidenavReducer,
  redDatamanagement : dataManagementReducer,
  redUsermanagement : userManagementReducer,
  // rapidList : rapidManualReducer,
  // summaryanalytics : summaryAnalyticsReducer,
  redModal: modalReducer,
  // v_patients: reducerPatient,
  // v_cohort: cohortReducer_v2
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
export default rootReducer;
