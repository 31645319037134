import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import cohortIcon from '../../images/cohort_icon.png';
import {
	setCriteriaData,
	getEvent,
	editCohort,
	getConceptsForTextInput,
	addCohort,
	setIsLoading,
} from './AddCohortHelper';

const EditCohortComponent = lazy(() =>
	import(/* webpackChunkName: "EditCohort" */ '../../components/EditCohort/EditCohortComponent')
);

const headerData = {
	title: 'Cohort',
	list: 'add',
	img: <img src={cohortIcon} className={'App-CohortList'} alt={'Cohort List'}></img>,
};

function AddCohort(props) {
	let [errors, setErrors] = useState('');

	useEffect(() => {
		setCriteriaData([]);
	}, []);

	useEffect(() => {
		getEvent();
	}, []);

	const saveCohort = (type, cohortId, cohortName, criterionString, outcomeMeasureIds) => {
		console.log('saveCohort', type, cohortId, cohortName, criterionString, outcomeMeasureIds);
		setIsLoading(true);
		if (type == 'add') {
			addCohort(cohortName, criterionString, outcomeMeasureIds).then(
				(res) => {
					setIsLoading(false);
					if (res.data.code) {
						props.history.push(`${constants.ROUTE_URL}/dashboard/cohortlist`);
					}
				},
				(error) => {
					setIsLoading(false);
					if (error.response.data.code === 400) {
						errors.duplicateCohort = error?.response?.data?.description;
						const _errors = Object.assign({}, errors);
						setErrors(_errors);
					}
				}
			);
		} else if (type == 'update') {
			editCohort(cohortId, cohortName, criterionString, outcomeMeasureIds).then(
				(res) => {
					setIsLoading(false);
					if (res.data.code) {
						props.history.push(
							`${constants.ROUTE_URL}/dashboard/cohortdesign/${cohortId}`
						);
					}
				},
				(error) => {
					setIsLoading(false);
					if (error.response.data.code === 400) {
						errors.duplicateCohort = error?.response?.data?.description;
						const _errors = Object.assign({}, errors);
						setErrors(_errors);
					}
				}
			);
		}
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles data={headerData} search={false} {...props} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<EditCohortComponent cohortId={-1} saveCohort={saveCohort} errors={errors} />
				</Suspense>
			</ErrorBoundary>
		</div>
	);
}

AddCohort.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AddCohort);
