/* istanbul ignore file */
import actionTypes from '../actions/actionTypes';

//defining the initial state for the technology reducer.
let initialState = {
	lexiconCount : 0,
	temporalCount : 0,
	grammarCount: 0,
	negationsCount: 0,
	associationCount: 0,
	NECount: 0,
	conceptSupport: 0,
	LexiconConcepts: {},
	LexiconConceptsByID: {},
	itemDetail : {}
}
//reducer that matches the action type for technology actions and changes the state
export const technologyReducer = (state = initialState, action ) => {
	
	switch(action.type) {
		case actionTypes.GET_LEXICON_COUNT : {
			return {...state, lexiconCount: action.payload}
		}

		case actionTypes.GET_TEMPORAL_ORDER : {
			return {...state, temporalCount:action.payload}
		}

		case actionTypes.GET_GRAMMAR_COUNT : {
			return {...state, grammarCount: action.payload}
		}

		case actionTypes.GET_NEGATIONS_COUNT : {
			return {...state, negationsCount: action.payload}
		}

		case actionTypes.GET_ASSOCIATION_COUNT : {
			return {...state, associationCount: action.payload}
		}

		case actionTypes.GET_NE_COUNT : {
			return {...state, NECount: action.payload}
		}

		case actionTypes.GET_CONCEPT_SUPPORT : {
			return {...state, conceptSupport: action.payload}
		}

		case actionTypes.GET_LEXICON_CONCEPTS : {
			return {...state, LexiconConcepts: action.payload}
		}
		case actionTypes.GET_LEXICON_CONCEPTS_BY_ID : {
			return {...state, LexiconConceptsByID: action.payload}
		}
		case actionTypes.SET_TECH_ITEM_DETAIL : {
			return {...state, itemDetail: action.payload}
		}

		default : {
			return state;
		}
	}
}