import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import UserData from '../../components/UserData/UserData';
import actions from '../../actions';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import { FetchUserList } from './UserManagementHelper';

const UserManagement = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [userList, setUserList] = useState([]);

	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		return () => {
			if(props.open){
				props.open(null);
			}
		};
	}, []);

	useEffect(() => {
		if (open) {
			FetchUserList().then((res) => {
				setUserList(res?.data?.data || []);
			});
		}
	}, [open]);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		props.dispatch(actions.openModal(''));
		//SET CLOSE MODAL IN REDUX
	};

	return (
		<ErrorBoundary>
			<ModalComponent
				open={open}
				handleClose={handleClose}
				content={
					<div className="modal_body_content">
						<UserData handleClose={handleClose} customUser={userList} />
					</div>
				}
				className="Data-Management"
			/>
		</ErrorBoundary>
	);
});

UserManagement.propTypes = {
	dispatch: PropTypes.func,
	open: PropTypes.func,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(UserManagement);
