/* istanbul ignore file */
import actionTypes from './actionTypes';
import { apiGet, apiPost, apiPostForm, getSectionDetails } from '../utilities/utils';
import store from '../store';

/****************************************
 * Function Name: getHistoryData
 * Description: exports an action to get the Annotated Text from the backend.
 * The data received gets set in the reducer which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - doc_id - The document ID based on which the API call has to be made.
 ****************************************/

export function getHistoryData(doc_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetAnnotatedTextFromID', {
				dataRecordID: parseInt(doc_id),
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_ANNOTATED_TEXT,
						payload: res.data || [],
					});
					resolve(res.data || []);
				})
				.catch((err) => {
					console.log(err);
				});
		});
}

/****************************************
 * Function Name: removeHistoryData
 * Description: exports an action to set the annotation text
 * in the reducer.
 * Method has one signature.
 ****************************************/
export function removeHistoryData() {
	return (dispatch) => {
		dispatch({
			type: actionTypes.GET_ANNOTATED_TEXT,
			payload: '',
		});
	};
}

/****************************************
 * Function Name: setDocName
 * Description: exports an action to set the document name
 * in the reducer.
 * Method has one signature.
 * @param - doc_name - Document Name that has to be set in the reducer.
 ****************************************/

export function setDocName(doc_name) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_DOC_NAME,
			payload: doc_name,
		});
	};
}

/****************************************
 * Function Name: getAnnotationList
 * Description: exports an action to get the Automated Annotation details
 * from the backend. The list received is then set in the reducer.
 * Method has one signature.
 * @param - cuid - The id of the link clicked for the annotation list has
 * to be fetched from the backend.
 ****************************************/

export function getAnnotationList(cuId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPost('GetAnnotationList', `conceptTableEntry.concept_id=${cuId}`)
				.then((res) => {
					// console.log(res);
					dispatch({
						type: actionTypes.GET_ANNOTATION_LIST,
						payload: { id: cuId, list: res.data.data },
					});
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getAnnotationDetail
 * Description: exports an action to get the Automated Annotation details
 * from the backend. The list received is then set in the reducer.
 * Method has one signature.
 * @param - conceptID - The id of the link clicked for the annotation details has
 * to be fetched from the backend.
 ****************************************/

export function getAnnotationDetail(conceptID) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPost('GetAnnotationDetail', `codeTableEntry.term=${conceptID}`)
				.then((res) => {
					dispatch({
						type: actionTypes.GET_ANNOTATION_DETAIL,
						payload: { id: conceptID, detail: res.data },
					});
					resolve(res.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getManualAnnotations
 * Description: exports an action to fetch the manual annotations data from backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export function getManualAnnotations(m_DocIdList) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetDocumentManualAnnotations', {
				m_DocIdList: m_DocIdList,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MANUAL_ANNOTATIONS,
						payload: res.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getManualAnnotationsNew
 * Description: exports an action to fetch the manual annotations data from backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export function getManualAnnotationsNew(m_DocIdList) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetManualAnnotations', {
				'm_ManualAnnotationObject.subdocument_id': m_DocIdList,
			})
				.then((res) => {
					// dispatch({
					// 	type: actionTypes.GET_MANUAL_ANNOTATIONS_SUBDOC,
					// 	payload: res.data.payload ? { [m_DocIdList]: res.data.payload } : { [m_DocIdList]: [] },
					// });
					resolve(res.data.payload || []);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getManualAnnotationsWithConflicts
 * Description: exports an action to fetch the manual annotations data from backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export function getManualAnnotationsWithConflicts(
	studyId,
	concept_id,
	userIdList,
	initial,
	pageNumber,
	pageSize,
	forKappaScore
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetManualAnnotationsWithConflicts', {
				m_studyId: studyId,
				m_ConceptId: concept_id,
				pageNumber: pageNumber,
				pageSize: pageSize,
				userIdList: userIdList,
				initial: initial,
				forKappaScore: forKappaScore == 'true' ? true : false,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MANUAL_ANNOTATIONS,
						payload: res.data.payload || []
					});

					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getUserManualAnnotations
 * Description: exports an action to fetch the manual annotations of a logged in user from the backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export function getUserManualAnnotations(m_DocIdList) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetManualAnnotations', {
				'm_ManualAnnotationObject.subdocument_id': m_DocIdList,
				m_UserManualAnnotationFlag: true,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MANUAL_ANNOTATIONS,
						payload: res.data.payload || [],
					});
					resolve(res.data.payload || []);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getUserManualAnnotationsWithoutFlag
 * Description: exports an action to fetch the manual annotations of a logged in user from the backend
 * later for manipulation.
 * Method has one signature.
 ****************************************/

export function getUserManualAnnotationsWithoutFlag(m_DocIdList) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetManualAnnotations', {
				'm_ManualAnnotationObject.subdocument_id': m_DocIdList,
				m_isAnnotatorReviewConflict: true,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MANUAL_ANNOTATIONS,
						payload: res.data.payload || [],
					});
					resolve(res.data.payload || []);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: saveManualAnnotation
 * Description: exports an action to set the annotation list from backend
 * in the reducer.
 * Method has one signature.
 ****************************************/
// export function saveManualAnnotation(manualDataObj) {
// 	return (dispatch) => {
// 		dispatch({
// 			type: actionTypes.SET_MANUAL_ANNTATION_LIST,
// 			payload: manualDataObj,
// 		});
// 	};
// }

/****************************************
 * Function Name: addManualAnnotation
 * Description: exports an action to Adding the manual annotation List from backend
 * later for manipulation.
 * Method has nine signature.
 ****************************************/

export function addManualAnnotation(
	annotation_id,
	concept_id,
	document_id,
	is_present,
	is_current,
	is_definitive,
	is_experienced,
	numeric_result,
	document_concept_id,
	startpos,
	endpos,
	offsetStart,
	offsetEnd,
	annotatedText,
	selectedSectionId
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			if (document_concept_id == -1) {
				if (startpos == -1) {
					apiGet('AddManualAnnotation', {
						'm_ManualAnnotationObject.annotation_id': annotation_id,
						'm_ManualAnnotationObject.concept_id': concept_id,
						'm_ManualAnnotationObject.document_id': document_id,
						'm_ManualAnnotationObject.is_present': is_present,
						'm_ManualAnnotationObject.is_current': is_current,
						'm_ManualAnnotationObject.is_definitive': is_definitive,
						'm_ManualAnnotationObject.is_experienced': is_experienced,
						'm_ManualAnnotationObject.numeric_result': numeric_result,
					})
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				} else {
					apiGet('AddManualAnnotation', {
						'm_ManualAnnotationObject.annotation_id': annotation_id,
						'm_ManualAnnotationObject.concept_id': concept_id,
						'm_ManualAnnotationObject.document_id': document_id,
						'm_ManualAnnotationObject.is_present': is_present,
						'm_ManualAnnotationObject.is_current': is_current,
						'm_ManualAnnotationObject.is_definitive': is_definitive,
						'm_ManualAnnotationObject.is_experienced': is_experienced,
						'm_ManualAnnotationObject.numeric_result': numeric_result,
						'm_ManualAnnotationTextObject.startPosition': startpos,
						'm_ManualAnnotationTextObject.endPosition': endpos,
						'm_ManualAnnotationTextObject.offsetStart': offsetStart,
						'm_ManualAnnotationTextObject.offsetEnd': offsetEnd,
						'm_ManualAnnotationTextObject.annotText': annotatedText,
						'm_ManualAnnotationTextObject.sectionId': selectedSectionId,
					})
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				}
			} else {
				apiGet('AddManualAnnotation', {
					'm_ManualAnnotationObject.annotation_id': annotation_id,
					'm_ManualAnnotationObject.concept_id': concept_id,
					'm_ManualAnnotationObject.document_id': document_id,
					'm_ManualAnnotationObject.is_present': is_present,
					'm_ManualAnnotationObject.is_current': is_current,
					'm_ManualAnnotationObject.is_definitive': is_definitive,
					'm_ManualAnnotationObject.is_experienced': is_experienced,
					'm_ManualAnnotationObject.numeric_result': numeric_result,
					'm_ManualAnnotationDocConceptObject.document_concept_id': document_concept_id,
				})
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			}
		});
}

/****************************************
 * Function Name: addManualAnnotation
 * Description: exports an action to Adding the manual annotation List from backend
 * later for manipulation.
 * Method has nine signature.
 ****************************************/

export function addManualAnnotationNew(
	annotation_id,
	concept_id,
	document_id,
	is_present,
	is_current,
	is_definitive,
	is_experienced,
	numeric_result,
	document_concept_id,
	startpos,
	endpos,
	offsetStart,
	offsetEnd,
	annotatedText,
	selectedSectionId,
	annotationType,
	isUpdate
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			//if(document_concept_id == -1) {

			if (annotationType == 'Document') {
				apiGet('AddManualAnnotation', {
					'm_ManualAnnotationObject.annotation_id': annotation_id,
					'm_ManualAnnotationObject.concept_id': concept_id,
					'm_ManualAnnotationObject.subdocument_id': document_id,
					'm_ManualAnnotationObject.is_present': is_present,
					'm_ManualAnnotationObject.is_current': is_current,
					'm_ManualAnnotationObject.is_definitive': is_definitive,
					'm_ManualAnnotationObject.is_experienced': is_experienced,
					'm_ManualAnnotationObject.numeric_result': numeric_result,
					'm_ManualAnnotationObject.startPosition': startpos,
					'm_ManualAnnotationObject.endPosition': endpos,
					'm_ManualAnnotationObject.annotation_type': 'Document',
					'm_ManualAnnotationObject.section_number': selectedSectionId,
				})
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			} else if (annotationType == 'Document concept') {
				apiGet('AddManualAnnotation', {
					'm_ManualAnnotationObject.annotation_id': annotation_id,
					'm_ManualAnnotationObject.concept_id': concept_id,
					'm_ManualAnnotationObject.subdocument_id': document_id,
					'm_ManualAnnotationObject.is_present': is_present,
					'm_ManualAnnotationObject.is_current': is_current,
					'm_ManualAnnotationObject.is_definitive': is_definitive,
					'm_ManualAnnotationObject.is_experienced': is_experienced,
					'm_ManualAnnotationObject.numeric_result': numeric_result,
					'm_ManualAnnotationObject.startPosition': startpos,
					'm_ManualAnnotationObject.endPosition': endpos,
					'm_ManualAnnotationObject.annotation_type': 'Document concept',
					'm_ManualAnnotationDocConceptObject.offsetStart': offsetStart,
					'm_ManualAnnotationDocConceptObject.offsetEnd': offsetEnd,
					'm_ManualAnnotationDocConceptObject.annotText': annotatedText,
					'm_ManualAnnotationObject.section_number': selectedSectionId,
				})
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				if (isUpdate) {
					apiGet('AddManualAnnotation', {
						'm_ManualAnnotationObject.annotation_id': annotation_id,
						'm_ManualAnnotationObject.concept_id': concept_id,
						'm_ManualAnnotationObject.subdocument_id': document_id,
						'm_ManualAnnotationObject.is_present': is_present,
						'm_ManualAnnotationObject.is_current': is_current,
						'm_ManualAnnotationObject.is_definitive': is_definitive,
						'm_ManualAnnotationObject.is_experienced': is_experienced,
						'm_ManualAnnotationObject.numeric_result': numeric_result,
						'm_ManualAnnotationObject.annotation_type': 'Text',
						'm_ManualAnnotationObject.startPosition': startpos,
						'm_ManualAnnotationObject.endPosition': endpos,
						'm_ManualAnnotationObject.section_number': selectedSectionId,
					})
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				} else {
					apiGet('AddManualAnnotation', {
						'm_ManualAnnotationObject.annotation_id': annotation_id,
						'm_ManualAnnotationObject.concept_id': concept_id,
						'm_ManualAnnotationObject.subdocument_id': document_id,
						'm_ManualAnnotationObject.is_present': is_present,
						'm_ManualAnnotationObject.is_current': is_current,
						'm_ManualAnnotationObject.is_definitive': is_definitive,
						'm_ManualAnnotationObject.is_experienced': is_experienced,
						'm_ManualAnnotationObject.numeric_result': numeric_result,
						'm_ManualAnnotationObject.startPosition': startpos,
						'm_ManualAnnotationObject.endPosition': endpos,
						'm_ManualAnnotationObject.annotation_type': 'Text',
						'm_ManualAnnotationObject.section_number': selectedSectionId,
					})
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				}
			}
			// }else{
			// 	apiGet('AddManualAnnotation', {
			// 			'm_ManualAnnotationObject.annotation_id' : annotation_id,
			// 			'm_ManualAnnotationObject.concept_id' : concept_id,
			// 			'm_ManualAnnotationObject.document_id' : document_id,
			// 			'm_ManualAnnotationObject.is_present' : is_present,
			// 			'm_ManualAnnotationObject.is_current' : is_current,
			// 			'm_ManualAnnotationObject.is_definitive' : is_definitive,
			// 			'm_ManualAnnotationObject.is_experienced' : is_experienced,
			// 			'm_ManualAnnotationObject.numeric_result' : numeric_result,
			// 			'm_ManualAnnotationDocConceptObject.document_concept_id' : document_concept_id
			// 		}).then(res => {

			// 			resolve(res);
			// 		})
			// 		.catch(err => {
			// 			reject(err);
			// 		});

			// }
		});
}

/****************************************
 * Function Name: AddManualAnnotationsInBulk
 * Description: exports an action to Adding the manual annotation List from backend
 * later for manipulation.
 * Method has nine signature.
 ****************************************/

export function AddManualAnnotationsInBulk(data) {
	// const annotations = 

	return (dispatch) =>
		new Promise(function (resolve, reject) {
			getSectionDetails(data).then((annotations)=>{
				return apiPostForm('AddManualAnnotationsInBulk', annotations)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
			})
		});
}

/****************************************
 * Function Name: updateManualAnnotationConcept
 * Description: exports an action to get the Update Manual Annotation list to
 * Document document concept from the backend.
 * Method has nine signature.
 * to be fetched from the backend.
 ****************************************/
export function updateManualAnnotationConcept(
	annotation_id,
	concept_id,
	document_id,
	is_present,
	is_current,
	is_definitive,
	is_experienced,
	numeric_result,
	document_concept_id
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('AddManualAnnotation', {
				'm_ManualAnnotationObject.annotation_id': annotation_id,
				'm_ManualAnnotationObject.concept_id': concept_id,
				'm_ManualAnnotationObject.document_id': document_id,
				'm_ManualAnnotationObject.is_present': is_present,
				'm_ManualAnnotationObject.is_current': is_current,
				'm_ManualAnnotationObject.is_definitive': is_definitive,
				'm_ManualAnnotationObject.is_experienced': is_experienced,
				'm_ManualAnnotationObject.numeric_result': numeric_result,
				'm_ManualAnnotationDocConceptObject.document_concept_id': document_concept_id,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getManualAnnotationDetail
 * Description: exports an action to get the manual annotation detail from backend
 * later for manipulation.
 * Method has one signature.
 * @param - m_AnnotationId - The id of the link clicked for the annotation details has
 * to be fetched from the backend.
 ****************************************/
// export function getManualAnnotationDetail(m_AnnotationId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('FetchManualAnnotationDocumentConcept', {
// 				m_AnnotationId: m_AnnotationId,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_MANUAL_ANNOTATION_DETAIL,
// 						payload: res.data,
// 					});
// 					resolve(res.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }

/****************************************
 * Function Name: getManualAnnotationDetail
 * Description: exports an action to get the manual annotation detail from backend
 * later for manipulation.
 * Method has one signature.
 * @param - m_AnnotationId - The id of the link clicked for the annotation details has
 * to be fetched from the backend.
 ****************************************/
// export function FetchManualAnnotationDocumentConceptNew(m_AnnotationIds) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('FetchManualAnnotationDocumentConceptNew', {
// 				m_AnnotationIdList: m_AnnotationIds,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_MANUAL_ANNOTATION_DETAIL,
// 						payload: res.data,
// 					});
// 					resolve(res.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }

// export function getManualAnnotationText(m_AnnotationId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('FetchManualAnnotationText', {
// 				m_AnnotationId: m_AnnotationId,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_MANUAL_ANNOTATION_DETAIL,
// 						payload: res.data,
// 					});
// 					resolve(res.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }

// export function GetManualAnnotationDetails(m_AnnotationId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('GetManualAnnotationDetails', {
// 				m_AnnotationIdList: m_AnnotationId,
// 			})
// 				.then((res) => {
// 					// dispatch({
// 					// 	type:actionTypes.GET_MANUAL_ANNOTATION_DETAIL,
// 					// 	payload: res.data
// 					// })
// 					resolve(res.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }

// export function getManualAnnotationTextNew(m_AnnotationId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('FetchManualAnnotationTextNew', {
// 				m_AnnotationIdList: m_AnnotationId,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_MANUAL_ANNOTATION_DETAIL,
// 						payload: res.data,
// 					});
// 					resolve(res.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }

/****************************************
 * Function Name: getDetailsOfDocumnetConceptTemporality
 * Description: exports an action to get the automated annotation date data from backend
 * later for manipulation.
 * Method has one signature.
 * @param - m_DocConceptId - The hit of m_DocConceptId is the automated annotation date data has
 * to be fetched from the backend.
 ****************************************/

// export function getDetailsOfDocumnetConceptTemporality(m_DocConceptId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('GetDetailsOfDocumnetConceptTemporality', {
// 				m_DocConceptId: m_DocConceptId,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_DETAIL_DOC_CONCEPT_TEMPORALITY,
// 						payload: res.data,
// 					});
// 					resolve(res.data.data);
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					resolve({});
// 					//reject(err);
// 				});
// 		});
// }
/****************************************
 * Function Name: setRapidManualAnnotation
 * Description: exports an action to set the rapid manual annotation list from backend
 * in the reducer.
 * Method has one signature.
 ****************************************/
export function setRapidManualAnnotation(rapidmanualDataObj) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_RAPID_MANUAL_ANNOTATION,
			payload: rapidmanualDataObj,
		});
	};
}

// export function setRapidManualDocConceptMapping(conceptDataObj) {
// 	return (dispatch) => {
// 		dispatch({
// 			type: actionTypes.SET_RAPID_MANUAL_DOCUMENT_CONCEPT_MAP,
// 			payload: conceptDataObj,
// 		});
// 	};
// }

/****************************************
 * Function Name: getAutomatedAnnotationListForDocument
 * Description: exports an action to get the automated annotation list from backend
 * later for manipulation.
 * Method has one signature.
 * @param - m_SubdocumentId - The id of the link clicked for the automated annotation list has
 * to be fetched from the backend.
 ****************************************/
// export function getAutomatedAnnotationListForDocument(m_SubdocumentId) {
// 	return (dispatch) =>
// 		new Promise(function (resolve, reject) {
// 			apiGet('GetAutomatedAnnotationListForDocument', {
// 				m_SubdocumentId: m_SubdocumentId,
// 			})
// 				.then((res) => {
// 					dispatch({
// 						type: actionTypes.GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT,
// 						payload: res.data.data,
// 					});
// 					resolve(res.data.data);
// 				})
// 				.catch((err) => {
// 					reject(err);
// 				});
// 		});
// }

/****************************************
 * Function Name: deleteManualAnnotation
 * Description: exports an action to delete the manual annotation from backend
 * in the reducer.
 * Method has one signature.
 ****************************************/
export function deleteManualAnnotation(annotation_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('DeleteManualAnnotations', {
				'm_ManualAnnotationObject.annotation_id': annotation_id,
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT,
						payload: res.data.data,
					});
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getSubdocumentById(subddoc_id) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			let storedData = store.getState();
			let storedSubdocument = storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
			if (storedSubdocument) {
				resolve(storedSubdocument);
			} else {
				apiGet('GetSubdocumentById', {
					m_documentId: subddoc_id,
				})
					.then((res) => {
						if (res?.data?.data) {
							dispatch({
								type: actionTypes.GET_SUBDOCUMENT_DETAIL,
								payload: { [`subdocument_${subddoc_id}`]: res },
							});
						}
						resolve(res);
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});
			}
		});
}

/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getAnnotationTagsForText(text) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('NLPTagSections', {
				documentBody: text,
				isTextDocument: true,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function resolveManualAnnotationConflicts(resolveAnnotation) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('ResolveManualAnnotationConflicts ', resolveAnnotation)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getSubdocumentById
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function resolvePatientAnnotationConflicts(resolveAnnotation) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('ResolvePatientManualAnnotationConflicts ', resolveAnnotation)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewAWSResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getPreviewAWSResponse(data) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('TagNLP?isTextDocument=true&nlpTechnology=AWS', data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getPreviewGATEResponse(data) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiPostForm('TagNLP?isTextDocument=true&nlpTechnology=GATE', data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function getConceptSupportDataForDocument(dataRecordId, m_conceptId) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetConceptSupportDataForDocument', {
				dataRecordId,
				m_conceptId,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function GetPatientManualAnnotationsWithConflictsStudy(
	m_studyId,
	pageNumber = 1,
	pageSize = 0
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetPatientManualAnnotationsWithConflicts', {
				m_studyId: m_studyId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				forKappaScore: false,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function GetPatientManualAnnotationsWithConflicts(
	m_PatientId,
	pageNumber = 1,
	pageSize = 0
) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetPatientManualAnnotationsWithConflicts', {
				m_PatientId: m_PatientId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				forKappaScore: false,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: getPreviewGATEResponse
 * Description: return name of the document
 * Method has one signature.
 ****************************************/
export function GetSubdocumentList(m_documentId, m_contentType) {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetSubdocumentList', {
				m_documentId: m_documentId,
				m_contentType: m_contentType,
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetSeverityValues
 * Description: return name of the Severity Values
 * Method has one signature.
 ****************************************/
export function getSeverityValues() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetSeverityValues', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_SEVERITY_VALUES,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetSeverityValues
 * Description: return name of the Severity Values
 * Method has one signature.
 ****************************************/
export function getDescriptorValues() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetDescriptorValues', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_DESCRIPTOR_VALUES,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetSeverityValues
 * Description: return name of the Severity Values
 * Method has one signature.
 ****************************************/
export function getProgressionValues() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetProgressionValues', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_PROGRESSION_VALUES,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetNumericValueUnits
 * Description: return name of the Numeric Value Units
 * Method has one signature.
 ****************************************/

export function getNumericValueUnits() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetNumericValueUnits', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_NUMERIC_VALUE_UNITS,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetMedicationUnits
 * Description: return name of the Medication Unit
 * Method has one signature.
 ****************************************/

export function getMedicationUnits() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetMedicationUnits', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MEDICATION_UNITS,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetMedicationForms
 * Description: return name of the Medication Forms
 * Method has one signature.
 ****************************************/

export function getMedicationForms() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetMedicationForms', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MEDCATION_FORMS,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetMedicationRoutes
 * Description: return name of the Medication Routes
 * Method has one signature.
 ****************************************/
export function getMedicationRoutes() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetMedicationRoutes', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_MEDICATION_ROUTES,
						payload: res.data.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
 * Function Name: GetConceptCategoriesOptions
 * Description: return name of the Concept Category options for manual annotaion
 * Method has one signature.
 ****************************************/
export function GetConceptCategoriesOptions() {
	return (dispatch) =>
		new Promise(function (resolve, reject) {
			apiGet('GetConceptCategoryList', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_CONCEPT_CATEGORIES_OPTIONS,
						payload: res?.data?.data,
					});
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

export function GetNotDefinitiveDetails(text) {
	return new Promise((resolve, reject) => {
		apiGet('GetNotDefinitiveDetails', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_NOT_DEFINITIVE_DETAILS,
					payload: res?.data?.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function GetNotExperiencedReasons(text) {
	return new Promise((resolve, reject) => {
		apiGet('GetNotExperiencedReasons', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_NOT_EXPERIENCED_REASONS,
					payload: res?.data?.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}