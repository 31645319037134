import React from 'react';
import PropTypes from 'prop-types';
import { ConvertToLocale, setQueryParams } from '../../utilities/utils';
// import { setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
// import { setHistory } from '../../actions/sideNavActions';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import constants from '../../constants';

/**
 * Note: css classes used in this component expects, to be wrapped in div with class name "study-result-container"
 * Note#1: This component used in refactored cohort result page, but old css used because component used for study page
 *          css can be refactored after study page refactor.
 */

const CohortResultTable = ({ handlePatientClick, cohort, userRole }) => (
	<div className="outcome-measures">
		<Box label="Patients" count={cohort.patientCount} handleClick={handlePatientClick} />
		{cohort &&
			cohort.outcomeEvents &&
			cohort.outcomeEvents.map((outcome, index) => (
				<Box
					key={index}
					label={outcome.outcome_name}
					count={outcome.patient_count}
					handleClick={() =>
						gotoPatientListOutcome(
							cohort.cohort_id,
							outcome.outcome_id,
							outcome.outcome_name
						)
					}
				/>
			))}
	</div>
);

CohortResultTable.propTypes = {
	cohort: PropTypes.shape({
		cohort_id: PropTypes.any,
		outcomeEvents: PropTypes.shape({
			map: PropTypes.func,
		}),
		patientCount: PropTypes.any,
	}),
	handlePatientClick: PropTypes.any,
	userRole: PropTypes.any,
};

// CohortResultTable.propTypes = {
//     handlePatientClick: PropTypes.func.isRequired,
//     cohort: PropTypes.object.isRequired,
//     /**
//      *  cohort object = {
//      *      patientCount: number,
//      *      outcomeEvents: array,
//      *  }
//      */
// }

export default CohortResultTable;

const Box = ({ label, count, handleClick }) => (
	<div className="box">
		<h5>{label}</h5>
		{count > 0 ? (
			<div className="count count-link" onClick={handleClick}>
				{ConvertToLocale(count)}
			</div>
		) : (
			<div className="count">0</div>
		)}
	</div>
);

/***********************************************
 * Function Name: gotoPatientListOutcome
 * Description: Set filter label, history and navigate to patient list with two parameters cohort_id, outcome_id
 * @param cohort_id Cohort id
 * @param outcome_id outcome id
 * @param outcome_name Name of outcome
 **********************************************/
const gotoPatientListOutcome = (cohort_id, outcome_id, outcome_name, userRole) => {
	let filterLabel = {
		page: 'patient',
		options: [
			{
				filterLabel: 'Outcome',
				name: outcome_name,
			},
		],
	};

	setFilterLabel(filterLabel);
	setHistory(false, outcome_name);
	setQueryParams({
		pathname: `${constants.ROUTE_URL}/dashboard/patientlist`,
		query: { cohort_id, outcome_id },
	});
};
